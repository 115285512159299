import image from "../../../../../assets/images/servicos/servico-eletronico-digital.webp";
import Breadcrumbs from "../../../../layouts/Breadcrumbs";

import { useEffect } from "react";

const EletronicaDigital = () => {
  useEffect(() => {
    document.title = "Mídia Eletrônica & Digital | Agência Boa Imagem";
    const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
      metaDescription.setAttribute(
        "content",
        "A mídia se tornou algo revolucionário, a cada dia existem mais adeptos e consumidores virtuais. Sendo explorada por meio de redes sociais, e-mails e outros."
      );
    }
  }, []);

  const breadcrumbsItens = [
    {
      name: "Serviços",
      alias: "/servicos",
    },
    {
      name: "Offline",
      alias: "/offline",
    },
    {
      name: "Mídia e veículo",
      alias: "/midia-e-veiculo",
    },
    {
      name: "Eletrônica/Digital",
      alias: "/eletronica-digital",
    },
  ];

  return (
    <>
      <Breadcrumbs
        title={"Mídias Eletrônica/Digital"}
        items={breadcrumbsItens}
      />
      <section id="servicesPage">
        <div className="relative bg_secundary section_padding_clip">
          <div className="container">
            <img
              className="text_center mb-30"
              src={image}
              alt="Foto: Serviço de Mídia Eletrônica/Digital"
              title="Foto: Serviço de Mídia Eletrônica/Digital"
              width="520"
              height="347"
            />

            <p>
              A mídia digital se tornou algo revolucionário, a cada dia existem
              mais adeptos e consumidores virtuais. Essa ferramenta pode ser
              explorada por meio de redes sociais, e-mails, sites, telas de
              elevadores, banners eletrônicos e outros. Além de possui uma
              grande variedade de espaços para se comunicar com diversos
              públicos é um meio seguro e eficaz de se comunicar.
            </p>

            <p>
              Contamos com uma equipe de profissionais para todas as áreas de
              comunicação. Isso quer dizer que temos a capacidade de divulgar
              sua empresa ou negócio cuidando de sua produção para a mídia
              específica seja ela a televisão, rádio ou web. Produzimos a sua
              campanha publicitária para marketing interno ou externo, cuidando
              em adaptar a linguagem para cada tipo de veículo.
            </p>

            <h2>Mídia eletrônica e digital</h2>

            <p>
              A mídia eletrônica é aquela que se utiliza de meio digital para
              informar os diversos tipos de público. Essa ferramenta oferece
              saídas favoráveis em relação a mídia impressa. Produzimos a sua
              campanha publicitária para marketing interno ou externo, cuidando
              em adaptar a linguagem para cada veículo.
            </p>

            <p>
              O digital já se tornou tão importante que ninguém mais discute se
              a sua empresa deve fazer parte desse mundo, mas como deve fazê-lo.
              A Boa imagem tem experiência de mídias sociais, como Facebook e
              Twitter, a formatos como banner, outdoors em vídeo ou em 3D,
              elevamídia (para elevadores) e até sites completos, páginas
              estáticas promocionais e hot sites.
            </p>

            <p>
              Não é novidade que brasileiro adora uma rede social. Os números já
              comprovam o aumento da adesão. Só no Facebook, o País está em 2º
              lugar atrás dos EUA e a frente da Índia. Mas a grande questão é
              como usar essas plataformas a favor do seu negócio? Para algumas
              empresas, a publicidade nas redes sociais pode parecer algo
              complexo e impossível de administrar. Existe uma infinidade de
              cases de sucesso e de fracasso nas redes sociais. Tudo vai
              depender das estratégias escolhidas.
            </p>

            <p>Entre em contato com a nossa agência e conheça mais serviços!</p>
          </div>
        </div>
      </section>
    </>
  );
};

export default EletronicaDigital;
