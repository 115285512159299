import imgEbook1 from '../../assets/images/ebooks/ebook-facebook.jpg';
import imgEbook2 from '../../assets/images/ebooks/ebook-google-adwords.jpg';
import imgEbook3 from '../../assets/images/ebooks/ebook-automacao-de-marketing.jpg';
import imgEbook4 from '../../assets/images/ebooks/ebook-mitos-do-marketing-novo.jpg';
import imgEbook5 from '../../assets/images/ebooks/ebook-8-casos-de-uso-do-marketing-digital.jpg';
import imgEbook6 from '../../assets/images/ebooks/ebook-20-otimizacoes-em-facebook-ads.jpg';
import imgEbook7 from '../../assets/images/ebooks/ebook-uma-introducao-inbound-marketing.jpg';
import imgEbook8 from '../../assets/images/ebooks/ebook-landing-pages-na-pratica.jpg';
import Breadcrumbs from '../layouts/Breadcrumbs';
import { useEffect } from 'react';

const Materiais = () => {

    const ebooks = [{
        title: "Guia do Marketing no Facebook",
        text: "Dentre todas as estratégias de Marketing Digital que sua empresa pode utilizar para impulsionar seu negócio, estar no Facebook é uma das mais importantes! Baixe o nosso guia e aprenda estratégias que fazem todas as diferenças!",
        link: "/ebooks/O_guia_do_marketing_no_Facebook.pdf",
        img: imgEbook1
    },
    {
        title: "Geração de Leads com Google Adwords",
        text: "Se você deseja aumentar a geração de oportunidades para a sua empresa, baixe o nosso Ebook e aprenda técnicas com Google Adwords e garanta que o investimento da sua empresa em anúncios retorne Leads qualificados!",
        link: "/ebooks/Geracao_de_Leads_com_Google_Adwords.pdf",
        img: imgEbook2
    },
    {
        title: "O guia definitivo da automacão de marketing",
        text: "Automação de Marketing ajuda sua empresa a preparar os Leads para a compra e a se relacionar com eles de forma automática e escalável.",
        link: "/ebooks/O_guia_definitivo_da_Automacao_de_Marketing.pdf",
        img: imgEbook3
    },
    {
        title: "Mitos do Marketing Digital",
        text: "Para ajudar você em seus estudos, levantamos os principais \"mitos\" que vemos e ouvimos diariamente em diversos assuntos do Marketing Digital",
        link: "/ebooks/Mitos_do_Marketing_Digital.pdf",
        img: imgEbook4
    },{
        title: "8 Casos de uso de Marketing Digital em empresas de tecnologia",
        text: "Reunimos neste eBook exemplos de empresas de tecnologia de nichos variados e apresentamos uma breve proposta para cada uma em diversos setores.",
        link: "/ebooks/8_casos_de_uso_do_marketing_digital_em_empresas_de_tecnologia.pdf",
        img: imgEbook5
    },
    {
        title: "20 Otimizações em Facebook Ads",
        text: "No entanto, é por essa facilidade que frequentemente encontramos pessoas que não atingiram, por esses canais, o resultado esperado, e por isso não acreditam que um novo investimento possa ter um retorno positivo. Aprenda conosco a utilizar essa ferramenta de forma correta.",
        link: "/ebooks/20_otimizacoes_em_Facebook_Ads.pdf",
        img: imgEbook6
    },
    {
        title: "Uma Introdução ao Inbound Marketing",
        text: "Inbound Marketing, ou o que muitos conhecem como o “novo marketing”, é uma metodologia e uma forma de pensar e fazer negócios relativamente recente, você conhece? Não? Você precisa conhecer hoje mesmo!",
        link: "/ebooks/Inbound_Marketing_Uma_Introducao.pdf",
        img: imgEbook7
    },
    {
        title: "Landing Pages na prática",
        text: "Produzir conteúdo de qualidade é essencial para captar Leads e guiá-los pelo Funil de Vendas. Por meio de um bom conteúdo, seus potenciais clientes entenderão o problema que têm e verão que sua empresa pode solucioná-los.",
        link: "/ebooks/Landing_Pages_na_Pratica.pdf",
        img: imgEbook8
    },];

    useEffect(() => {
        document.title = "Materiais | E-books gratuitos sobre publicidade e marketing";
        const metaDescription = document.querySelector('meta[name="description"]');
        if (metaDescription) {
          metaDescription.setAttribute(
            "content",
            "Materiais de Publicidade, Propaganda e Marketing disponíveis para enriquecer ainda mais o seu conhecimento. Aproveite e baixe os nossos guias, e aprenda estratégias que fazem toda a diferença!"
          );
        }
    }, []);

    const breadcrumbsItens = [{
        name: 'Materiais',
        alias: '/materiais'
    }]

    return (
        <>
        <Breadcrumbs title='Materiais' items={breadcrumbsItens}/>
        <section id="ebooksPage" className='box_divider relative bg_secundary section_padding_clip text_center'>
            
            <div className='container'>
                {ebooks.map((item, index) => (
                    <div className='item' key={index}>
                        <h2>{item.title}</h2>
                        <img alt={`Ebook: ${item.title}`} title={`Ebook: ${item.title}`} src={item.img} width='450' height='278'/>
                        <p>{item.text}</p>
                        <a href={item.link} className='btn btn-right btn-margin-top' target='_blank'>Baixar agora!</a>
                    </div>
                ))}
            </div>
        </section>
        </>
    );
}

export default Materiais;