import { useEffect } from "react";
import Breadcrumbs from "../../../../layouts/Breadcrumbs";

const AutomacaoMarketing = () => {

    useEffect(() => {
        document.title = "Automação de Marketing | Agência Boa Imagem";
        const metaDescription = document.querySelector('meta[name="description"]');
        if (metaDescription) {
          metaDescription.setAttribute(
            "content",
            "A automação de marketing e o e-mail marketing são ferramentas poderosas para conquistar clientes, manter o engajamento e aumentar a taxa de conversão."
          );
        }
    }, []);

    const breadcrumbsItens = [
        {
            name: 'Serviços',
            alias: '/servicos'
        },{
            name: 'Online',
            alias: '/online'
        },{
            name: 'Marketing digital',
            alias: '/marketing-digital'
        },{
            name: 'Automação de marketing',
            alias: '/automacao-de-marketing'
        }
    ];

    return (
        <>
            <Breadcrumbs title={'Serviços'} items={breadcrumbsItens}/>
            <section id="servicesPage">
                <div className='relative bg_secundary section_padding_clip'>
                    <div className='container'>

                    <p>Automação de marketing refere-se ao uso de software e tecnologias para automatizar tarefas, gerenciar campanhas complexas e personalizar a comunicação com os clientes. Isso inclui a automação de e-mails, mídias sociais, gerenciamento de leads, segmentação de mercado e análise de dados.</p>

                    <p>A automação de marketing é uma ferramenta poderosa para qualquer empresa que busca melhorar a eficiência, trazendo resultados tangíveis de forma ágil e inteligente.</p>

                    <h2 className="title_divider mb-30">Benefícios da Automação de Marketing</h2>

                    <ul className="list">
                        <li>Eficiência Operacional: Automatizar tarefas repetitivas libera tempo para que as equipes de marketing possam se concentrar em atividades estratégicas e criativas.</li>
                        <li>Personalização em Escala: Permite a personalização de mensagens para diferentes segmentos de público com base em comportamentos, interesses e interações anteriores.</li>
                        <li>Melhoria no Gerenciamento de Leads: Facilita a nutrição de leads ao longo do funil de vendas, fornecendo conteúdo relevante em cada estágio do processo de compra.</li>
                        <li>Medição e Análise: Oferece ferramentas robustas para monitorar o desempenho de campanhas e analisar dados, ajudando a tomar decisões informadas.</li>
                        <li>Aumento da Taxa de Conversão: Mensagens mais relevantes e direcionadas resultam em maior engajamento e, consequentemente, melhores taxas de conversão.</li>
                    </ul>

                    <p>Vale lembrar que nos tempos atuais as soluções de automação de marketing estão se tornando cada vez mais sofisticadas, consistentes e eficazes.</p>

                    <p>As opções de automação vão desde e-mail marketing até sistemas integrados de CRM para o gerenciamento de leads. Confira todos os serviços na página do nosso parceiro <a href="https://www.biplus.com.br/" target="_blank" rel="noopener">BI PLUS</a>.</p>

                    </div>
                </div>
            </section>
        </>
    );
}

export default AutomacaoMarketing;