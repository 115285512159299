import logo from '../../assets/images/logo-boa-imagem.webp';

const Header = () => {
    return (
        <header id="topo">
            <img src={logo} width="162" height="33" alt="Logo: Boa Imagem - Marketing e Publicidade" title='Boa Imagem - Marketing e Publicidade' />
        </header>
    );
};

export default Header;