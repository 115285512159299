import { Link } from "react-router-dom";
import servico360 from "../../assets/images/icon-360.webp";
import ebooks from "../../assets/images/ebooks.webp";
import Post from "../layouts/Post";
import CasesCarousel from "../layouts/CasesCarousel";
import { useEffect } from "react";
import chessImage from "../../assets/images/xadres.webp";

import cliente01 from "../../assets/images/clientes/cliente-1-pnud.webp";
import cliente02 from "../../assets/images/clientes/cliente-2-coca-cola.webp";
import cliente03 from "../../assets/images/clientes/cliente-3-banco-do-brasil.webp";
import cliente04 from "../../assets/images/clientes/cliente-4-visa.webp";
import cliente05 from "../../assets/images/clientes/cliente-5-escola-multi-integral.webp";
import cliente06 from "../../assets/images/clientes/cliente-6-ng-enegenharia.webp";
import cliente07 from "../../assets/images/clientes/cliente-7-inframerica.webp";
import cliente08 from "../../assets/images/clientes/cliente-8-tv-record.webp";
import cliente09 from "../../assets/images/clientes/cliente-9-toyota.webp";
import cliente10 from "../../assets/images/clientes/cliente-10-bom-samaritano.webp";
import cliente11 from "../../assets/images/clientes/cliente-11-cna.webp";
import cliente12 from "../../assets/images/clientes/cliente-12-hosl.webp";
import cliente13 from "../../assets/images/clientes/cliente-13-asbapi.webp";
import cliente14 from "../../assets/images/clientes/cliente-14-receita-ferederal.webp";
import cliente15 from "../../assets/images/clientes/cliente-15-brasilbrokers.webp";
import cliente16 from "../../assets/images/clientes/cliente-16-brookfield.webp";
import cliente17 from "../../assets/images/clientes/cliente-17-colcci.webp";
import cliente18 from "../../assets/images/clientes/cliente-18-estacio.webp";
import cliente19 from "../../assets/images/clientes/cliente-19-fujioka.webp";
import cliente20 from "../../assets/images/clientes/cliente-20-ibl.webp";
import cliente21 from "../../assets/images/clientes/cliente-21-indaia.webp";
import cliente22 from "../../assets/images/clientes/cliente-22-ministerio-das-comunicacoes.webp";
import cliente23 from "../../assets/images/clientes/cliente-23-ucb.webp";
import cliente24 from "../../assets/images/clientes/cliente-24-wmed.webp";

const Home = () => {
  useEffect(() => {
    document.title =
      "Boa Imagem | Agência de Publicidade e Propaganda | Brasília - DF";
    const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
      metaDescription.setAttribute(
        "content",
        "Contato para a Boa Imagem Corporativa, Atuamos desde 2009 em Brasília com estratégias e ferramentas de marketing que analisam corretamente os canais de comunicação, com foco em resultados."
      );
    }
  }, []);

  return (
    <>
      <section id="topoHome">
        <div className="container">
          <h1>
            <strong>Boa Imagem</strong> | Agência de Publicidade e Propaganda
            localizada em Brasília - DF
          </h1>
          <p>
            {" "}
            Agência de publicidade e propaganda, assessoria e consultoria em
            marketing, tecnologia, vendas e mídia.
          </p>
        </div>
      </section>

      <section
        id="servicosHome"
        className="bg_secundary section_padding_clip text_center"
      >
        <div className="container">
          <h2>
            Uma <strong className="text_primary">agência</strong>
            <img
              width="78"
              height="58"
              src={servico360}
              alt="Ícone 360"
              title="360"
            />
          </h2>
          <div className="box_two_columns_desktop">
            <p>
              Fundada em 2009, a Boa Imagem é uma agência de Brasília que
              utiliza a abordagem Full Service Agency, o que significa que ela é
              uma agência de publicidade completa e pode lidar com todos os
              aspectos de marketing, publicidade e propaganda. Isso inclui
              planejamento estratégico, produção, criatividade e inovação, bem
              como serviços de consultoria de marketing e a modelagem de
              negócios com o Business Plan focado em marketing.
            </p>

            <p>
              Somado a estratégias 360° para planejamento estratégico, a
              tecnologia e a análise de dados são parte das ferramentas de
              performance para nossos clientes, o diferencial é que, utilizando
              investimentos de forma eficaz na propaganda, a agência Boa Imagem
              atua nas necessidades do seu negócio para que a boa comunicação
              seja o principal motor para a geração de vendas. Leia a análise
              completa em Fixthephoto.com.
            </p>
          </div>

          <div className="box_services">
            <div className="box_one">
              <Link to={"/servicos/estrategias"}>
                <h3>Estratégia</h3>
              </Link>
            </div>

            <div className="box_two_columns">
              <div className="box_two">
                <Link to={"/servicos/online"}>
                  <h3>Online</h3>
                </Link>
              </div>

              <div className="box_three">
                <Link to={"/servicos/offline"}>
                  <h3>offline</h3>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        id="casesHome"
        className="bg_primary section_padding_clip clip_top text_center"
      >
        <div className="container">
          <h2>
            Mais de <strong>1000</strong> empresas atendidas e satisfeitas!
          </h2>
          <CasesCarousel />
        </div>
      </section>

      <section
        id="callToActionHome"
        className="bg_secundary section_padding_clip clip_top text_center"
      >
        <div className="container">
          <h2 className="text_primary">
            <strong>Planejamento delineado sob medida</strong>
          </h2>
          <h3>
            “Planejar é decidir de antemão qual é e como será a sua vitória.”
            <br />
            Rhandy Di Stefano (Integrated Coaching Institute)
          </h3>

          <img
            src={chessImage}
            alt="tabuleiro de xadress"
            width="680"
            height="484"
            loading="lazy"
          />

          <p>
            Em qualquer empreendimento é necessário pensar no público-alvo. Sem
            um ponto de vista estratégico definido em planejamento, fica difícil
            entender a jornada do cliente desde o seu primeiro contato com a
            empresa até a decisão de compra. Analisar corretamente os dados é o
            caminho para a sua agência de publicidade em Brasília.
          </p>

          <h4>
            Uma agência de publicidade que utiliza investimentos da forma mais
            eficaz possível, identificando as necessidades do seu negócio para
            gerar uma boa comunicação com seus clientes. Assim, como o cavalo em
            um tabuleiro de xadrez, oferecemos uma manobra exclusiva no jogo dos
            negócios. Não se trata de pular etapas do processo, mas de executar
            cada uma delas com rigor e apreço.
          </h4>

          <p>
            Vamos agendar uma reunião para começar a traçar o caminho do sucesso
            da sua empresa?
          </p>
        </div>
      </section>

      <section
        id="clientsHome"
        className="bg_tertiary section_padding_clip clip_top text_center"
      >
        <div className="container">
          <h2 className="text_primary">Clientes</h2>
          <p>
            Desde a sua fundação, a Boa Imagem Agência de Publicidade e
            Propaganda, com sede em Brasília, Distrito Federal, já atendeu mais
            de 1.200 marcas, tanto no Brasil quanto em outros países. Atuamos em
            diversos estados brasileiros e com clientes internacionais, focados
            especialmente em campanhas para a América Latina. Cada desafio de
            comunicação foi transformado em peças publicitárias impactantes.
            Confira abaixo algumas das marcas que já confiaram em nossos
            serviços e explore o nosso portfólio para ver exemplos de nosso
            trabalho.
          </p>

          <div className="box_two_columns">
            <img
              width="198"
              height="97"
              alt="Logo da empresa: PNUD"
              title="PNUD"
              src={cliente01}
              loading="lazy"
            />
            <img
              width="198"
              height="97"
              alt="Logo da empresa: Coca-Cola"
              title="Coca-Cola"
              src={cliente02}
              loading="lazy"
            />
            <img
              width="198"
              height="97"
              alt="Logo da empresa: Banco do Brasil"
              title="Banco do Brasil"
              src={cliente03}
              loading="lazy"
            />
            <img
              width="198"
              height="97"
              alt="Logo da empresa: Visa"
              title="Visa"
              src={cliente04}
              loading="lazy"
            />
            <img
              width="198"
              height="97"
              alt="Logo da empresa: Escola Multi-Integral"
              title="Escola Multi-Integral"
              src={cliente05}
              loading="lazy"
            />
            <img
              width="198"
              height="97"
              alt="Logo da empresa: NG|Engenharia"
              title="NG|Engenharia"
              src={cliente06}
              loading="lazy"
            />
            <img
              width="198"
              height="97"
              alt="Logo da empresa: Inframerica"
              title="Inframerica"
              src={cliente07}
              loading="lazy"
            />
            <img
              width="198"
              height="97"
              alt="Logo da empresa: TV Record"
              title="TV Record"
              src={cliente08}
              loading="lazy"
            />
            <img
              width="198"
              height="97"
              alt="Logo da empresa: Toyota"
              title="Toyota"
              src={cliente09}
              loading="lazy"
            />
            <img
              width="198"
              height="97"
              alt="Logo da empresa: Bom Samaritano"
              title="Bom Samaritano"
              src={cliente10}
              loading="lazy"
            />
            <img
              width="198"
              height="97"
              alt="Logo da empresa: CNA"
              title="CNA"
              src={cliente11}
              loading="lazy"
            />
            <img
              width="198"
              height="97"
              alt="Logo da empresa: HOSL"
              title="HOSL"
              src={cliente12}
              loading="lazy"
            />
            <img
              width="198"
              height="97"
              alt="Logo da empresa: ASBAPI"
              title="ASBAPI"
              src={cliente13}
              loading="lazy"
            />
            <img
              width="198"
              height="97"
              alt="Logo da empresa: Receita Federal"
              title="Receita Federal"
              src={cliente14}
              loading="lazy"
            />
            <img
              width="198"
              height="97"
              alt="Logo da empresa: BrasilBrokers"
              title="BrasilBrokers"
              src={cliente15}
              loading="lazy"
            />
            <img
              width="198"
              height="97"
              alt="Logo da empresa: Brookfield"
              title="Brookfield"
              src={cliente16}
              loading="lazy"
            />
            <img
              width="198"
              height="97"
              alt="Logo da empresa: Colcci"
              title="Colcci"
              src={cliente17}
              loading="lazy"
            />
            <img
              width="198"
              height="97"
              alt="Logo da empresa: Estácio"
              title="Estácio"
              src={cliente18}
              loading="lazy"
            />
            <img
              width="198"
              height="97"
              alt="Logo da empresa: Fujioka"
              title="Fujioka"
              src={cliente19}
              loading="lazy"
            />
            <img
              width="198"
              height="97"
              alt="Logo da empresa: IBL"
              title="IBL"
              src={cliente20}
              loading="lazy"
            />
            <img
              width="198"
              height="97"
              alt="Logo da empresa: Indaiá"
              title="Indaiá"
              src={cliente21}
              loading="lazy"
            />
            <img
              width="198"
              height="97"
              alt="Logo da empresa: Ministério das Comunicações"
              title="Ministério das Comunicações"
              src={cliente22}
              loading="lazy"
            />
            <img
              width="198"
              height="97"
              alt="Logo da empresa: UCB"
              title="UCB"
              src={cliente23}
              loading="lazy"
            />
            <img
              width="198"
              height="97"
              alt="Logo da empresa: WMed"
              title="WMed"
              src={cliente24}
              loading="lazy"
            />
          </div>
        </div>
      </section>

      <section
        id="contentHome"
        className="section_padding_clip clip_top text_center"
      >
        <div className="container">
          <div id="ebooksHome">
            <h2>Faça o download de eBooks gratuitos</h2>
            <div className="box_two_columns">
              <img
                width="269"
                height="308"
                alt="eBooks"
                title="eBooks"
                src={ebooks}
                loading="lazy"
              />
              <p>
                O conhecimento é fundamental para a construção de uma Boa
                Imagem. Explore nosso catálogo de eBooks e fique por dentro de
                insights, dicas e inspirações para o seu negócio.
              </p>
            </div>
            <Link className="btn" to={"/materiais"}>
              Ver todos
            </Link>
          </div>

          <div id="blogHome">
            <h2>
              Blog: fique por dentro das notícias sobre ações de Marketing,
              Publicidade e Propaganda
            </h2>
            <Post />
            <Link className="btn" to={"/blog"}>
              Ver blog
            </Link>
          </div>
        </div>
      </section>
    </>
  );
};

export default Home;
