import React from "react";
import { Link } from "react-router-dom";
import { ServicesInterface } from "../interfaces/ServicesInterface";

interface ServiceBoxProps {
    services: ServicesInterface[];
}

const ServiceBox: React.FC<ServiceBoxProps> = ({ services }) => {
    return (
        <>
            {services.map((service, index) => (
                <div className="item" key={index}>
                    <h2>{service.title}</h2>
                    <img
                        width="360"
                        height="240"
                        src={service.image}
                        alt={service.title}
                        title={service.title}
                    />
                    <p>{service.text}</p>
                    <Link className="btn btn-right btn-margin-top" to={service.link}>Ver mais</Link>
                </div>
            ))}
        </>
    );
}

export default ServiceBox;
