import { useEffect } from "react";
import Breadcrumbs from "../../../layouts/Breadcrumbs";

const Design = () => {

    useEffect(() => {
        document.title = "Design de Produto | Agência Boa Imagem";
        const metaDescription = document.querySelector('meta[name="description"]');
        if (metaDescription) {
          metaDescription.setAttribute(
            "content",
            "O Design de Produto ou Design Industrial, da Boa Imagem em Brasília, tem por objetivo desenvolver produtos exclusivos e com design diferenciados para os nossos clientes."
          );
        }
    }, []);

    const breadcrumbsItens = [
        {
            name: 'Serviços',
            alias: '/servicos'
        },{
            name: 'Offline',
            alias: '/offline'
        },{
            name: 'Design',
            alias: '/design'
        }
    ];

    return (
        <>
            <Breadcrumbs title={'Design de Produto'} items={breadcrumbsItens}/>
            <section id="servicesPage">
                <div className='relative bg_secundary section_padding_clip'>
                    <div className='container'>

                        <h3>Criamos o melhor design para a sua empresa</h3>
                        <h2 className="title_divider mb-30">Design de Produto</h2>

                        <p>Podemos encontrar inúmeras maneiras de aplicar o Design. O segmento é amplo e nos possibilita inicia-lo de diferentes formas, porém na Boa Imagem estamos preparados para desenvolver a criação do design de produto, peças publicitárias e outros modelos que podem fazer parte da necessidade da sua empresa.</p>

                        <p>Sabemos que a identidade de uma empresa faz toda a diferença. A agência Boa Imagem é capaz de sanar todas as necessidades de marketing e publicidade da sua empresa. Nossa dinâmica é baseada no planejamento, produção e ação. O planejamento é o primeiro passo que antecede a criação. Identificar as necessidades de comunicação permite perceber e avaliar a realidade da empresa e os objetivos a serem atingidos.</p>

                        <p>Com a orientação definida pelo planejamento, a Boa Imagem promove a interação de seus profissionais de criação, para a definição do conceito e a sua consequente aplicação visual para a produção da arte final adequada ao tipo de mídia a ser utilizada. A Boa Imagem utiliza técnicas de 2D, 3D em diferentes interfaces e aplicativos, transformando a ideia em layout e identidade visual ou redesenhando a sua marca.</p>

                        <h4>PRONTIDÃO</h4>

                        <p>A agência Boa Imagem está sempre preparada para traduzir da melhor maneira as vontades e ambições de nossos clientes. Estamos focados em mostrar a qualidade e importância do nosso trabalho.</p>

                        <p>Buscamos disponibilizar inovação e qualidade. A estética e o modelo não podem ser apenas interessantes, eles precisam ser funcionais e oferecerem durabilidade. Sempre cumprindo prazos, nossa intenção é estreitar a relação com nossos parceiros, para continuar atendendo sempre.</p>

                        <p>Não deixe de entrar em contato com a Boa Imagem. Oferecemos inúmeros serviços que podem ampliar a sua empresa de forma online e offline. Além disso, colecionamos experiências, estamos sempre antenados na atualidade do mercado e assim somos capazes de oferecer o melhor serviço. Nossa visão de futuro é ampla e juntos podemos desenvolver bons projetos e aumentar significativamente o sucesso do seu negócio.</p>

                    </div>
                </div>
            </section>
        </>
    );
}

export default Design;