import { ServicesInterface } from "../../../../interfaces/ServicesInterface";
import Breadcrumbs from "../../../../layouts/Breadcrumbs";
import ServiceBox from "../../../../layouts/ServiceBox";
import servico01 from "../../../../../assets/images/servicos/servico-marketing-de-busca.webp";
import servico02 from "../../../../../assets/images/servicos/servico-marketing-direto.webp";
import servico03 from "../../../../../assets/images/servicos/servico-endomarketing.webp";
import servico04 from "../../../../../assets/images/servicos/servico-marketing-indireto.webp";
import servico05 from "../../../../../assets/images/servicos/servico-marketing-digital.webp";
import servico06 from "../../../../../assets/images/servicos/servico-marketing-politico-e-eleitoral.webp";

import { useEffect } from "react";

const EstrategiasMarketing = () => {
  useEffect(() => {
    document.title = "Estratégia de Marketing: O que é e como desenvolver?";
    const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
      metaDescription.setAttribute(
        "content",
        "Independente do seu segmento, possuir uma boa estratégia de marketing pode ampliar o seu negócio e faze-lo atingir o sucesso e muita visibilidade."
      );
    }
  }, []);

  const breadcrumbsItens = [
    {
      name: "Serviços",
      alias: "/servicos",
    },
    {
      name: "Estratégias",
      alias: "/estrategias",
    },
    {
      name: "Estratégias de marketing",
      alias: "/estrategias-de-marketing",
    },
  ];

  const services: ServicesInterface[] = [
    {
      title: "Marketing de busca",
      text: "Nós sabemos como aplicar as melhores estratégias dentro desse segmento. Cuidamos de todas as necessidades da sua empresa e garantimos bons resultados, conheça um pouco mais sobre Marketing de Busca.",
      image: servico01,
      link: "/servicos/estrategias/estrategias-de-marketing/marketing-de-busca",
    },
    {
      title: "Marketing direto",
      text: "Profissionais que entendem do segmento são capazes de criar um plano estratégico bem completo. Iniciando com a seleção dos leads até chegar no final que é o convencimento do cliente através do Marketing Direto.",
      image: servico02,
      link: "/servicos/estrategias/estrategias-de-marketing/marketing-direto",
    },
    {
      title: "Endomarketing",
      text: "A estratégia de endomarketing é um diferencial competitivo, pois com essa estratégia a empresa consegue melhorar o relacionamento com os seus colaboradores, além de tornar o ambiente mais agradável. Valoriza os diferenciais e as necessidades dos funcionários e claro que tudo se reflete em qualidade.",
      image: servico03,
      link: "/servicos/estrategias/estrategias-de-marketing/endomarketing",
    },
    {
      title: "Marketing indireto",
      text: "O mais importante para marcas e empresas é serem lembradas, mas para que isso aconteça é preciso existir uma estratégia de marketing. A mais indicada para serem vistas e não serem esquecidas é usando o Marketing Indireto. Conheça um pouco mais sobre essa ferramenta!",
      image: servico04,
      link: "/servicos/estrategias/estrategias-de-marketing/marketing-indireto",
    },
    {
      title: "Marketing digital",
      text: "O objetivo do Marketing Digital é disseminar informações com o intuito de influenciar a transação entre comprador e vendedor. Porém a Web vem trazendo vantagens de permitir a interação do consumidor com a peça publicitária, diferentemente de outras mídias. Esta interação é possível apenas com um clique. Para todas as soluções da web o marketing digital torna-se necessário.",
      image: servico05,
      link: "/servicos/online/marketing-digital",
    },
    {
      title: "Marketing Pessoal",
      text: "Diversos parceiros selecionados para realizar a sua pesquisa e assessoria integrada, unindo a comunicação do varejo e do mercado institucional, trazendo a tona questões internas de forma a fortalecer comunicação organizacional.",
      image: servico06,
      link: "/servicos/estrategias/estrategias-de-marketing/marketing-pessoal",
    },
  ];

  return (
    <>
      <Breadcrumbs
        title={"Estratégias de marketing"}
        items={breadcrumbsItens}
      />
      <section id="servicesInsidePage">
        <div className="relative bg_secundary section_padding_clip">
          <div className="container">
            <h2>Tenha um plano bem delineado</h2>

            <p>
              Independente do seu segmento, possuir uma boa estratégia de
              marketing faz toda a diferença na hora de atingir o sucesso e a
              visibilidade.
            </p>

            <p>
              Se já no início, você investir em um plano bem delineado, as
              chances de conquistar e atrair leads qualificados aumenta. Sabemos
              a necessidade de uma boa apresentação na mídia e em outras formas
              de comunicação. Mas sabemos também a melhor forma de sanar todas
              as suas dúvidas e a melhor maneira de te ajudar a expandir o seu
              negócio.
            </p>

            <p>
              Mas você deve está se perguntando, como vou encontrar a forma
              ideal para desenvolver essas estratégias?
            </p>

            <h2>Nós te ajudamos</h2>

            <p>
              Não precisa se preocupar! Hoje está mais fácil ter acesso a
              profissionais e empresas que entendam do segmento de estratégia de
              marketing.
            </p>

            <p>
              O conhecimento nessa área faz toda a diferença, pois especialistas
              qualificados saberão aplicar as melhores soluções e planos de
              acordo com as necessidades do seu negócio.
            </p>

            <p>
              Conheça mais sobre os modelos de marketing e entre em contato
              conosco, para desenvolvermos ótimas estratégias, com ótimos
              resultados.
            </p>
          </div>
        </div>
      </section>

      <section
        id="servicesInsidePageOthers"
        className="box_divider relative bg_secundary section_padding_clip text_center"
      >
        <div className="container">
          <ServiceBox services={services} />
        </div>
      </section>
    </>
  );
};

export default EstrategiasMarketing;
