import { IoClose } from "react-icons/io5";
import { IoLogoWhatsapp } from "react-icons/io";
import { LuPhone } from "react-icons/lu";
import { IoIosMail } from "react-icons/io";
import React, { useEffect, useState } from "react";
import { MdInstallDesktop } from "react-icons/md";

type openContactsProps = {
  openContacts: () => void;
};

const Contacts: React.FC<openContactsProps> = ({ openContacts }) => {
  // Tipagem específica para o evento `beforeinstallprompt`
  const [deferredPrompt, setDeferredPrompt] = useState<Event | null>(null);
  const [, setIsInstallable] = useState<boolean>(false);

  useEffect(() => {
    // Listener para capturar o evento `beforeinstallprompt`
    const handleBeforeInstallPrompt = (e: Event) => {
      e.preventDefault(); // Previne a exibição automática do prompt
      setDeferredPrompt(e); // Salva o evento para uso posterior
      setIsInstallable(true); // Exibe o botão de instalação
    };

    window.addEventListener("beforeinstallprompt", handleBeforeInstallPrompt);

    // Limpeza do listener quando o componente for desmontado
    return () =>
      window.removeEventListener(
        "beforeinstallprompt",
        handleBeforeInstallPrompt
      );
  }, []);

  const handleInstallClick = async () => {
    if (deferredPrompt) {
      // Tipo explícito para acesso ao método `prompt` de instalação
      const promptEvent = deferredPrompt as any;
      promptEvent.prompt(); // Exibe o prompt de instalação

      setDeferredPrompt(null); // Reseta o evento após o uso
      setIsInstallable(false); // Esconde o botão de instalação
    }
  };

  return (
    <>
      <div className="closeMenu" onClick={openContacts}>
        <IoClose />
      </div>
      <ul>
        <li>
          <a
            rel="nofollow noopener"
            title="Mandar WhatsApp para a Boa Imagem"
            href="tel:6139631444"
          >
            <LuPhone />
            (61) 3963-1444
          </a>
        </li>
        <li>
          <a
            target="_blank"
            rel="nofollow noopener"
            title="Ligar para a Boa Imagem"
            href="https://wa.me/556139631444"
          >
            <IoLogoWhatsapp />
            (61) 3963-1444
          </a>
        </li>
        <li>
          <a
            target="_blank"
            rel="nofollow noopener"
            title="Mandar e-mail para a Boa Imagem"
            href="mailto:atendimento@boaimagem.tv"
          >
            <IoIosMail />
            atendimento@boaimagem.tv
          </a>
        </li>
        <li>
          <a onClick={handleInstallClick}>
            <MdInstallDesktop />
            Instalar App
          </a>
        </li>
      </ul>
    </>
  );
};

export default Contacts;
