import Breadcrumbs from "../../../../layouts/Breadcrumbs";
import image from '../../../../../assets/images/servicos/servico-midias-sociais-2.webp';
import { useEffect } from "react";

const MidiasSociais = () => {

    useEffect(() => {
        document.title = "Gerenciamento de Mídias Sociais | Social Midia em Brasília";
        const metaDescription = document.querySelector('meta[name="description"]');
        if (metaDescription) {
          metaDescription.setAttribute(
            "content",
            "A Boa Imagem tem experiência no desenvolvimento de campanhas para mídias sociais, trabalha no gerenciamento das páginas dos clientes e criação de conteúdo."
          );
        }
    }, []);

    const breadcrumbsItens = [
        {
            name: 'Serviços',
            alias: '/servicos'
        },{
            name: 'Online',
            alias: '/online'
        },{
            name: 'Marketing Digital',
            alias: '/marketing-digital'
        },{
            name: 'Mídias Sociais',
            alias: '/midias-sociais'
        }
    ];

    return (
        <>
            <Breadcrumbs title={'Mídias Sociais'} items={breadcrumbsItens}/>
            <section id="servicesPage">
                <div className='relative bg_secundary section_padding_clip'>
                    <div className='container padding'>

                        <p>Na Boa Imagem , cada estratégia é criada de acordo com as metas, mercado e planos de cada cliente. Acompanhe este artigo de Mídias Sociais para entender melhor a atuação da agência Boa Imagem nas redes sociais:</p>


                        <h2>A importância do relacionamento ON-Line</h2>

                        <p>Engajamento é o que as empresas mais necessitam atualmente. Portanto,apenas criar contas em redes sociais de grandes acessos não é o suficiente, é preciso ter um bom plano estratégico e muito conhecimento para que possamos atingir as pessoas e trazê-las para o espaço-empresa, tornando nossos conteúdos cada vez mais atrativos. Para investir na presença empresarial, é necessário ser essencial em redes sociais, sempre frisando a importância do que se oferece. Suas páginas nas redes precisam dispor de conteúdos originais e relevantes. Assim, sua empresa será destaque no dia a dia dos consumidores e de todos que tenham acesso ao conteúdo disponibilizado.</p>


                        <h2>Explorando diferentes canais de comunicação</h2>

                        <p>Através das mídias sociais, é possível divulgar produtos, conhecer e fidelizar os clientes e ficar atento às tendências de mercado. Sites de segmentos específicos, têm como foco principal obter ganhos no uso da imagem do anunciante e fixação da marca. Por essas razões, os usuários das redes, ao compartilhar conteúdo de interesse mútuo, se tornam os principais veículos de transmissão. Assim, é fácil perceber que a comunicação através de sites, redes sociais e blogs proporciona maior aproximação dos consumidores com interesses semelhantes, formando grupos que compartilham experiências e opiniões, tornando-se, por consequência, os maiores consumidores dos produtos veiculados.</p>


                        <h2>Criação de conteúdo inbound para mídias sociais</h2>

                        <p>A Agência Boa Imagem presta um serviço que é planejado e executado conforme a necessidade de cada empresa. Produtos e serviços são desenvolvidos levando em conta o perfil do cliente, seus gostos, sites e outros hábitos de navegação. Além disso, temos experiência no desenvolvimento de campanhas para mídias sociais e trabalhamos no gerenciamento das páginas dos clientes, na produção de conteúdos específicos e segmentados, no monitoramento de menções e interações e na criação de publicações para gerar engajamento e interatividade por parte do público. Através de ações interativas em sites de comunidades, redes sociais e blogs,como descrito acima, fortalecemos a estratégia publicitária com o objetivo de criar e aprimorar relacionamentos com potenciais clientes, favorecendo a marca e a imagem da empresa.</p>

                        <img className="mb-30" src={image} alt="Foto: Serviço de Mídias Sociais" title="Foto: Serviço de Mídias Sociais" width="520" height="283"/>

                        <p>As mídias sociais são um fenômeno já consolidado no Brasil e mundo, não podendo mais serem desconsideradas pelas empresas. No Brasil, mais de 80% dos internautas participam de algum tipo de rede social e,nessa mesma proporção, leem blogs regularmente. A Boa Imagem tem experiência no desenvolvimento de campanhas para mídias sociais e trabalha no gerenciamento de páginas, na produção de conteúdos específicos e segmentados, no monitoramento de menções e interações e na criação de publicações para gerar engajamento e interatividade por parte do público.</p>

                        <p>Entre em contato com a agência Boa Imagem e solicite uma proposta de campanha de publicidade on-line ou marketing nas redes sociais para o seu negócio e comprove a eficácia de nossos serviços digitais!</p>

                    </div>
                </div>
            </section>
        </>
    );
}

export default MidiasSociais;