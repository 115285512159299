import { Link } from "react-router-dom";
import { ServicesInterface } from "../../../interfaces/ServicesInterface";
import Breadcrumbs from "../../../layouts/Breadcrumbs";
import ServiceBox from "../../../layouts/ServiceBox";
import service01 from '../../../../assets/images/servicos/servico-design-de-produto.webp';
import service02 from '../../../../assets/images/servicos/servico-fotografia-publicitaria.webp';
import service03 from '../../../../assets/images/servicos/servico-videos-e-animacoes.webp';
import service04 from '../../../../assets/images/servicos/servico-propaganda-de-televisao.webp';
import service05 from '../../../../assets/images/servicos/servico-propaganda-em-radios.webp';
import service06 from '../../../../assets/images/servicos/servico-propaganda-em-revistas.webp';
import { useEffect } from "react";

const Pesquisa = () => {

    useEffect(() => {
        document.title = "Pesquisa Qualitativa e Quantitativa. Estudo de Mercado | Brasília";
        const metaDescription = document.querySelector('meta[name="description"]');
        if (metaDescription) {
          metaDescription.setAttribute(
            "content",
            "Prestamos serviço de assessoria integrada unindo a comunicação com as tendências de mercado e  trazendo questões para fortalecer comunicação organizacional."
          );
        }
    }, []);

    const breadcrumbsItens = [
        {
            name: 'Serviços',
            alias: '/servicos'
        },{
            name: 'Estratégias',
            alias: '/estrategias'
        },{
            name: 'Pesquisa de mercado',
            alias: '/pesquisa'
        }
    ];

    const services: ServicesInterface[] = [
        {
            title: 'Design de produto',
            text: 'Estamos preparados para desenvolver a criação do design de produto, peças publicitárias e outros modelos que podem fazer parte da necessidade da sua empresa.',
            image: service01,
            link: '/servicos/offline/design',
        },{
            title: 'Fotografia publicitária',
            text: 'A fotografia publicitária é um serviço diferenciado, não é apenas uma foto. Com uma boa composição e com um projeto bem elaborado, o resultado com certeza irá satisfazer o cliente.',
            image: service02,
            link: '/servicos/offline/fotografia-publicitaria',
        },{
            title: 'Vídeos e animações',
            text: 'Produzimos vídeos comerciais e publicitários, além de atendermos demanda para novos espaços como youtube e novas mídias. No modelo de animação 2D e 3D a criação do conteúdo pode ser feito de maneira completa.',
            image: service03,
            link: '/servicos/offline/videos',
        },{
            title: 'Propaganda de televisão',
            text: 'As telinhas impactam milhões de brasileiros todos os dias, a mídia televisiva influencia a formação de opinião, apresentando aspectos de modernidade que causam transformações no sistema econômico.',
            image: service04,
            link: '/servicos/offline/propaganda-de-televisao',
        },{
            title: 'Propaganda em rádios',
            text: 'As rádios tem o seu público cativo, um veículo de divulgação muito procurado pelos anunciantes pois pode ele oferece um baixo custo. Ou seja, para produzir e vincular o valor é mais em conta.',
            image: service05,
            link: '/servicos/offline/propaganda-de-radio',
        },{
            title: 'Propaganda em revistas',
            text: 'A propaganda de revista possibilita o melhor tempo de leitura. Quando determinada pessoa está lendo uma revista, a atenção está voltada para as páginas, assim as chances de interferência nos anúncios é mínimo.',
            image: service06,
            link: '/servicos/offline/propaganda-de-revista',
        }
    ];

    return (
        <>
            <Breadcrumbs title={'Pesquisa de Mercado: Conheça os Segredos do seu Público-Alvo'} items={breadcrumbsItens}/>
            <section id="servicesInsidePage">
                <div className='relative bg_secundary section_padding_clip'>
                    <div className='container'>

                        <h2 className="title_divider">Análises que fazem a diferença para descobrir as Necessidades do seu Mercado</h2>

                        <p>Conectamos você aos melhores institutos e ferramentas de pesquisa específicas para a sua demanda. A análise dos dados para aplicação nas estratégias de negócio da sua empresa é por nossa conta.</p>

                        <p>Em momento de expansão dos negócios, é fundamental alinhar as expectativas e garantir que você está próximo de alcançar o público certo e oferecer soluções que realmente atendam às necessidades do seu mercado. Entendemos a importância das pesquisas para impulsionar o crescimento dos negócios. Embora não realizemos diretamente o serviço de pesquisa de mercado, somos especialistas em conectar você com um instituto de pesquisa altamente qualificado, capaz de fornecer insights valiosos sobre o seu público-alvo.</p>

                        <p>As pesquisas existem justamente para avaliar questões específicas de mercado e são uma ferramenta estratégica essencial para qualquer empresa que queira se destacar em um cenário altamente competitivo. Uma ferramenta que possibilita conhecer detalhes sobre as necessidades, preferências e comportamentos do seu público-alvo, permitindo que você tome decisões embasadas e crie estratégias eficientes de marketing. Com o conhecimento adquirido através da pesquisa de mercado, você pode adaptar seus produtos, otimizar sua comunicação e melhorar o seu branding e o posicionamento correto da sua empresa em relação aos concorrentes.</p>

                        <h2>Por que a Boa Imagem indica fazer pesquisas de mercado?</h2>

                        <p>Você pode estar se perguntando por que a Boa Imagem precisa de pesquisas para criar boas estratégias no planejamento de comunicação? A resposta é simples: nossa agência tem anos de experiência em parcerias estratégicas com institutos de pesquisa de renome. Trabalhamos apenas com os melhores profissionais do setor, que possuem expertise em métodos de pesquisa avançados e conhecimento aprofundado em diversas áreas de mercado. Utilizando dados de qualidade obtidos em pesquisas específicas a estratégia desenvolvida consegue criar diferenciais exclusivos na estratégia de negócios e comunicação.</p>

                        <h2 className="text_center">Quer saber como a Boa Imagem pode ajudar você nesse quesito? Clique no botão ao lado para conversar conosco:</h2>

                        <p><Link className="btn" to={'/contato'}>Fale Conosco!</Link></p>

                        <p>Antes mesmo de optar por fazer a sua pesquisa de mercado, conte conosco para analisar e traçar estratégias e assim garantir que os dados obtidos cumpram objetivos de comunicação e possam gerar uma série de vantagens significativas:</p>		
		
                        <ul className="list">
                            <li><b>Acesso a especialistas altamente qualificados:</b> Nossa agência possui uma extensa rede de parceiros de pesquisa de mercado, cuidadosamente selecionados com base em sua reputação e histórico de sucesso. Trabalhamos apenas com especialistas altamente qualificados, que conhecem os métodos mais eficazes para coletar e analisar dados relevantes.</li>
                            <li><b>Economia de tempo e recursos:</b> terceirizar a pesquisa de mercado permite que você foque no seu core business, enquanto nós cuidamos dos aspectos técnicos da pesquisa. Isso economiza seu tempo e recursos, permitindo que você alcance resultados mais rapidamente.</li>
                            <li><b>Análise abrangente de dados:</b> nossos parceiros de pesquisa de mercado são especialistas em analisar e interpretar dados complexos. Eles fornecerão relatórios detalhados e insights acionáveis, que você poderá usar para tomar decisões informadas e estratégicas.</li>
                            <li><b>Pesquisa personalizada para atender às suas necessidades:</b> a Boa Imagem trabalha em estreita colaboração com você para entender suas metas e objetivos de negócios. Com base nessa compreensão, nós nos associamos ao instituto de pesquisa mais adequado ao seu setor, garantindo que os resultados sejam adaptados às suas necessidades específicas.</li>
                            <li><b>Confidencialidade e ética:</b> Na nossa agência, valorizamos a confidencialidade e a ética em todas as nossas parcerias. Os institutos de pesquisa com os quais trabalhamos seguem rigorosos padrões éticos e garantem a confidencialidade dos dados coletados. Você pode confiar que suas informações estarão protegidas e tratadas com o máximo cuidado.</li>
                            <li><b>Abordagem abrangente de pesquisa de mercado:</b> Nossos parceiros de pesquisa de mercado utilizam uma variedade de métodos e técnicas para obter insights valiosos. Isso pode incluir pesquisas de opinião, análise de tendências, estudos de comportamento do consumidor, análise de concorrência e muito mais. Com essa abordagem abrangente, você terá uma compreensão completa do seu mercado e das oportunidades disponíveis.</li>
                            <li><b>Permaneça à frente da concorrência:</b> Ao investir em uma pesquisa de mercado terceirizada, você ganha uma vantagem competitiva significativa. Com insights atualizados sobre as necessidades e preferências do seu público-alvo, você poderá antecipar tendências, identificar lacunas no mercado e desenvolver estratégias inovadoras para se destacar da concorrência.</li>
                            <li><b>Resultados mensuráveis e mensagens convincentes:</b> A pesquisa de mercado fornece dados concretos que podem ser medidos e utilizados para comunicar mensagens persuasivas aos seus clientes. Com base nos resultados obtidos, você poderá direcionar sua mensagem de marketing de forma mais eficaz, aumentando a probabilidade de engajamento e conversão.</li>
                            <li><b>Melhor retorno sobre o investimento:</b> Ao tomar decisões de negócios baseadas em dados sólidos provenientes de uma pesquisa de mercado, você está maximizando o retorno sobre o investimento. Evite gastos desnecessários e aloque seus recursos com eficiência, direcionando suas estratégias de marketing e desenvolvendo produtos e serviços que realmente atendam às demandas do mercado.</li>
                        </ul>

                        <h2>Comunicação</h2>

                        <p>A comunicação da entidade com o público interno é de extrema importância para o crescimento dela, pois funcionários e parceiros que conhecem a missão da empresa e enxergam os resultados obtidos, podem se orgulhar de seu trabalho.</p>

                        <p>Desk Research: leitura, análise e entrega de relatório estratégico com informações existentes de uma forma lógica, organizando os dados, facilitando o entendimento do conteúdo, evitando o re-trabalho e repetição de estudos/pesquisas já realizados.</p>

                        <h2>Estudos qualitativos  de comunicação importantes avaliar:</h2>

                        <ul className="list">
                            <li>Conceitos e ideias;</li>
                            <li>Logos, nomes, embalagens;</li>
                            <li>Comunicação;</li>
                            <li>Produtos, marcas e concorrência;</li>
                            <li>Grupos Focais em todo Brasil e América Latina;</li>
                            <li>Entrevistas em Profundidade com diversos públicos;</li>
                            <li>Teste de produtos em Central Location ou domicílio;</li>
                            <li>Usabilidade;</li>
                            <li>Tecnologia.</li>
                        </ul>

                        <p>Os estudos são desenvolvidos de acordo com as necessidades de cada cliente.</p>
                        <p>A seguir apresentamos alguns tipos de pesquisas mais realizadas:</p>

                        <h2>Diferentes objetivos para pesquisas de mercado</h2>

                        <ul className="list">
                            <li>Hábitos, desejos e motivações de consumidores;</li>
                            <li>Comportamento de compra, uso e consumo;</li>
                            <li>Lançamento de produtos (conceito, nome, sabor e embalagem);</li>
                            <li>Imagem de produtos já existentes no mercado;</li>
                            <li>Identificação de potencial de mercado;</li>
                            <li>Campanhas de comunicação (pré-testes, recall e pós-testes);</li>
                            <li>Posicionamento de marcas;</li>
                            <li>Segmentação de mercado;</li>
                            <li>Estudos da imagem corporativa;</li>
                            <li>Avaliação da concorrência;</li>
                            <li>Estudos de preço;</li>
                            <li>Nível de satisfação de clientes;</li>
                            <li>Clima organizacional;</li>
                            <li>Mistery Shopping;</li>
                            <li>Comportamento de usuários na internet.</li>
                        </ul>

                        <h2>Objetivos para Pesquisas de opinião pública</h2>

                        <ul className="list">
                            <li>Avaliação e sondagens da opinião pública;</li>
                            <li>Estudos de questões sociais, políticas e econômicas;</li>
                            <li>Atitudes, crenças e valores socioculturais;</li>
                            <li>Mudanças socioculturais;</li>
                            <li>Estudos de cultura e consciência política;</li>
                            <li>Investigações psicográficas.</li>
                        </ul>

                        <h2>Alguns objetivos para Pesquisas com intenções políticas e sociais</h2>

                        <ul className="list">
                            <li>Dividem-se em dois tipos: eleitoral e administrativa (em que se investigam os processos de tomada de decisões públicas em diferentes níveis de governo);</li>
                            <li>Avaliação de políticas públicas;</li>
                            <li>Avaliação de Administrações Públicas;</li>
                            <li>Evolução e acompanhamento da atuação do governo, partidos políticos e seus líderes;</li>
                            <li>Análise da incidência dos valores e tendências socioculturais sobre a formação da imagem públicas dos dirigentes;</li>
                            <li>Cenário político e diagnóstico de campanhas eleitorais;</li>
                            <li>Acompanhamento de intenções de voto;</li>
                            <li>Resultados eleitorais;</li>
                            <li>Geografia eleitoral;</li>
                            <li>Seleção de estratégias de campanhas políticas;</li>
                            <li>Conteúdo de mensagens políticas e repercussão dos discursos políticos;</li>
                            <li>Análise das propagandas de campanha eleitoral;</li>
                            <li>Acompanhamento do impacto das campanhas de comunicação: recall, controle e correção do desenvolvimento da campanha;</li>
                            <li>Perfil e imagem do candidato e comparação os dos candidatos concorrentes.</li>
                        </ul>

                        <p>Em resumo, a Boa Imagem precisa fazer pesquisas de mercado  para oferecer a você a oportunidade de aproveitar o conhecimento e a experiência de mercado e marketing para que você tenha os melhores insights sobre seu público-alvo. Nossa parceria estratégica permite que você concentre-se no crescimento do seu negócio, enquanto nos encarregamos de fornecer as informações necessárias para o seu sucesso.</p>
                        <p>Entre em contato conosco hoje mesmo e descubra como nossa agência pode ajudar você a obter uma vantagem competitiva por meio de uma pesquisa de mercado personalizada e eficiente. Não deixe suas decisões de negócios ao acaso. Conheça o seu mercado, conheça o seu público-alvo e alcance resultados excepcionais com as nossas análises especializadas em pesquisa de mercado.</p>
                        <p>Lembre-se: o conhecimento é poder. Invista em pesquisa para conhecer melhor o seu mercado  e para impulsionar o crescimento dos seus projetos.</p>

                        <p>Assim fortalecemos a empresa em seus mínimos detalhes. Fornecemos aos nossos clientes a oportunidade de entrarem no mundo da comunicação integrada.</p>

                        <h3>Também oferecemos assessorias para o seu negócio</h3>
                        <h2 className="text_center">Clique no botão abaixo e vamos juntos!</h2>  
                        <p><Link className="btn" to={'/contato'}>Fale Conosco!</Link></p>                      

                        <h2 className="title_divider mb-30">Entenda outras áreas de atuação da agência:</h2>
                    </div>

                    </div>
            </section>

            <section id="servicesInsidePageOthers"  className='box_divider relative bg_secundary section_padding_clip text_center'>
                <div className='container'>
                    <ServiceBox services={services} />
                </div>
            </section>
        </>
    );
}

export default Pesquisa;