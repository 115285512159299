import { useEffect } from "react";
import Breadcrumbs from "../../../layouts/Breadcrumbs";

const MarketingVarejo = () => {

    useEffect(() => {
        document.title = "Marketing de Varejo | Boa Imagem";
        const metaDescription = document.querySelector('meta[name="description"]');
        if (metaDescription) {
          metaDescription.setAttribute(
            "content",
            "No dinâmico mundo do varejo, conectar-se de forma eficaz com seus clientes é essencial. Na Boa Imagem, entendemos que o sucesso no varejo vai além de simplesmente exibir produtos – trata-se de criar experiências memoráveis e envolventes que fidelizam os consumidores e impulsionam as vendas."
          );
        }
    }, []);
    
    const breadcrumbsItens = [
        {
            name: 'Serviços',
            alias: '/servicos'
        },{
            name: 'Online',
            alias: '/online'
        },{
            name: 'Marketing de Varejo',
            alias: '/marketing-de-varejo'
        }
    ];

    return (
        <>
            <Breadcrumbs title={'Marketing de Varejo'} items={breadcrumbsItens}/>
            <section id="servicesPage">
                <div className='relative bg_secundary section_padding_clip'>
                    <div className='container padding'>
                      
                        <p>No dinâmico mundo do varejo, conectar-se de forma eficaz com seus clientes é essencial. Na Boa Imagem, entendemos que o sucesso no varejo vai além de simplesmente exibir produtos – trata-se de criar experiências memoráveis e envolventes que fidelizam os consumidores e impulsionam as vendas.</p>

                        <p>Nossa abordagem ao marketing de varejo é personalizada e baseada em dados, garantindo que cada campanha seja projetada para atender às necessidades específicas do seu negócio. Aqui estão algumas das áreas em que podemos ajudar:</p>

                        <h3><strong>Entendimento do público-alvo</strong></h3>
                        <p>Utilizamos técnicas avançadas de segmentação e análise de comportamento para compreender o que motiva seus clientes. Com isso, podemos criar campanhas direcionadas que ressoam com seus interesses e necessidades.</p>

                        <h3><strong>Experiência de compra</strong></h3>
                        <p>Desenvolvemos estratégias de merchandising que otimizam a apresentação dos seus produtos, seja em lojas físicas ou online. Nossos especialistas garantem que o layout, a sinalização e os displays sejam visualmente atraentes e eficazes na conversão de vendas.</p>

                        <h3><strong>Promoções que engajam</strong></h3>
                        <p>Criamos campanhas promocionais irresistíveis que não apenas atraem novos clientes, mas também incentivam a fidelização. De descontos sazonais a programas de recompensas personalizados, nossos especialistas estão prontos para te ajudar.</p>

                        <p>A Boa Imagem está preparada para encarar qualquer desafio do varejo. Combinamos criatividade, inovação e uma profunda compreensão do mercado para desenvolver campanhas que realmente fazem a diferença. </p>

                        <p>Entre em contato conosco hoje mesmo e fortaleça sua marca.</p>

                    </div>
                </div>
            </section>
        </>
    );
}

export default MarketingVarejo;