import Breadcrumbs from "../../../layouts/Breadcrumbs";
import image01 from '../../../../assets/images/servicos/servico-placas-e-fachadas-1.webp';
import image02 from '../../../../assets/images/servicos/servico-placas-e-fachadas-2.webp';
import image03 from '../../../../assets/images/servicos/servico-placas-e-fachadas-3.webp';
import image04 from '../../../../assets/images/servicos/servico-placas-e-fachadas-4.webp';
import image05 from '../../../../assets/images/servicos/servico-placas-e-fachadas-6.webp';
import image06 from '../../../../assets/images/servicos/servico-placas-e-fachadas-7.webp';
import image07 from '../../../../assets/images/servicos/servico-placas-e-fachadas-8.webp';
import { useEffect } from "react";

const PlacasFachadas = () => {

    useEffect(() => {
        document.title = "Placas e Fachadas | Agência Boa Imagem";
        const metaDescription = document.querySelector('meta[name="description"]');
        if (metaDescription) {
          metaDescription.setAttribute(
            "content",
            "Investir em placas ou em uma fachada nova pode mudar a percepção do cliente a respeito da sua marca. Saiba as vantagens desse investimento!"
          );
        }
    }, []);

    const breadcrumbsItens = [
        {
            name: 'Serviços',
            alias: '/servicos'
        },{
            name: 'Offline',
            alias: '/offline'
        },{
            name: 'Placas e fachadas',
            alias: '/placas-e-fachadas'
        }
    ];

    return (
        <>
            <Breadcrumbs title={'Placas e fachadas'} items={breadcrumbsItens}/>
            <section id="servicesPage">
                <div className='relative bg_secundary section_padding_clip'>
                    <div className='container'>

                        <h2 className="title_divider mb-30">Placas e fachadas</h2>

                        <p>O mercado está cada vez mais competitivo. A cada dia novos negócios surgem, aquecendo a concorrência e levando empresários a investir em ações e ferramentas que possam reverter o quadro e consequentemente levar ao lucro.</p>

                        <p>Muitos donos de negócios tem recorrido a estratégias de Marketing Digital, anúncios pagos e tantas outras opções online ou offline, que sem sombra de dúvidas são muito eficientes quando executadas da forma certa.</p>

                        <blockquote>
                        <p>“O problema não está na estratégia escolhida, mas sim na forma como sua empresa pode estar sendo apresentada ao público.”</p>
                        </blockquote>

                        <p>Não é inteligente investir em anúncios de promoção para levar novos clientes a sua loja física, se a fachada está ilegível ou mal apresentada, o que pode dificultar a localização do seu negócio para o seu futuro cliente. Ou até mesmo a falta de placas sinalizando a promoção, direção, etc.</p>

                        <p>Você com certeza já ouviu falar na expressão: “A primeira impressão é a que fica.”</p>

                        <p>Investir em placas ou em uma fachada nova pode mudar a percepção do cliente a respeito da sua marca. Uma imagem bem construída, representa mais que beleza visual, está atrelada a características positivas como: organização, profissionalismo e seriedade.</p>

                        <h2>Fachada: invista na imagem do seu negócio</h2>

                        <p>Existem 3 ótimos motivos para mudar o visual da fachada da sua empresa:</p>

                        <ul>
                        <li><b>Encante seu cliente</b> – Empresas com fachadas bonitas, causam ao consumidor o desejo de entrar, mesmo que apenas para conhecer. Na maioria das vezes, o ato de conhecer acaba virando uma oportunidade de negócio.</li>
                        <li><b>Saia na frente da concorrência</b> – Ao se destacar do seu concorrente, as chances de lucro aumentam consideravelmente. A sobrevivência da sua empresa está diretamente ligada a <b>quantos clientes deixam de ir ao seu concorrente para comprar no seu negócio</b>.</li>
                        <li><b>Gere mais vendas</b> – Construa a identidade visual da sua marca de forma coerente, assim seus clientes tendem a associar uma imagem positiva de forma automática, gerando reconhecimento e fidelização.</li>
                        </ul>

                        <p>Confira alguns exemplos de antes e depois que demonstram a importância de renovar ou investir em uma nova fachada.</p>

                        <img src={image01} alt="Foto: Serviços de Placas, Fachadas, Orçamento, DF, Brasília" title="Foto: Serviços de Placas, Fachadas, Orçamento, DF, Brasília" width="539" height="118"/>
                        <p className="text_center"><small>Fonte: Artmax</small></p>

                        <img src={image02} alt="Foto: Serviços de Placas, Fachadas, Orçamento, DF, Brasília" title="Foto: Serviços de Placas, Fachadas, Orçamento, DF, Brasília" width="542" height="169"/>
                        <p className="text_center"><small>Fonte: XPREZ</small></p>

                        <img src={image03} alt="Foto: Serviços de Placas, Fachadas, Orçamento, DF, Brasília" title="Foto: Serviços de Placas, Fachadas, Orçamento, DF, Brasília" width="544" height="174"/>
                        <p className="text_center"><small>Fonte: XPREZ</small></p>


                        <h2>PLACAS: DESTAQUE SUA EMPRESA</h2>

                        <p>Internas ou externas, as placas podem ter diferentes finalidades como informar, direcionar, promover produtos/serviços. Podem ser usadas até mesmo para destacar o nome e ramo da sua empresa, como “fachada”.</p>

                        <p>A placa pode dar o toque especial que a sua empresa precisa, além disso comporta diversos estilos e materiais como ACM (o mais utilizado) ou em MDF.</p>

                        <p>Dentre seus diversos objetivos, destacam-se 3:</p>

                        <ul>
                        <li>
                            <b>Institucional</b> – A placa pode ser usada como fachada da sua empresa. Enquanto não há verba para investir em uma fachada, esta alternativa é uma ótima opção de passar uma imagem positiva da sua empresa.
                            <img className="mb-30 mt-30" src={image04} alt="Foto: Serviços de Placas, Fachadas, Orçamento, DF, Brasília" title="Foto: Serviços de Placas, Fachadas, Orçamento, DF, Brasília" width="602" height="451"/>
                        </li>

                        <li>
                            <b>Informativo</b> – São placas usadas para sinalizar determinada direção ou a localização atual para o seu cliente. Também pode ser considerada como sinalização interna. Ideais para a organização e padronização da sua empresa.
                            <img className="mb-30 mt-30" src={image05} alt="Foto: Serviços de Placas, Fachadas, Orçamento, DF, Brasília" title="Foto: Serviços de Placas, Fachadas, Orçamento, DF, Brasília" width="590" height="681"/>
                        </li>

                        <li>
                            <b>Promocional</b> – Tem como objetivo indicar ofertas e promoções da empresa, de forma a facilitar que o consumidor encontre com facilidade o que procura ou para destacar uma oferta específica.
                            <img className="mb-30 mt-30" src={image06} alt="Foto: Serviços de Placas, Fachadas, Orçamento, DF, Brasília" title="Foto: Serviços de Placas, Fachadas, Orçamento, DF, Brasília" width="602" height="472"/>
                        </li>
                        </ul>

                        <h2>RENOVAÇÃO DE FACHADA</h2>
                        Projeto em 3D realizado pela Boa Imagem para a empresa Mood Gelato e Café.

                        <h2>COLOQUE SUA EMPRESA NA MÃO DE QUEM ENTENDE!</h2>
                        <p>Desde 2009 ajudamos empresas de Brasília e de todo o país a mudar sua imagem, gerando resultados positivos e fidelizando clientes.</p>

                        <p>Sua empresa precisa de uma agência que saiba trabalhar com qualidade e com o compromisso de construir a imagem que atrai os clientes certos para o seu negócio. Conte com a Boa Imagem! Solicite seu orçamento.</p>
                        <img src={image07} alt="Foto: Serviços de Placas, Fachadas, Orçamento, DF, Brasília" title="Foto: Serviços de Placas, Fachadas, Orçamento, DF, Brasília" width="576" height="189"/>

                        <h2>COLOQUE SUA EMPRESA NA MÃO DE QUEM ENTENDE!</h2>
                        <p>Desde 2009 ajudamos empresas de Brasília e de todo o país a mudar sua imagem, gerando resultados positivos e fidelizando clientes.</p>

                        <p>Sua empresa precisa de uma agência que saiba trabalhar com qualidade e com o compromisso de construir <b>a imagem que atrai os clientes certos para o seu negócio</b>. Conte com a Boa Imagem! Solicite seu orçamento.</p>

                    </div>
                </div>
            </section>
        </>
    );
}

export default PlacasFachadas;