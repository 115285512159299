import { ServicesInterface } from "../../../interfaces/ServicesInterface";
import Breadcrumbs from "../../../layouts/Breadcrumbs";
import ServiceBox from "../../../layouts/ServiceBox";
import assessorias from "../../../../assets/images/servicos/servico-assessorias.webp";
import consultoria from "../../../../assets/images/servicos/servico-consultoria.webp";
import estrategiasMarketing from "../../../../assets/images/servicos/servico-estrategias-de-marketing.webp";
import pesquisaMercado from "../../../../assets/images/servicos/servico-pesquisa-de-mercado.webp";
import marketingPessoal from "../../../../assets/images/servicos/servico-marketing-politico-e-eleitoral.webp";
import planejamentoMidia from "../../../../assets/images/servicos/servico-planejamento-de-midia.webp";
import { useEffect } from "react";

const Estrategias = () => {
  useEffect(() => {
    document.title = "Assessoria, Consultoria e Estratégias de Marketing";
    const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
      metaDescription.setAttribute(
        "content",
        "Uma boa estratégia de marketing faz toda a diferença na hora de atingir o sucesso e a visibilidade. Por isso, seja qual for seu segmento, invista em planejamento estratégico."
      );
    }
  }, []);

  const breadcrumbsItens = [
    {
      name: "Serviços",
      alias: "/servicos",
    },
    {
      name: "Estratégias",
      alias: "/estrategias",
    },
  ];

  const services: ServicesInterface[] = [
    {
      title: "Assessorias",
      text: "A Agência Boa Imagem realiza assessoria em várias áreas. Nossos profissionais possuem amplo conhecimento em comunicação organizacional e empresarial.",
      image: assessorias,
      link: "/servicos/estrategias/assessorias",
    },
    {
      title: "Consultoria",
      text: "Hoje em dia é fundamental que as empresas possuam uma boa visibilidade no mundo virtual, principalmente empresas que fazem a consultoria de vendas online.",
      image: consultoria,
      link: "/servicos/estrategias/consultoria-de-vendas-online",
    },
    {
      title: "Estratégias de marketing",
      text: "Independente do seu segmento, possuir uma boa estratégia de marketing faz toda a diferença na hora de atingir o sucesso e a visibilidade.",
      image: estrategiasMarketing,
      link: "/servicos/estrategias/estrategias-de-marketing",
    },
    {
      title: "Pesquisa de mercado",
      text: "Diversos parceiros selecionados para realizar a sua pesquisa e assessoria integrada, unindo a comunicação do varejo e do mercado institucional, trazendo a tona questões internas de forma a fortalecer comunicação organizacional.",
      image: pesquisaMercado,
      link: "/servicos/estrategias/pesquisa",
    },
    {
      title: "Marketing Pessoal",
      text: "Diversos parceiros selecionados para realizar a sua pesquisa e assessoria integrada, unindo a comunicação do varejo e do mercado institucional, trazendo a tona questões internas de forma a fortalecer comunicação organizacional.",
      image: marketingPessoal,
      link: "/servicos/estrategias/estrategias-de-marketing/marketing-pessoal",
    },
    {
      title: "Planejamento de mídia",
      text: "Muitos empreendedores não se preocupam tanto com o depois de ter uma boa ideia. Poucos sabem que para o sucesso de um empreendimento é necessário um planejamento bem delineado.",
      image: planejamentoMidia,
      link: "/servicos/estrategias/planejamento",
    },
  ];

  return (
    <>
      <Breadcrumbs title={"Serviços de estratégias"} items={breadcrumbsItens} />
      <section
        id="servicesPage"
        className="box_divider relative bg_secundary section_padding_clip text_center"
      >
        <div className="container">
          <ServiceBox services={services} />
        </div>
      </section>
    </>
  );
};

export default Estrategias;
