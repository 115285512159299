import { useEffect } from "react";
import Breadcrumbs from "../../../../layouts/Breadcrumbs";

const MarketingDireto = () => {

    useEffect(() => {
        document.title = "Marketing Direto: Vantagens deste conjunto de ações para a sua marca e vendas";
        const metaDescription = document.querySelector('meta[name="description"]');
        if (metaDescription) {
          metaDescription.setAttribute(
            "content",
            "A estratégia de marketing direto é extremamente personalizada, podendo alcançar facilmente um público específico, além de oferecer um resultado rápido!"
          );
        }
    }, []);

    const breadcrumbsItens = [
        {
            name: 'Serviços',
            alias: '/servicos'
        },{
            name: 'Estratégias',
            alias: '/estrategias'
        },{
            name: 'Estratégias de marketing',
            alias: '/estrategias-de-marketing'
        },{
            name: 'Marketing Direto',
            alias: '/marketing-direto'
        }
    ];

    return (
        <>
            <Breadcrumbs title={'Marketing Direto'} items={breadcrumbsItens}/>
            <section id="servicesInsidePage">
                <div className='relative bg_secundary section_padding_clip'>
                    <div className='container'>
                        <h2 className="title_divider mb-30">Marketing direto: Conheça a estratégia que vai alavancar as suas vendas!</h2>

                        <p>Se você quer dar um <b>UP</b> nas vendas da sua empresa esse post foi feito para você!</p>

                        <p>Vamos conversar um pouco sobre marketing direto? Basicamente esse modelo de marketing é uma forma de fazer um contato direto com potenciais clientes (leads qualificados) com o objetivo de divulgar e vender mais, seja um serviço ou produto.</p>

                        <p>Essa estratégia é extremamente focada e personalizada, podendo alcançar facilmente um público específico, além de oferecer um resultado rápido! Convencer pessoas que já estão interessadas no que a sua empresa pode oferecer é mais prático. A abordagem do marketing direto será feita especificamente para esse público e isso aumenta o índice de conversão.</p>

                        <p>Se você chegou até aqui é porque estamos nos entendendo!</p>

                        <h2>Como posso definir o meu público alvo?</h2>

                        <p>Definir o seu público em potencial não é algo muito simples, mas se levarmos em conta que estaremos entrando em contato com pessoas que já se interessam na sua marca, a comunicação entre empresa e público fica mais assertivo.</p>

                        <p>Conhecer os dados dessas pessoas e saber o que elas procuram é fundamental. Devemos também respeitar a privacidade e escolha desses leads, pois incomodar o leitor com uma cartela de venda que não lhe interessa ou forçar promoções que não combinam com o seu perfil, através de e-mails ou contato direto, pode deixar uma impressão ruim.</p>

                        <h2>Como posso abordar esse público?</h2>

                        <p>Depois de definir o seu público-alvo, precisamos determinar como faremos a melhor abordagem. Tudo depende dos dados que foram coletados do estudo de seus potenciais clientes.</p>

                        <p>Por exemplo, quando o seu público é mais tradicional é válido oferecer folders que exponham os seus serviços ou produtos. Caso o seu público seja jovem, apostar em mídias sociais pode ser uma forma interessante de fidelizar esses clientes.</p>

                        <p>A forma como será feito esse contato é muito importante independente do público.</p>

                        <h2>Boas estratégias:</h2>

                        <h3>1. Senso de urgência</h3>

                        <p>Essa ferramenta pode ser explorada de várias maneiras positivas. Por exemplo, se você possui um e-commerce, uma ótima maneira de atrair o público até a conversão é oferecendo promoções, e é aí que o marketing direto entra.</p>

                        <p>Oferecer ofertas com tempo curto de duração, não deixa tempo para que os seus clientes fiquem indecisos. Ou seja, eles ficam mais propícios a aceitarem a oferta e efetuarem a compra.</p>

                        <h3>2. Preocupação constante</h3>

                        <p>Manter uma preocupação com o que o seu público busca é essencial, não se pode lembrar dele apenas no momento de fazer uma oferta. É importante manter um contato interessante com os seus leads constantemente.</p>

                        <p>Além disso é preciso oferecer conteúdo de qualidade e que gere valor para quem acessa o seu site, isso aumenta a sua audiência e consequentemente é criado um vínculo com o seu público, eles se tornarão mais atentos e fiéis ao seu serviço ou produto.</p>

                        <h2>Agora como alcançar o UP?</h2>

                        <p>Chegamos na melhor parte, apesar de não ser muita novidade é preciso ter em mente que a função de pequenas ações faz uma grande diferença na hora de atingir com o marketing direto.</p>

                        <p>Como já conversamos, o marketing direto é algo único, personalizado e é isso que proporciona a grande taxa de conversão, mas é preciso utiliza-lo principalmente em produtos e serviços, ou melhor, na forma de apresentar esses produtos e serviços.</p>

                        <p>FAREMOS ISSO COM O OBJETIVO DE ATINGIR UM FECHAMENTO RÁPIDO, CRIANDO DE UMA MANEIRA PERSUASIVA UMA COMUNICAÇÃO QUE CRIA UMA CONEXÃO COM O LEAD.</p>

                        <h4>Quem pode me ajudar?</h4>

                        <p>Profissionais que entendam do segmento são capazes de criar um plano estratégico bem completo, iniciando com a seleção dos leads qualificados, estruturando seus serviços e produtos da melhor maneira até chegar no ponto final que é o convencimento do cliente através do marketing direto e finalmente a conversão na compra ou contratação do serviço.</p>

                        <p><b>Não hesite em conhecer a nível profissional o que uma agência preparada pode fazer por você! Entre em contato e agende já uma reunião!</b></p>                        
                    </div>
                </div>
            </section>
        </>
    )
}

export default MarketingDireto;
