import { useEffect, useState } from "react";
import Breadcrumbs from "../layouts/Breadcrumbs";
import { Link, useSearchParams } from "react-router-dom";
import { PostInterface } from "../interfaces/PostInterface";
import { PaginationInterface } from "../interfaces/PaginationInterface";
import Pagination from "../layouts/Pagination";

const Blog = () => {
  const [searchParams] = useSearchParams();
  const [posts, setPosts] = useState<PaginationInterface<PostInterface> | null>(
    null
  );
  const page = searchParams.get("page") ?? 1;

  useEffect(() => {
    if (page === 1) {
      document.title =
        "Blog | Conteúdo exclusivo da Agência Boa Imagem para você";
      const metaDescription = document.querySelector(
        'meta[name="description"]'
      );
      if (metaDescription) {
        metaDescription.setAttribute(
          "content",
          "`Artigos de Marketing disponíveis para enriquecer ainda mais o seu conhecimento. Aproveite e leia as nossas postagens.`"
        );
      }
    } else {
      document.title = `Blog | Conteúdo exclusivo da Agência Boa Imagem para você | Página: ${page}`;
      const metaDescription = document.querySelector(
        'meta[name="description"]'
      );
      if (metaDescription) {
        metaDescription.setAttribute(
          "content",
          `Artigos de Marketing disponíveis para enriquecer ainda mais o seu conhecimento. Aproveite e leia as nossas postagens. | Página: ${page}`
        );
      }
    }

    const fetchPost = async () => {
      try {
        const res = await fetch(
          `https://boaimagem.org/api/posts.php/?limit=10&content_limit=110&page=${page}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        const post = await res.json();
        setPosts(post);
      } catch (err) {
        console.log(err);
      }
    };
    fetchPost();
  }, [page]);

  const breadcrumbsItens = [
    {
      name: "Blog",
      alias: "/blog",
    },
  ];

  return (
    <>
      <Breadcrumbs title="Blog" items={breadcrumbsItens} />
      <section
        id="blogPage"
        className="box_divider relative bg_secundary section_padding_clip"
      >
        <div className="container">
          {posts?.data &&
            posts.data.map((post, index) => (
              <div className="item" key={index}>
                <h2>{post.post_title}</h2>
                <img
                  width="400"
                  height="185"
                  alt={post.post_title}
                  title={post.post_title}
                  src={`https://boaimagem.org/images/noticias-webp/${post.imagem.replace(
                    /\.(png|jpg)$/,
                    "-thumb.webp"
                  )}`}
                />
                <p>{post.text.replace(/<\/?[^>]+(>|$)/g, "")}...</p>
                <Link
                  className="btn btn-right btn-margin-top"
                  to={`/blog/${post.post_name}`}
                >
                  Leia mais
                </Link>
              </div>
            ))}
        </div>

        {posts?.data && <Pagination pagination={posts.pagination} />}
      </section>
    </>
  );
};

export default Blog;
