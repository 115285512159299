import { IoShareSocialOutline } from "react-icons/io5";
import { TfiMenuAlt } from "react-icons/tfi";
import { LuPhone } from "react-icons/lu";
import { IoChatboxEllipsesOutline } from "react-icons/io5";
import { useState } from "react";
import Contacts from "./navbar/Contacts";
import Menu from "./navbar/Menu";
import Socials from "./navbar/Socials";

const Navbar = () => {

    const [contactsToggle, setContactsToggle] = useState(false);
    const [menuToggle, setMenuToggle] = useState(false);
    const [socialToggle, setSocialToggle] = useState(false);

    function openContacts(){
        setContactsToggle(!contactsToggle);
    }

    function openMenu(){
        setMenuToggle(!menuToggle);
    }

    function openSocial(){
        setSocialToggle(!socialToggle);
    }

    return (
        <>
        <section id="toolbarFixed">
            <div className="openContacts" onClick={openContacts}>
                <LuPhone />
                <IoChatboxEllipsesOutline />
                <p>Contatos</p>
            </div>
            <div className="openMenu" onClick={openMenu}>
                <TfiMenuAlt />
            </div>
            <div className="openSocial" onClick={openSocial}>
                <IoShareSocialOutline />
                <p>Sociais</p>
            </div>
        </section>

        <nav className={`menuSidebar openLeft ${(contactsToggle) ? 'openSidebar' : ''}`}>
            <Contacts openContacts={openContacts}/>
        </nav>

        <nav className={`menuSidebar openBottom ${(menuToggle) ? 'openSidebar' : ''}`}>
            <Menu openMenu={openMenu}/>
        </nav>

        <nav className={`menuSidebar openRight ${(socialToggle) ? 'openSidebar' : ''}`}>
            <Socials openSocial={openSocial}/>
        </nav>
        </>
    );
}

export default Navbar;