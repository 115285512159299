import { ServicesInterface } from "../../../../interfaces/ServicesInterface";
import Breadcrumbs from "../../../../layouts/Breadcrumbs";
import ServiceBox from "../../../../layouts/ServiceBox";
import servico01 from '../../../../../assets/images/servicos/servico-midias-sociais.webp';
import servico02 from '../../../../../assets/images/servicos/servico-automacao-de-marketing.webp';
import servico03 from '../../../../../assets/images/servicos/servico-SEO–otimizacao.webp';
import servico04 from '../../../../../assets/images/servicos/servico-anuncios-patrocinados.webp';
import servico05 from '../../../../../assets/images/servicos/servico-criacao-de-sites.webp';
import { useEffect } from "react";



const MarketingDigital = () => {

    const services: ServicesInterface[] = [
        {
            title: 'Mídias sociais',
            text: 'São diversas soluções da Agência para Mídias Sociais, cada estratégia é criada de acordo com as metas, mercado e planos de cada cliente. Acompanhe este artigo para entender melhor a atuação da agência Boa Imagem nas redes sociais.',
            image: servico01,
            link: '/servicos/online/marketing-digital/midias-sociais',
        },{
            title: 'Automação de marketing',
            text: 'E-mail marketing é uma das poderosa ferramenta de comunicação que a Boa Imagem oferece para você alcançar seu público de maneira segmentada.',
            image: servico02,
            link: '/servicos/online/marketing-digital/automacao-de-marketing',
        },{
            title: 'SEO otimização',
            text: 'Somos uma empresa especializada na criação de sites responsivos, lojas virtuais, envio de email marketing, envio de sms marketing, corporativo e eleitoral.',
            image: servico03,
            link: '/servicos/online/marketing-digital/seo-otimizacao',
        },{
            title: 'Anúncios patrocinados',
            text: 'Ofereça produtos e/ou serviços para quem busca! Este serviço geralmente é pago por cliques nos mecanismos de pesquisa e redes sociais como ferramenta de publicidade online.',
            image: servico04,
            link: '/servicos/online/marketing-digital/anuncios-patrocinados',
        },{
            title: 'Criação de sites',
            text: 'Somos uma empresa especializada na criação de sites responsivos, lojas virtuais, envio de email marketing, envio de sms marketing, corporativo e eleitoral.',
            image: servico05,
            link: '/servicos/online/marketing-digital/criacao-de-sites',
        },
    ];

    useEffect(() => {
        document.title = "Marketing Digital em Brasília  | Agencia Boa Imagem";
        const metaDescription = document.querySelector('meta[name="description"]');
        if (metaDescription) {
          metaDescription.setAttribute(
            "content",
            "Ações de comunicação utilizadas por meios digitais, para divulgar e comercializar produtos e serviços, conquistando novos clientes e melhorando a sua rede de relacionamentos."
          );
        }
    }, []);

    const breadcrumbsItens = [
        {
            name: 'Serviços',
            alias: '/servicos'
        },{
            name: 'Online',
            alias: '/online'
        },{
            name: 'Marketing digital',
            alias: '/marketing-digital'
        }
    ];

    return (
        <>
            <Breadcrumbs title={'Marketing digital 🤔'} items={breadcrumbsItens}/>
            <section id="servicesPage">
                <div className='relative bg_secundary section_padding_clip'>
                    <div className='container padding'>

                        <p>O termo Marketing <b className="text-danger">Digital</b> não é correto, porém se tornou necessário para falar desta modalidade de serviços (assim como Bombril ou Gilette)</p>

                        <p>O objetivo é de disseminar informações com o intuito de influenciar a transação entre comprador e vendedor. Porém a Web vem trazendo vantagens de permitir a interação do consumidor com a peça publicitária, diferentemente de outras mídias.</p>

                        <p>Esta interação é possível já que com um clique o consumidor pode ter acesso a informações detalhadas do produto de seu interesse, comparar preços e efetuar sua compra, tudo no ambiente da Internet, para todas as soluções da web o <b>Marketing</b> torna-se necessário.</p>

                        <p>Na Web, todos os elementos do suporte interativo, podem ser transformados em publicidade, desde o produto e serviço, até a elementos que compõem a interface do computador, como papéis de parede com o logotipo da empresa ou imagem da marca.</p>

                        <p>O Livro <b className="text-danger">Marketing Digital Não Existe</b>, escrito pelo Diretor da agência Paulo Vitor Nascimento, explica melhor o conceito de Marketing e os motivos para não existir uma versao digital</p>
                    </div>

                    </div>
            </section>

            <section id="servicesInsidePageOthers"  className='box_divider relative bg_secundary section_padding_clip text_center'>
                <div className='container'>
                    <ServiceBox services={services} />
                </div>
            </section>
        </>
    );
}

export default MarketingDigital;