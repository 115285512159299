import { useEffect } from "react";
import Breadcrumbs from "../../../layouts/Breadcrumbs";

const Planejamento = () => {

    useEffect(() => {
        document.title = "Planejamento estratégico e Plano de Negócios baseado em Marketing";
        const metaDescription = document.querySelector('meta[name="description"]');
        if (metaDescription) {
          metaDescription.setAttribute(
            "content",
            "Trabalhamos identificando todas as necessidades do seu negócio. Assim, podemos gerar um bom planejamento e maximizar os seus resultados."
          );
        }
    }, []);

    const breadcrumbsItens = [
        {
            name: 'Serviços',
            alias: '/servicos'
        },{
            name: 'Estratégias',
            alias: '/estrategias'
        },{
            name: 'Planejamento',
            alias: '/planejamento'
        }
    ];

    return (
        <>
            <Breadcrumbs title={'Planejamento'} items={breadcrumbsItens}/>
            <section id="servicesInsidePage">
                <div className='relative bg_secundary section_padding_clip'>
                    <div className='container'>
                        
                        <p>O planejamento de mídia é um processo estratégico essencial, destinado a selecionar os melhores meios e veículos para transmitir mensagens publicitárias ao público-alvo de maneira eficaz. Este planejamento envolve a análise detalhada do mercado, o entendimento profundo do comportamento do consumidor e a definição clara dos objetivos da campanha publicitária.</p>

                        <h2 className="title_divider mb-30"><strong>Objetivos do Planejamento de Mídia</strong></h2>
                        <ul className="list">
                            <li>Alcançar o público-alvo: Identificar e selecionar os canais de comunicação que mais se alinham com os hábitos e preferências do público-alvo.</li>
                            <li>Maximizar o retorno sobre o investimento: Otimizar a alocação do orçamento publicitário para garantir o melhor retorno possível.</li>
                            <li>Gerar consciência de marca: Aumentar a visibilidade da marca e fortalecer a sua presença no mercado.</li>
                            <li>Apoiar os objetivos de negócio: Contribuir diretamente para as metas de vendas e crescimento da empresa.</li>
                        </ul>

                        <p>Um bom planejamento minimiza riscos e maximiza o impacto da comunicação, garantindo que a mensagem certa chegue às pessoas certas no momento certo.</p>

                        <p>A Boa Imagem pode ajudar seu negócio a desenvolver um planejamento de mídia personalizado. Entre em contato com nossos especialistas!</p>

                    </div>
                </div>
            </section>
        </>
    );
}

export default Planejamento;