import { ServicesInterface } from "../../../interfaces/ServicesInterface";
import Breadcrumbs from "../../../layouts/Breadcrumbs";
import ServiceBox from "../../../layouts/ServiceBox";
import servico01 from "../../../../assets/images/servicos/servico-design-de-produto.webp";
import servico02 from "../../../../assets/images/servicos/servico-fotografia-publicitaria.webp";
import servico03 from "../../../../assets/images/servicos/servico-videos-e-animacoes.webp";
import servico04 from "../../../../assets/images/servicos/servico-midia-impressa.webp";
import servico05 from "../../../../assets/images/servicos/servico-paineis-OOH.webp";
import servico06 from "../../../../assets/images/servicos/servico-placas-e-fachadas.webp";
import servico07 from "../../../../assets/images/servicos/servico-projetos-graficos.webp";
import servico08 from "../../../../assets/images/servicos/servico-projetos-de-totem.webp";
import servico09 from "../../../../assets/images/servicos/servico-projeto-de-display.webp";
import servico10 from "../../../../assets/images/servicos/servico-propaganda-em-radios.webp";
import servico11 from "../../../../assets/images/servicos/servico-propaganda-em-revistas.webp";
import servico12 from "../../../../assets/images/servicos/servico-propaganda-de-televisao.webp";
import servico13 from "../../../../assets/images/servicos/servico-sinalizacao-interna.webp";
import servico14 from "../../../../assets/images/servicos/servico-ilustracoes-2d-e-3d.webp";
import servico15 from "../../../../assets/images/servicos/servico-outras-midias.webp";
import servico16 from "../../../../assets/images/servicos/servico-midias-e-veiculos.webp";
import servico17 from "../../../../assets/images/servicos/servico-eletronico-digital.webp";

import { useEffect } from "react";

const Offline = () => {
  useEffect(() => {
    document.title = "Serviços Offline | Agência Boa Imagem";
    const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
      metaDescription.setAttribute(
        "content",
        "O marketing offline são estratégias de mídias tradicionais em Brasília, como Panfletos, Cartões de Visita, Banners, Placas, Fachadas, Projetos Gráficos, Display, Totem, Sinalização Interna, Propanda de Revista e Televisão."
      );
    }
  }, []);

  const breadcrumbsItens = [
    {
      name: "Serviços",
      alias: "/servicos",
    },
    {
      name: "Offline",
      alias: "/offline",
    },
  ];

  const services: ServicesInterface[] = [
    {
      title: "Design de produto",
      text: "Estamos preparados para desenvolver a criação do design de produto, peças publicitárias e outros modelos que podem fazer parte da necessidade da sua empresa.",
      image: servico01,
      link: "/servicos/offline/design",
    },
    {
      title: "Fotografia publicitária",
      text: "A fotografia publicitária é um serviço diferenciado, não é apenas uma foto. Com uma boa composição e com um projeto bem elaborado, o resultado com certeza irá satisfazer o cliente.",
      image: servico02,
      link: "/servicos/offline/fotografia-publicitaria",
    },
    {
      title: "Vídeos e animações",
      text: "Produzimos vídeos comerciais e publicitários, além de atendermos demanda para novos espaços como youtube e novas mídias. No modelo de animação 2D e 3D a criação do conteúdo pode ser feito de maneira completa.",
      image: servico03,
      link: "/servicos/offline/videos",
    },
    {
      title: "Mídia impressa",
      text: "A Agência Boa Imagem atende vários segmentos, desenvolvendo serviços de acordo com a necessidade do cliente, o desenvolvimento de produtos para a Mídia Impressa é feito de forma offline.",
      image: servico04,
      link: "/servicos/offline/midia-e-veiculo/impressa",
    },
    {
      title: "Mídias e veículos",
      text: "Existem vários meios e veículos de comunicação. Porém, nem sempre sabemos diferenciar suas características e atuações. Podemos dizer que a mídia é o meio que intermediará a mensagem, ou seja ela que transmitirá a mensagem.",
      image: servico16,
      link: "/servicos/offline/midia-e-veiculo",
    },
    {
      title: "Painéis OOH",
      text: "Ela corresponde a qualquer tipo de mídia que nos impacta a partir do momento em que colocamos os pés fora de casa, por isso o termo Out of Home.",
      image: servico05,
      link: "/servicos/offline/midia-e-veiculo/out-of-home",
    },
    {
      title: "Placas e fachadas",
      text: "Investir em placas ou em uma fachada nova pode mudar a percepção do cliente a respeito da sua marca. Uma imagem bem construída, representa mais que beleza visual, está atrelada a características positivas como: organização, profissionalismo e seriedade.",
      image: servico06,
      link: "/servicos/offline/placas-e-fachadas",
    },
    {
      title: "Projetos gráficos",
      text: "Existem diversas possibilidades de materiais gráficos para divulgar sua empresa, tais como: folder, flyer, anúncio de revista, cartaz, cartão de visita, papelaria corporativa, entre outros.",
      image: servico07,
      link: "/servicos/offline/projetos-graficos",
    },
    {
      title: "Projetos de totem",
      text: "Podemos comparar um totem publicitário simples com um outdoor, ambos têm como objetivo atingir camadas sociais. Porém o totem de acordo com a lei e fiscalização estabelecida pela Agefis não pode ser usado em ambientes públicos.",
      image: servico08,
      link: "/servicos/offline/totem-publicitario",
    },
    {
      title: "Projeto de display",
      text: "As formas de publicidade por meio da estrutura de display são inúmeras. Esse tipo de peça publicitária tem como objetivo fazer o consumidor realizar a compra do seu produto.",
      image: servico09,
      link: "/servicos/offline/display",
    },
    {
      title: "Propaganda em rádios",
      text: "Esse veículo de divulgação é muito procurado pelos anunciantes pois pode ele oferece um baixo custo. Ou seja, para produzir e vincular o valor é mais em conta.",
      image: servico10,
      link: "/servicos/offline/propaganda-de-radio",
    },
    {
      title: "Propaganda em revistas",
      text: "A propaganda de revista possibilita o melhor tempo de leitura. Quando determinada pessoa está lendo uma revista, a atenção está voltada para as páginas, assim as chances de interferência nos anúncios é mínimo.",
      image: servico11,
      link: "/servicos/offline/propaganda-de-revista",
    },
    {
      title: "Propaganda de televisão",
      text: "As telinhas impactam milhões de brasileiros todos os dias, a mídia televisiva evidentemente influencia a formação de opinião, apresentando aspectos de modernidade que causam transformações no sistema econômico.",
      image: servico12,
      link: "/servicos/offline/propaganda-de-televisao",
    },
    {
      title: "Projeto de sinalização interna",
      text: "A identidade visual de uma empresa vai além dos folders, das redes sociais e até mesmo de outdoors. Estamos falando sobre a sinalização interna, elemento essencial para guiar e tornar a experiência do seu cliente agradável e positiva no ponto de venda.",
      image: servico13,
      link: "/servicos/offline/sinalizacao-interna",
    },
    {
      title: "Ilustrações 2D e 3D",
      text: "Atuamos na área da comunicação responsável pela produção, gerenciamento e coordenação de informações. Acompanhamos a divulgação da imagem do cliente junto à mídia e ao público-alvo.",
      image: servico14,
      link: "/servicos/offline/ilustracoes-2d-e-3d",
    },
    {
      title: "Outras mídias",
      text: "A agência Boa Imagem possui uma grande variedade de serviços, porém possuímos outras mídias que também podem agregar no seu negócio. Todos os nossos projetos são pensados de forma completa para atender todas as necessidades dos clientes.",
      image: servico15,
      link: "/servicos/offline/midia-e-veiculo/outras",
    },
    {
      title: "Mídias Eletrônica/Digital",
      text: "A mídia digital se tornou algo revolucionário, a cada dia existem mais adeptos e consumidores virtuais. Essa ferramenta pode ser explorada por meio de redes sociais, e-mails, sites, telas de elevadores, banners eletrônicos e outros.",
      image: servico17,
      link: "/servicos/offline/midia-e-veiculo/eletronica-digital",
    },
  ];

  return (
    <>
      <Breadcrumbs title={"Serviços Offline"} items={breadcrumbsItens} />
      <section
        id="servicesPage"
        className="box_divider relative bg_secundary section_padding_clip text_center"
      >
        <div className="container">
          <ServiceBox services={services} />
        </div>
      </section>
    </>
  );
};

export default Offline;
