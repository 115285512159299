import Breadcrumbs from "../../../layouts/Breadcrumbs";
import image01 from '../../../../assets/images/servicos/peca-grafica-folder-sanfona-2.webp';

import image02 from '../../../../assets/images/servicos/peca-grafica-folder-sanfona.webp';
import image03 from '../../../../assets/images/servicos/peca-grafica-flyer.webp';
import image04 from '../../../../assets/images/servicos/peca-grafica-flyer-2.webp';
import image05 from '../../../../assets/images/servicos/peca-grafica-ebook.webp';
import image06 from '../../../../assets/images/servicos/peca-grafica-ebook-2.webp';
import image07 from '../../../../assets/images/servicos/peca-grafica-ebook-3.webp';
import { useEffect } from "react";

const ProjetosGraficos = () => {

    useEffect(() => {
        document.title = "Projetos Gráficos | Agência Boa Imagem";
        const metaDescription = document.querySelector('meta[name="description"]');
        if (metaDescription) {
          metaDescription.setAttribute(
            "content",
            "O objetivo de projetos gráficos é diferenciar determinado produto/serviço através de um padrão agradável e claro ao público que se deseja atingir."
          );
        }
    }, []);

    const breadcrumbsItens = [
        {
            name: 'Serviços',
            alias: '/servicos'
        },{
            name: 'Offline',
            alias: '/offline'
        },{
            name: 'Projetos Gráficos',
            alias: '/projetos-graficos'
        }
    ];

    return (
        <>
            <Breadcrumbs title={'Projetos Gráficos'} items={breadcrumbsItens}/>
            <section id="servicesPage">
                <div className='relative bg_secundary section_padding_clip'>
                    <div className='container'>

                        <p>O sucesso de qualquer empresa depende da forma e frequência como é divulgada para o público-alvo. O segredo do sucesso do seu negócio depende da eficiência da comunicação, não apenas para atrair clientes, mas para fidelizá-los, aumentando assim a credibilidade da marca e o ticket médio de compra. Para isso, o investimento em projetos gráficos é essencial.</p>

                        <h2>PROJETOS GRÁFICOS – O QUE SÃO E PARA QUE SERVEM?</h2>

                        <p>Existem diversas possibilidades de materiais gráficos para divulgar sua empresa, tais como: folder, flyer, anúncio de revista, cartaz, cartão de visita, papelaria corporativa, entre outros.</p>

                        <p>O objetivo de um projeto gráfico é diferenciar determinado produto/serviço através de um padrão agradável e claro ao público que se deseja atingir. O que parece ser um simples projeto, torna-se uma ferramenta de vendas eficaz quando feita por profissionais qualificados.</p>

                        <p>Dois aspectos são fundamentais para projetos gráficos de qualidade:</p>

                        <ul className="list">
                            <li><b>Visual</b> – Design, tipografia, cores;</li>
                            <li><b>Editorial</b> – Textos, conteúdo, linguagem.</li>
                        </ul>


                        <p>Incluem-se aqui características que fazem toda a diferença para que a mensagem seja passada de forma clara e eficiente, seja num material institucional, promocional, informativo. São elas:

                        </p>
                        
                        <ul className="list">
                            <li><b>Formato de página</b> – No caso de jornais, revistas, cartilhas, etc;</li>
                            <li><b>Tipo de papel</b> – Jornal, couchê, etc;</li>
                            <li><b>Tipografia</b> – Tipo de fonte a ser utilizada, com serifa, sem serifa, humanista, etc;</li>
                            <li><b>Marca do cliente</b> – Logotipo;</li>
                            <li><b>Cores</b> – Escolha da melhor paleta de cores para o projeto.</li>
                        </ul>

                        <h2>COMUNIQUE-SE DA FORMA CERTA COM SEU CLIENTE!</h2>

                        <p>O ponto chave da questão está em quem você escolhe para cuidar da imagem do seu negócio. É comum pensar que apenas uma peça gráfica não fará diferença para a reputação da sua empresa, mas basta olhar para grandes nomes como Coca-Cola, Nike e tantas outras marcas de sucesso, para perceber que cada detalhe deve ser pensado, para que o conjunto da obra possa refletir a identidade visual da marca.</p>

                        <p>Escolha quem sabe fazer!<br/>
                        Atuamos desde 2009 no mercado de Brasília. Nosso método 360° une estratégia e execução, tornando o projeto gráfico algo pensado com foco em quem realmente importa: o seu futuro cliente.</p>

                        <p>Nosso processo envolve as seguintes etapas:</p>

                        <ul className="list">
                            <li><b>Briefing</b> – Reunião e coleta de informações;</li>
                            <li><b>Conceito</b> – Tom e ideia da peça gráfica;</li>
                            <li><b>Design</b> – Apresentação do layout pensado;</li>
                            <li><b>Execução</b> – Produção do layout para aprovação;</li>
                            <li><b>Ajustes</b> – Detalhes e alterações solicitadas;</li>
                            <li><b>Finalização</b> – Entrega do projeto de peça gráfica.</li>
                        </ul>

                        <h3>CASE – MXK KUSTOM</h3>

                        <p>A marca que vende capacetes e acessórios para motos, tem como identidade o estilo biker de ser, de motoqueiros e motociclistas, dessa forma, adotou-se o estilo rústico e com tons escuros para compor os projetos gráficos do cliente. Como pode-se observar, mantém-se a identidade visual, levando ao rápido reconhecimento da marca, em qualquer peça a que o leitor seja exposto.</p>

                        <p>Nossos projetos são focados no cliente final e também no conhecimento da cliente (representante da empresa) a respeito do público que busca para o seu negócio.</p>

                        <img src={image01} alt="Foto: Serviços de Peça gráfica - Folder Sanfona" title="Foto: Serviços de Peça gráfica - Folder Sanfona" width="1002" height="250"/>

                        <p className="text_center"><small>Peça gráfica: Folder Sanfona</small></p>
                        <img src={image02} alt="Foto: Serviços de Peça gráfica - Folder Sanfona" title="Foto: Serviços de Peça gráfica - Folder Sanfona" width="1001" height="249"/>
                        <img src={image03} alt="Foto: Serviços de Peça gráfica - Flyer" title="Foto: Serviços de Peça gráfica - Flyer" width="637" height="887"/>
                        <img src={image04} alt="Foto: Serviços de Peça gráfica - Flyer" title="Foto: Serviços de Peça gráfica - Flyer" width="639" height="905"/>
                        <p className="text_center"><small>Peça gráfica: Flyer</small></p>

                        <img src={image05} alt="Foto: Serviços de Peça gráfica - E-Book" title="Foto: Serviços de Peça gráfica - E-Book" width="799" height="567"/>
                        <img src={image06} alt="Foto: Serviços de Peça gráfica - E-Book" title="Foto: Serviços de Peça gráfica - E-Book" width="796" height="564"/>
                        <img src={image07} alt="Foto: Serviços de Peça gráfica - E-Book" title="Foto: Serviços de Peça gráfica - E-Book" width="960" height="679"/>
                        <p className="text_center"><small>Peça gráfica: E-Book</small></p>

                        <h2>GARANTIA DE QUALIDADE</h2>

                        <blockquote>
                        “Existem diversas empresas no mercado que prometem entregar peças gráficas de qualidade, mas nem todas estão preocupadas com o essencial: as suas vendas.”
                        </blockquote>

                        <p>Conte com uma agência que atua a anos em Brasília e conhece bem os melhores canais e recursos gráficos para fazer o seu negócio decolar. Com a Boa Imagem sua empresa só tem a ganhar!</p>

                        <h2>NOSSOS SERVIÇOS GRÁFICOS:</h2>

                        <p>Nossa equipe está preparada para criar e diagramar diferentes tipos de peças gráficas, conheça algumas:</p>

                        <ul className="list">
                            <li>Cartaz</li>
                            <li>Folder</li>
                            <li>Flyer</li>
                            <li>Cartilha</li>
                            <li>Revista</li>
                            <li>Cartão de visita</li>
                            <li>Banner</li>
                            <li>Cardápio</li>
                            <li>Envelope</li>
                            <li>Calendário</li>
                            <li>Adesivos</li>
                            <li>Embalagem</li>
                            <li>Encarte</li>
                            <li>Catálogo</li>
                            <li>Papel timbrado</li>
                            <li>Placas e fachadas</li>
                            <li>Lonas</li>
                            <li>Panfletos</li>
                            <li>Luminosos</li>
                            <li>Ímã de geladeira</li>
                        </ul>

                        <h5>ESQUEÇA AS DÚVIDAS, TEMOS SOLUÇÕES.<br/>
                        SOLICITE JÁ UM ORÇAMENTO!</h5>


                    </div>
                </div>
            </section>
        </>
    );
}

export default ProjetosGraficos;