import Breadcrumbs from "../../layouts/Breadcrumbs";
import online from '../../../assets/images/servicos/servico-online.webp';
import offline from '../../../assets/images/servicos/servico-offline.webp';
import estrategias from '../../../assets/images/servicos/servico-estrategias.webp';
import ServiceBox from "../../layouts/ServiceBox";
import { ServicesInterface } from "../../interfaces/ServicesInterface";
import { useEffect } from "react";


const Servicos = () => {

    useEffect(() => {
        document.title = "Serviços em Marketing e Publicidade em Brasília";
        const metaDescription = document.querySelector('meta[name="description"]');
        if (metaDescription) {
          metaDescription.setAttribute(
            "content",
            "Serviços de Propaganda, Publicidade, Estratégias de Marketing e Serviços Offline: Planejamento, Pesquisa, Vendas Online, Endomarketing, Mídia Impressa, acesse e veja mais"
          );
        }
    }, []);

    const breadcrumbsItens = [{
        name: 'Serviços',
        alias: '/servicos'
    }];

    const services: ServicesInterface[] = [
        {
            title: 'Estratégias',
            text: 'A Agência Boa Imagem realiza assessoria em várias áreas. Nossos profissionais possuem amplo conhecimento em comunicação organizacional e empresarial.',
            image: estrategias,
            link: '/servicos/estrategias',
        },        
        {
            title: 'Online',
            text: 'Hoje em dia é fundamental que as empresas possuam uma boa visibilidade no mundo virtual, principalmente empresas que fazem a consultoria de vendas online.',
            image: online,
            link: '/servicos/online',
        },        
        {
            title: 'Offline',
            text: 'Independente do seu segmento, possuir uma boa estratégia de marketing faz toda a diferença na hora de atingir o sucesso e a visibilidade.',
            image: offline,
            link: '/servicos/offline',
        }
    ];

    return (
        <>
            <Breadcrumbs title={'Serviços'} items={breadcrumbsItens}/>
            <section id="servicesPage" className='box_divider relative bg_secundary section_padding_clip text_center'>
                <div className='container'>
                    <ServiceBox services={services} />
                </div>
            </section>
        </>
    );
}

export default Servicos;
