import { ServicesInterface } from "../../../interfaces/ServicesInterface";
import Breadcrumbs from "../../../layouts/Breadcrumbs";
import ServiceBox from "../../../layouts/ServiceBox";
import image from '../../../../assets/images/servicos/servico-assessoria.webp';
import service01 from '../../../../assets/images/servicos/servico-design-de-produto.webp';
import service02 from '../../../../assets/images/servicos/servico-fotografia-publicitaria.webp';
import service03 from '../../../../assets/images/servicos/servico-videos-e-animacoes.webp';
import service04 from '../../../../assets/images/servicos/servico-propaganda-de-televisao.webp';
import service05 from '../../../../assets/images/servicos/servico-propaganda-em-radios.webp';
import service06 from '../../../../assets/images/servicos/servico-propaganda-em-revistas.webp';
import { Link } from "react-router-dom";
import { useEffect } from "react";

const Assessorias = () => {

    useEffect(() => {
        document.title = "Assessoria Publicitária Completa | Serviço Profissional";
        const metaDescription = document.querySelector('meta[name="description"]');
        if (metaDescription) {
          metaDescription.setAttribute(
            "content",
            "Alcançar bons resultados vai além de produzir boas propagandas e serviços. É preciso assessoria de qualidade e se comunicar corretamento com o mercado."
          );
        }
    }, []);

    const breadcrumbsItens = [
        {
            name: 'Serviços',
            alias: '/servicos'
        },{
            name: 'Estratégias',
            alias: '/estrategias'
        },{
            name: 'Assessorias',
            alias: '/assessorias'
        }
    ];

    const services: ServicesInterface[] = [
        {
            title: 'Design de produto',
            text: 'Estamos preparados para desenvolver a criação do design de produto, peças publicitárias e outros modelos que podem fazer parte da necessidade da sua empresa.',
            image: service01,
            link: '/servicos/offline/design',
        },{
            title: 'Fotografia publicitária',
            text: 'A fotografia publicitária é um serviço diferenciado, não é apenas uma foto. Com uma boa composição e com um projeto bem elaborado, o resultado com certeza irá satisfazer o cliente.',
            image: service02,
            link: '/servicos/offline/fotografia-publicitaria',
        },{
            title: 'Vídeos e animações',
            text: 'Produzimos vídeos comerciais e publicitários, além de atendermos demanda para novos espaços como youtube e novas mídias. No modelo de animação 2D e 3D a criação do conteúdo pode ser feito de maneira completa.',
            image: service03,
            link: '/servicos/offline/videos',
        },{
            title: 'Propaganda de televisão',
            text: 'As telinhas impactam milhões de brasileiros todos os dias, a mídia televisiva influencia a formação de opinião, apresentando aspectos de modernidade que causam transformações no sistema econômico.',
            image: service04,
            link: '/servicos/offline/propaganda-de-televisao',
        },{
            title: 'Propaganda em rádios',
            text: 'As rádios tem o seu público cativo, um veículo de divulgação muito procurado pelos anunciantes pois pode ele oferece um baixo custo. Ou seja, para produzir e vincular o valor é mais em conta.',
            image: service05,
            link: '/servicos/offline/propaganda-de-radio',
        },{
            title: 'Propaganda em revistas',
            text: 'A propaganda de revista possibilita o melhor tempo de leitura. Quando determinada pessoa está lendo uma revista, a atenção está voltada para as páginas, assim as chances de interferência nos anúncios é mínimo.',
            image: service06,
            link: '/servicos/offline/propaganda-de-revista',
        }
    ];

    return (
        <>
            <Breadcrumbs title={'Assessorias relacionadas às estratégias de comunicação da Agência Boa Imagem'} items={breadcrumbsItens}/>
            <section id="servicesInsidePage">
                <div className='relative bg_secundary section_padding_clip'>
                    <div className='container'>
                        <p>A Agência Boa Imagem está aqui para te ajudar na assessoria em várias áreas! Nossa equipe de empresas parceiras possui profissionais com amplo conhecimento em comunicação organizacional e empresarial.</p>

                        <img src={image} width='1024' height='521' alt="Assessorias" />

                        <h3 className="mt-30">Assessoria de imprensa</h3>
                        <p>Atuamos em conjunto com profissionais especializados na área da comunicação, responsáveis pela produção, gerenciamento e coordenação de informações juntamente aos veículos de comunicação.</p>

                        <p>Acompanhamos a divulgação da imagem do cliente junto à mídia e ao público-alvo, analisando e utilizando as vitórias da Assessoria de Imprensa para ampliar o conhecimento do público interno a respeito de ações relevantes da marca.</p>

                        <p>A Assessoria de Imprensa atua principalmente na promoção e no lançamento de produtos e serviços em forma de notícias, gerando pautas exclusivas para empresas em diferentes canais de comunicação.</p>

                        <h3>Planejamento estratégico</h3>
                        <p>Para desenvolver o plano estratégico, desenvolvemos análises qualitativas.</p>

                        <p>Desta maneira formulamos ou reformulamos a comunicação dentro do segmento em que sua empresa está inserida.</p>

                        <h3>Assessoria interna</h3>
                        <p>Sabemos a importância de manter um bom ambiente de trabalho, por isso buscamos desenvolver a resolução de conflitos, proporcionando assim um espaço agradável.</p>

                        <p>Também avaliamos o plano estratégico de comunicação empresarial para desenvolver melhorias, valores e planos bem delineados para que a sua empresa cresça no mercado.</p>

                        <h3>Gestão de redes sociais</h3>
                        <p>Atualmente é indispensável uma forte presença online. Pensando assim, oferecemos uma gestão eficaz de mídias sociais. Nossa assessoria nesse segmento possui um bom planejamento e metas claras para obter os melhores resultados e aumentar a visibilidade da sua marca.</p>

                        <h3>Curadoria de conteúdo</h3>
                        <p>Na assessoria de curadoria de conteúdo, buscamos fazer a seleção de conteúdos relevantes que podem gerar uma boa interação com o público-alvo.</p>

                        <h3>Métricas e relatórios</h3>
                        <p>Esse modelo de assessoria proporciona um acompanhamento que mede os resultados do investimento, com produção de relatórios dentro dos principais formatos e de acordo com a necessidade do cliente.</p>
                        
                        <h3>Oferecemos pacotes de serviços recorrentes para o seu negócio</h3>
                        <h3 className="text_center">Clique no botão abaixo para receber atendimento:</h3>
                        <p><Link className="btn" to={'/contato'}>Fale Conosco!</Link></p>

                        <h2 className="title_divider">Entenda outras áreas de atuação da agência:</h2>
                    </div>
                </div>
            </section>

            <section id="servicesInsidePageOthers"  className='box_divider relative bg_secundary section_padding_clip text_center'>
                <div className='container'>
                    <ServiceBox services={services} />
                </div>
            </section>
        </>
    );
}

export default Assessorias;