import { IoClose } from "react-icons/io5";
import { MdFacebook } from "react-icons/md";
import { RiInstagramFill } from "react-icons/ri";
import { FaSquareXTwitter } from "react-icons/fa6";
import { FaYoutube, FaLinkedin } from "react-icons/fa";

type SocialProps = {
    openSocial: () => void;
}

const Socials: React.FC<SocialProps> = ({openSocial}) => {
    return (
        <>
        <div className="closeMenu" onClick={openSocial}>
                <IoClose />
            </div>                
            <ul>
                <li>
                    <a target="_blank" rel="nofollow noopener" title="Ver Boa Imagem no Facebook" href="https://www.facebook.com/boaimagem">
                        <MdFacebook />
                        Facebook
                    </a>
                </li>
                <li>
                    <a target="_blank" rel="nofollow noopener" title="Ver Boa Imagem no Instagram" href="https://www.instagram.com/boa_imagem">
                        <RiInstagramFill />
                        Instagram
                    </a>
                </li>
                <li>
                    <a target="_blank" rel="nofollow noopener" title="Ver Boa Imagem no Twitter" href="https://twitter.com/BoaImagem">
                        <FaSquareXTwitter />
                        Twitter
                    </a>
                </li>
                <li>
                    <a target="_blank" rel="nofollow noopener" title="Ver Boa Imagem no Youtube" href="https://www.youtube.com/user/Boalmagem">
                        <FaYoutube />
                        Youtube
                    </a>
                </li>                
                <li>
                    <a target="_blank" rel="nofollow noopener" title="Ver Boa Imagem no Linkedin" href="https://www.linkedin.com/company/boa-imagem">
                        <FaLinkedin />
                        Linkedin
                    </a>
                </li>
            </ul>
         </>
    );
}

export default Socials;