import Breadcrumbs from "../../../layouts/Breadcrumbs";
import image01 from '../../../../assets/images/servicos/servico-sinalizacao-1.webp';
import image02 from '../../../../assets/images/servicos/servico-sinalizacao-2.webp';
import image03 from '../../../../assets/images/servicos/servico-sinalizacao-3.webp';
import image04 from '../../../../assets/images/servicos/servico-sinalizacao-4.webp';
import image05 from '../../../../assets/images/servicos/servico-sinalizacao-5.webp';
import { useEffect } from "react";


const SinalizacaoInterna = () => {

    useEffect(() => {
        document.title = "Sinalização Interna de empresas e edifícios | Agência Boa Imagem";
        const metaDescription = document.querySelector('meta[name="description"]');
        if (metaDescription) {
          metaDescription.setAttribute(
            "content",
            "Os pequenos detalhes fazem toda a diferença, por isso, seja para informar onde ficam os banheiros ou para situar o clientes a respeito da sua localização no caso de grandes empresas, a sinalização interna é essencial!"
          );
        }
    }, []);

    const breadcrumbsItens = [
        {
            name: 'Serviços',
            alias: '/servicos'
        },{
            name: 'Offline',
            alias: '/offline'
        },{
            name: 'Projeto de sinalização interna',
            alias: '/sinalizacao-interna'
        }
    ];

    return (
        <>
            <Breadcrumbs title={'Projeto de sinalização interna'} items={breadcrumbsItens}/>
            <section id="servicesPage">
                <div className='relative bg_secundary section_padding_clip'>
                    <div className='container'>

                        <p>A identidade visual de uma empresa vai além dos folders, das redes sociais e até mesmo de outdoors. Estamos falando sobre a sinalização interna, elemento essencial para guiar e tornar a experiência do seu cliente agradável e positiva no ponto de venda.</p>

                        <p>É importante ter em mente que o ser humano é em essência, extremamente visual. Isso significa que, na maioria das vezes, a primeira impressão é a que fica. Portanto, deve-se pensar na organização interna, tanto quanto na comunicação para atrair os seus clientes.</p>

                        <p>Uma empresa organizada visualmente transmite seriedade, profissionalismo e tantas outras características importantes para fazer o cliente se apaixonar pela sua marca. Os pequenos detalhes fazem toda a diferença, por isso, seja para informar onde ficam os banheiros ou para situar o clientes a respeito da sua localização no caso de grandes empresas, a sinalização interna é essencial!</p>

                        <h2>Quais os tipos de sinalização interna?</h2>

                        <p>Existem diferentes tipos de sinalização, seja para domicílios ou para empresas. Para a sinalização interna corporativa destacamos 3 tipos:</p>

                        <p><b>Segurança</b> – Sinalização que tem como objetivo alertar. Incluem-se nesta categoria placas como: perigo, extintor, saída de emergência, proibido fumar, entre outras.</p>

                        <img className="text_center mb-30 mt-30" src={image01} alt="Foto: Serviços de Sinalização Interna" title="Foto: Serviços de Sinalização Interna" width="459" height="229"/>

                        <p><b>Arquitetônica</b> – Destinada a informar sobre a localização de salas, banheiros, elevadores, caixas, alas, visando facilitar a locomoção de todos dentro do estabelecimento.</p>

                        <img className="text_center mb-30 mt-30" src={image02} alt="Foto: Serviços de Sinalização Interna" title="Foto: Serviços de Sinalização Interna" width="543" height="248"/>

                        <p><b>Promocional</b> – Tem como objetivo promover a venda, sendo assim, no caso de mercados, são placas e displays posicionados em locais estratégicos e com cores e design que chamem a atenção do consumidor.</p>

                        <img className="text_center mb-30 mt-30" src={image03} alt="Foto: Serviços de Sinalização Interna" title="Foto: Serviços de Sinalização Interna" width="285" height="243"/>

                        <p><b>Para as tradicionais. Para as criativas!</b><br/>
                        Não importa o estilo da sua empresa, tradicional ou moderna, o design pode ajudá-la a mostrar sua essência com a sinalização interna certa. Separamos alguns exemplos de sinalização arquitetônica de inspiração. Confira!</p>

                        <img className="text_center mb-30 mt-30" src={image04} alt="Foto: Serviços de Sinalização Interna" title="Foto: Serviços de Sinalização Interna" width="584" height="749"/>

                        <p><b>Conte com a Boa Imagem!</b><br/>
                        Levamos o nosso nome muito a sério. Por isso, seja qual for o posicionamento da sua empresa, estamos preparados para cuidar da sinalização interna com qualidade e técnica.</p>

                        <p>Estes são alguns exemplos de sinalização realizados pela equipe Boa Imagem para a <a rel="noopener" href="https://escolamultiintegral.com.br" target="_blank" title="EMI – Escola Multi-Integral">EMI – ESCOLA MULTI-INTEGRAL</a>:</p>

                        <img className="text_center mb-30 mt-30" src={image05} alt="Foto: Serviços de Sinalização Interna" title="Foto: Serviços de Sinalização Interna" width="529" height="285"/>

                        <p>Nosso método consiste nas seguintes etapas:</p>

                        <ul className="list">
                        <li>Briefing</li>
                        <li>Conceito</li>
                        <li>Layout</li>
                        <li>Aprovação</li>
                        <li>Finalização</li>
                        </ul>

                    </div>
                </div>
            </section>
        </>
    );
}

export default SinalizacaoInterna;