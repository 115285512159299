import { useEffect } from "react";
import Breadcrumbs from "../../../../layouts/Breadcrumbs";

const OutrasMidias = () => {
  useEffect(() => {
    document.title = "Outras mídias | Agência Boa Imagem";
    const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
      metaDescription.setAttribute(
        "content",
        "Possuímos uma grande variedade de serviços e outras mídias. Nossos projetos são pensados de forma completa para atender as necessidades dos clientes."
      );
    }
  }, []);

  const breadcrumbsItens = [
    {
      name: "Serviços",
      alias: "/servicos",
    },
    {
      name: "Offline",
      alias: "/offline",
    },
    {
      name: "Mídia e veículo",
      alias: "/midia-e-veiculo",
    },
    {
      name: "Outras mídias",
      alias: "/outras",
    },
  ];

  return (
    <>
      <Breadcrumbs title={"Outras mídias"} items={breadcrumbsItens} />
      <section id="servicesPage">
        <div className="relative bg_secundary section_padding_clip">
          <div className="container">
            <h2 className="title_divider mb-30">
              A Boa Imagem é especializada em inovação
            </h2>

            <h3>Outras mídias</h3>

            <p>
              A agência Boa Imagem possui uma grande variedade de serviços,
              porém possuímos outras mídias que também podem agregar no seu
              negócio. Todos os nossos projetos são pensados de forma completa
              para atender todas as necessidades dos clientes. Produzimos desde
              peças publicitárias simples à peças mais complexas. Isso quer
              dizer que se você não identificou as suas necessidades em nosso
              site, isso não significa que a Boa Imagem não faça.
            </p>

            <p>
              Significa que estamos aguardando seu contato para, mais uma vez,
              criar algo inovador. Essa é a nossa especialidade.
            </p>

            <p>
              Além disso, trabalhamos com segmentos pensados para serviços
              específicos. Atendendo mídias online e offline. Sabemos a
              necessidade de conciliar a publicidade e a comunicação no meio
              empresarial. Com um planejamento estratégico avaliamos a melhor
              forma de ajudar a sua empresa. A Boa Imagem oferece ações de
              marketing, além de proporcionar um acompanhamento personalizado de
              acordo com a sua necessidade.
            </p>

            <p>
              Nosso objetivo principal é promover a satisfação de nossos
              clientes e fidelizar o público oferecendo sempre inovação e
              serviços de qualidade.
            </p>
          </div>
        </div>
      </section>
    </>
  );
};

export default OutrasMidias;
