import { useEffect } from "react";
import Breadcrumbs from "../../../layouts/Breadcrumbs";
const Ilustracoes2D3D = () => {

    useEffect(() => {
        document.title = "Ilustrações 2D e 3D | Personagens, cenários e ilustrações técnicas";
        const metaDescription = document.querySelector('meta[name="description"]');
        if (metaDescription) {
          metaDescription.setAttribute(
            "content",
            "O recurso gráfico que está faltando em sua campanha é uma ilustração 3D. Dê vida ao mascote de sua empresa e crie mais engajamento com seu público."
          );
        }
    }, []);

    const breadcrumbsItens = [
        {
            name: 'Serviços',
            alias: '/servicos'
        },{
            name: 'Offline',
            alias: '/offline'
        },{
            name: 'Ilustrações 2D e 3D',
            alias: ''
        }
    ];

    return (
        <>
            <Breadcrumbs title={'Ilustrações 2D e 3D'} items={breadcrumbsItens}/>
            <section id="servicesPage">
                <div className='relative bg_secundary section_padding_clip'>
                    <div className='container'>
                        
                        <h2 className="title_divider mb-30">Ilustrações 2D</h2>

                        <p>As ilustrações 2D continuam a encantar e engajar audiências de todas as idades. Na Boa Imagem, transformamos suas ideias em desenhos vibrantes e envolventes que comunicam sua mensagem de maneira clara e impactante.</p>

                        <p>Esse conteúdo é uma forma poderosa e versátil de expressão visual, capaz de capturar a essência de uma campanha, contar uma história ou transmitir uma mensagem. </p>

                        <h3><strong>Custo-efetividade</strong></h3>
                        <p>Comparado a outros modelos de ilustrações, o 2D pode ser mais econômico, proporcionando um excelente retorno sobre o investimento, especialmente para projetos com orçamentos limitados.</p>

                        <h3><strong>Clareza na comunicação</strong></h3>
                        <p>Já ouviu o termo “entendeu ou vou ter que desenhar”? Muitas vezes é preciso desenhar sim! A comunicação fica muito mais fácil de ser compreendida com ilustrações e elementos gráficos.</p>

                        <h3><strong>Identidade visual única</strong></h3>
                        <p>Ilustrações personalizadas ajudam a criar uma identidade visual distinta que diferencia sua marca da concorrência. Elas podem ser utilizadas em logotipos, embalagens, materiais de marketing e muito mais, tornando sua </p>marca reconhecível e memorável.

                        <p>Invista em projetos que possam diferenciar a sua empresa. Entre em contato com a Boa Imagem e peça um orçamento.</p>

                        <h2 className="title_divider mb-30">Ilustrações 3D</h2>

                        <p>As ilustrações 3D oferecem uma profundidade e um dinamismo que capturam a atenção e encantam o público. Na Boa Imagem, nossa equipe de especialistas em ilustração 3D está pronta para transformar suas visões em realidade, criando artes que destacam sua marca e comunicam sua mensagem com impacto e clareza.</p>

                        <p>Nossas ilustrações 3D oferecem várias vantagens que podem beneficiar sua marca:</p>

                        <h3><strong>Versatilidade e flexibilidade</strong></h3>
                        <p>As ilustrações 3D são altamente versáteis e podem ser adaptadas para uma variedade de usos. Elas permitem uma flexibilidade criativa que pode ser ajustada para atender às suas necessidades específicas.</p>

                        <h3><strong>Engajamento Visual</strong></h3>
                        <p>A profundidade e o dinamismo das ilustrações 3D criam um forte impacto visual, captando a atenção do público e aumentando o engajamento. Elas são ideais para criar conteúdos visuais impressionantes que se destacam em praticamente qualquer plataforma.</p>

                        <h3><strong>Identidade visual única</strong></h3>
                        <p>Ilustrações personalizadas ajudam a criar uma identidade visual distinta que diferencia sua marca da concorrência. Elas podem ser utilizadas em logotipos, embalagens, materiais de marketing e muito mais, tornando sua marca reconhecível e memorável.</p>

                        <p>Invista em projetos que possam diferenciar a sua empresa e que sejam capazes de impulsionar o crescimento da sua marca. Entre em contato com a Boa Imagem e peça um orçamento.</p>


                    </div>
                </div>
            </section>
        </>
    );
}

export default Ilustracoes2D3D;