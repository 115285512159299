import Breadcrumbs from "../../../../layouts/Breadcrumbs";
import { useEffect } from "react";

const MarketingPessoal = () => {
  useEffect(() => {
    document.title =
      "Marketing Pessoal - Assessoria para Figúras Públicas, Profissionais e Influencers";
    const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
      metaDescription.setAttribute(
        "content",
        "Entenda quais as vantagens e diferenças do Marketing Pessoal na estratégia de empresas e projetos. Saiba como o fator estratégia pode fazer toda a diferença. Confira!"
      );
    }
  }, []);

  const breadcrumbsItens = [
    {
      name: "Serviços",
      alias: "/servicos",
    },
    {
      name: "Estratégias",
      alias: "/estrategias",
    },
    {
      name: "Estratégias de marketing",
      alias: "/estrategias-de-marketing",
    },
    {
      name: "Marketing Pessoal",
      alias: "/marketing-pessoal",
    },
  ];

  return (
    <>
      <Breadcrumbs title={"Marketing Pessoal"} items={breadcrumbsItens} />
      <section id="servicesInsidePage">
        <div className="relative bg_secundary section_padding_clip">
          <div className="container">
            <p>
              A frase, “<b>a primeira impressão é a que fica</b>”, faz todo
              sentido quando o assunto é <b>a sua Imagem</b>. O motivo é claro:
              como seu público e seguidores saberão de suas propostas se não há
              uma comunicação que passe a imagem desejada? A resolução do
              problema de comunicação se dá com o uso de várias ferramentas,
              como o Marketing Pessoal, que você verá mais adiante, porém, o que
              move a campanha de Marketing Pessoal é o fator <b>Estratégia</b>.
            </p>

            <p>
              E por conseguinte, para uma boa estratégia, é necessária uma ótima
              equipe.
            </p>

            <h2>
              Grandes Figuras publciamente expostas e/ou conhecidas precisam de
              uma grande equipe de marketing Pessoal
            </h2>

            <p>
              Você com certeza, dificilmente ouviu falar nos nomes: Nizan
              Guanaes, Paulo Tarso Santos e João Santana. Mas com certeza sabe
              de quem se trata: Luiz Inácio Lula da Silva, Dilma Rousseff, Jair
              Bolsonaro, Anthony Garotinho, Fernando Henrique e Collor. Também
              deve conhecer nomes como Felipe Neto, Whinderson Nunes, Anitta e
              Ivete Sangalo. Os primeiros nomes citados são dos marqueteiros
              responsáveis pelas estratégias para posicionamento de marketing
              pessoal e os outros são os nomes de pessoas públicas que foram
              construídos como marcas. Comunicação e Marketing é essencial!
            </p>

            <p>
              A vitória depende de escolhas, diz respeito a qual estratégia será
              usada, qual o tom de campanha, a equipe que vai assessorar e
              tantos outros aspectos.
            </p>

            <p>
              O primeiro passo é escolher a melhor equipe. A Boa Imagem atua
              desde 2009 em Brasília. Com a visão 360° graus, sabemos qual a
              melhor estratégia e quais os melhores canais para o seu
              posicionamento perante o público.
            </p>

            <p>
              A seguir, você entenderá quais as vantagens, desvantagens e
              diferenças do Marketing Pessoal para outras estratégias conhecidas
              no mercado.
            </p>

            <h2>Marketing Pessoal X marketing Empresarial</h2>

            <p>
              A primeiro momento, os conceitos podem se confundir, porém, a
              diferença entre os dois termos é clara:
            </p>

            <ul>
              <li>
                <b>Marketing Pessoal</b> – Trata-se de uma estratégia pensada a
                longo prazo. Pode ser pensada tanto antes de lançar um novo
                projeto, quanto após um acontecimento importante. Constitui uma
                ferramenta para gerir a imagem da figura pública em todos os
                canais de comunicação.
              </li>
              <li>
                <b>Marketing Empresarial</b> – Consiste estratégias de
                divulgação para Empresas, com o intuito de construir a imagem da
                Marca para que esta alcance mais vendas no mercado a qual está
                inserida.
              </li>
            </ul>

            <h2>Marketing Pessoal – Consolidação do seu nome como marca</h2>

            <p>
              A maior vantagem dessa modalidade de Marketing é, sem dúvida, a
              solidez. Ao construir uma estratégia pensada a longo prazo, é
              possível pensar em todos os aspectos possíveis.
            </p>

            <p>
              As ações e comportamentos pensados, irão compor uma imagem
              positiva da Figura Pública e diferenciá-lo dos demais, aumentando
              as chances de se eleger e prevendo as possíveis investidas da
              oposição.
            </p>

            <p>
              Além disso, é possível construir com calma a imagem da Figura
              Pública, tais como: estilo jovial ou conservador, melhor
              vestimenta, tipo de discurso, quais as causas ideais para apoiar,
              promessas de campanha, conhecimento do perfil de eleitor,
              encomenda de pesquisas de público, participação em eventos e
              demais ações.
            </p>

            <p>
              As desvantagens do Marketing Pessoal consistem no tempo necessário
              para montar e colocar em prática toda a estratégia, a verba
              necessária para manter a equipe e o investimento em meios de
              comunicação, sejam eles online ou offline. Principalmente por
              neste caso não haver um retorno financeiro direto relacionado às
              vendas.
            </p>

            <h2>Marketing Empresarial – Foco na conversão</h2>

            <p>
              Este tipo de estratégia geralmente tem prazo determinado e
              objetivo claro: VENDAS!. Dessa forma, as campanhas de vendas tem
              dia para começar e para terminar, além disso, a verba disponível
              também é definida para um período específico e só são extendidas
              em campanhas com foco em Branding e Posicionamento de Marca, estas
              são algumas desvantagens que podemos considerar.
            </p>

            <p>
              A vantagem do Marketing Empresarial consiste na pontualidade do
              processo. A estratégia pensada, precisa cumprir o único objetivo,
              levar os clientes até a empresa e não tem a necessidade de lidar
              com questões muito específicas relacionadas a intimidade, como é o
              caso do Marketing Pessoal.
            </p>

            <p>
              Com a verba em mãos é possível escolher quais os canais essenciais
              para o sucesso da comunicação e já analisar a aceitação ou
              aprovação do público através das pesquisas de intenções efetuadas
              por institutos capacitados para realização das mesmas.
            </p>

            <h2>Visão estratégica | O case de sucesso barack obama</h2>

            <p>
              O primeiro presidente negro dos Estados Unidos teve destaque não
              só pela cor, mas também pela estratégia ousada de Marketing. A
              campanha foi na contra-mão dos concorrentes e concentrou seus
              esforços no público certo. Dessa forma, além de conseguir o apoio
              popular, Obama conseguiu doações para sua campanha.
            </p>

            <blockquote>
              Mas qual o segredo do sucesso de Obama? A estratégia.
            </blockquote>

            <p>
              Barack Obama e sua equipe perceberam que o seu público era
              conectado e engajado, sendo assim, focaram nos principais canais
              de comunicação. A estratégia de Marketing da Figura Pública a
              presidência incluía:
            </p>

            <ul>
              <li>Site bem estruturado e com botões de ação;</li>
              <li>Landing pages para conversão em doação;</li>
              <li>Envio de e-mail marketing para os contatos;</li>
              <li>Presença forte nas redes sociais.</li>
            </ul>

            <p>
              O resultado da campanha foi um sucesso, garantindo a vitória! E,
              mais tarde, a reeleição do presidente.
            </p>

            <p>
              O sucesso dessa campanha presidencial que usou o Marketing Pessoal
              explorando aspéctos únicos como a simpatia, a popularidade de
              Obama por sua equipe de Marketing e Comunicação. Você também pode
              ter ótimos resultados com a ajuda da nossa agência!
            </p>

            <h2>Você precisa de uma estratégia completa!</h2>

            <p>
              Para alcançar o resultado desejado, é preciso ter uma equipe que
              alie estratégia e execução, com maestria. Tanto o Marketing
              Pessoal, quanto o Marketing Empresarial, necessitam de estratégias
              que integrem as mídias offline e online. Conte com a Boa Imagem!
            </p>

            <p>
              Com a nossa agência você garante a boa execução em todos os
              processos de comunicação:
            </p>

            <ul>
              <li>
                <b>Estratégia de Marketing</b> – Identificação do Público;
              </li>
              <li>
                <b>Marketing Digital</b> – Gerenciamento de Redes Sociais,
                Layout e Programação de Site, E-mail Marketing;
              </li>
              <li>
                <b>Marketing Offline</b> – Material Gráfico Impresso;
              </li>
              <li>
                <b>Audiovisual</b> – Fotografia, Produção de Vídeo, Animação,
                Ilustração.
              </li>
            </ul>

            <p>
              Somos uma agência preocupada com o sucesso da sua campanha. Temos
              um time completo com diversos parceiros de serviços, pronto para
              te ajudar a alcançar a vitória. Contate-nos já!{" "}
            </p>
          </div>
        </div>
      </section>
    </>
  );
};

export default MarketingPessoal;
