import Breadcrumbs from "../../../../layouts/Breadcrumbs";
import image01 from "../../../../../assets/images/servicos/servico-outdoor.webp";
import image02 from "../../../../../assets/images/servicos/servico-busdoor.webp";
import { useEffect } from "react";

const OutOfHome = () => {
  useEffect(() => {
    document.title =
      "Out Of Home - A mídia que te impacta fora da sua zona de conforto!";
    const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
      metaDescription.setAttribute(
        "content",
        "A mídia exterior é vista como OOH, ela corresponde a qualquer tipo de mídia que nos impacta a partir do momento em que colocamos os pés fora de casa."
      );
    }
  }, []);

  const breadcrumbsItens = [
    {
      name: "Serviços",
      alias: "/servicos",
    },
    {
      name: "Offline",
      alias: "/offline",
    },
    {
      name: "Mídia e veículo",
      alias: "/midia-e-veiculo",
    },
    {
      name: "Painéis OOH",
      alias: "/out-of-home",
    },
  ];

  return (
    <>
      <Breadcrumbs title={"Painéis OOH"} items={breadcrumbsItens} />
      <section id="servicesPage">
        <div className="relative bg_secundary section_padding_clip">
          <div className="container">
            <h2 className="title_divider mb-30">Out of Home</h2>

            <p>
              Inicialmente a mídia exterior era vista como outdoors e empenas,
              agora o termo mudou para mídia OOH (out of home). Ela corresponde
              a qualquer tipo de mídia que nos impacta a partir do momento em
              que colocamos os pés fora de casa, por isso o termo Out of Home. A
              mídia Out of Home no Brasil ocupa o terceiro lugar no ranking dos
              meios de comunicação mais vistos pela população acima de 13 anos,
              perdendo apenas para a TV aberta e rádio FM. A cada ano estas
              estatísticas sofrem mudanças substanciais.
            </p>

            <p>
              Porém a Mídia Digital Out of Home de acordo com definições
              internacionais, pode ser dividida em três setores:
            </p>

            <ul>
              <li>
                <b>Alto Impacto:</b> Enormes monitores de LCD disponíveis em
                diferentes locais ao ar livre e que atinge pedestres e pessoas
                em trânsito.
              </li>
              <li>
                <b>Ponto de venda:</b> Monitores instalados em pontos de venda
                como supermercados, lojas, restaurantes e Shopping Centers.
              </li>
              <li>
                <b>Audiência Cativa:</b> Comunicação exibida em um local
                específico, com target definidos, onde o consumidor está
                disponível como ônibus, metro, trem, elevador, aeroporto,
                maternidade, etc.
              </li>
            </ul>

            <img
              className="mt-30"
              src={image01}
              alt="Foto: Serviços de Outdoor"
              title="Foto: Serviços de Outdoor"
              width="520"
              height="347"
            />

            <h2 className="title_divider mb-30">Outdoor</h2>

            <p>
              Todas as pessoas que saem de casa para qualquer destino
              provavelmente irão se deparar com um outdoor. Essa forma de
              divulgação é extremamente comum. O objetivo principal do outdoor é
              passar a mensagem de forma objetiva e direta. O anúncio em Outdoor
              é capaz de atingir várias camadas sociais, sendo um tipo de mídia
              que oferece resultados muito positivos.
            </p>

            <p>
              Para que o outdoor seja bem reconhecido é preciso aplicar
              simplicidade na campanha, pois a mesma precisa ser lembrada. É uma
              das mídias externas mais utilizadas para divulgação de:
            </p>

            <ul className="list">
              <li>produtos</li>
              <li>serviços</li>
              <li>shows</li>
              <li>lançamentos</li>
            </ul>

            <p>
              Consiste em inserir publicidade em placas de madeira ou metal em
              rodovias e locais de grande circulação popular. Com o passar do
              tempo, a comunicação em outdoors tem se inovado e apresentado
              painéis de alto destaque.
            </p>

            <p>
              E na Boa Imagem não é diferente, pois nossos profissionais estão
              prontos para preparar a melhor arte para divulgar o seu negócio.
            </p>

            <img
              src={image02}
              alt="Foto: Serviços de Busdoor"
              title="Foto: Serviços de Busdoor"
              width="380"
              height="390"
            />

            <h2 className="title_divider mb-30">Busdoor</h2>

            <p>
              É uma mídia externa móvel que consiste em aplicar o adesivo no
              ônibus com campanhas publicitárias, serviços e informações
              empresariais. O busdoor ganha destaque na divulgação, pois o
              alcance é maior devido a sua mobilidade. Com a Boa Imagem você não
              precisa se preocupar, pois cuidamos de todo o processo de
              construção de arte até a divulgação nos veículos. O Busdoor leva
              sua campanha para todos os lugares, é uma eficiente maneira de
              levar sua mensagem por toda a cidade. Trata-se de um adesivo
              aplicado no vidro traseiro dos coletivos urbanos, painel interno
              ou ainda o envelopamento parcial do veiculo. Em grandes centros
              urbanos, com a contínua movimentação de carros e pedestres,
              trânsito ou usuários de transporte publico, ele possui um grande
              impacto de visibilidade, capaz de promover 90% de recall e atingir
              todos os públicos, oferecendo a melhor relação custo/benefício
              quando se trata de mídia exterior.
            </p>

            <p>
              Comercializamos através de nossos parceiros espaços no vidro
              traseiro, painel interno e backbuss.
            </p>
            <p>Medidas gerais utilizadas para criação:</p>

            <ul className="list">
              <li>Para-brisa traseiro – até 2,30 x 1,00m.</li>
              <li>Painel interno – até 0,44 x 0,55cm</li>
              <li>Backbuss – até 2,90 x 2,40m.</li>
            </ul>

            <p>
              *Lembre-se que há necessidade de sangria devido ao formato de
              janela de cada modelo de veículo.
            </p>

            <p>Conheça outras mídias semelhantes:</p>

            <ul className="list">
              <li>Outdoor</li>
              <li>Taxidoor</li>
              <li>Metrô</li>
              <li>CPTM</li>
              <li>Eventos de Promoção, e mais…</li>
            </ul>

            <p>Entre em contato e solicite já o se orçamento!</p>
          </div>
        </div>
      </section>
    </>
  );
};

export default OutOfHome;
