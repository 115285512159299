import { ServicesInterface } from "../../../../interfaces/ServicesInterface";
import Breadcrumbs from "../../../../layouts/Breadcrumbs";
import ServiceBox from "../../../../layouts/ServiceBox";
import image from '../../../../../assets/images/servicos/servico-cricao-de-sites-2.webp';
import servico01 from '../../../../../assets/images/servicos/servico-SEO–otimizacao.webp';
import servico02 from '../../../../../assets/images/servicos/servico-automacao-de-marketing.webp';
import servico03 from '../../../../../assets/images/servicos/servico-estrategias-de-marketing.webp';
import servico04 from '../../../../../assets/images/servicos/servico-marketing-politico-e-eleitoral.webp';
import { useEffect } from "react";

const CriacaoSites  = () => {

    const services: ServicesInterface[] = [
        {
            title: 'Otimização de sites SEO',
            text: 'Estar entre as primeiras posições do Google é sinônimo de credibilidade. Maior exposição da sua marca e maior número de visitas para o seu site.',
            image: servico01,
            link: '/servicos/online/marketing-digital/seo-otimizacao',
        },{
            title: 'Envio de e-mail marketing',
            text: 'O e-mail marketing é uma ferramenta poderosa tanto para melhorar o relacionamento com seus clientes, como para vender seus produtos e serviços. Lembre aos seus clientes que você existe, faça e-mail marketing.',
            image: servico02,
            link: '/servicos/online/marketing-digital/automacao-de-marketing',
        },{
            title: 'SMS marketing e corporativo',
            text: 'Ler ou não ler, eis a questão que não pega quando o assunto é envio de SMS Marketing. Uma das grandes vantagens de utilizar essa estratégia para divulgar sua empresa, produtos e serviços, são as grandes taxas de aberturas.',
            image: servico03,
            link: '/servicos/estrategias/estrategias-de-marketing',
        },{
            title: 'Campanha eleitoral',
            text: 'O serviço de SMS eleitoral é autorizado pelo TSE (conforme Resolução 23.370), desde que as bases de números telefônicos sejam construídas em acordo com as regras da ANATEL e do TSE e sem qualquer cobrança ao usuário pelas mensagens.',
            image: servico04,
            link: '/servicos/estrategias/marketing-politico-e-eleitoral',
        },
    ];

    useEffect(() => {
        document.title = "Criação e Desenvolvimento de Sites e App | Marketing em Brasília";
        const metaDescription = document.querySelector('meta[name="description"]');
        if (metaDescription) {
          metaDescription.setAttribute(
            "content",
            "Somos uma empresa de Brasília especializada na criação de sites responsivos, lojas virtuais, envio de email marketing, corporativo e eleitoral."
          );
        }
    }, []);

    const breadcrumbsItens = [
        {
            name: 'Serviços',
            alias: '/servicos'
        },{
            name: 'Online',
            alias: '/online'
        },{
            name: 'Marketing Digital',
            alias: '/marketing-digital'
        },{
            name: 'Criação de Sites',
            alias: '/criacao-de-sites'
        }
    ];

    return (
        <>
            <Breadcrumbs title={'Criação de sites'} items={breadcrumbsItens}/>
            <section id="servicesPage">
                <div className='relative bg_secundary section_padding_clip'>
                    <div className='container padding'>
                        <img className="mb-30" src={image} alt="Foto: Serviço de Criação de Sites" title="Foto: Serviço de Criação de Sites" width="1200" height="500"/>

                        <p>Na era digital, um site bem projetado é a base da presença online de qualquer negócio. A criação de um site não é importante apenas para a estética da marca, mas também pela funcionalidade, experiência do usuário (UX) e otimização nas ferramentas de busca (SEO).</p>

                        <p>Atualmente, os sites são as vitrines mais eficientes, portanto, precisam ser planejados e desenvolvidos para gerar oportunidades de negócio.</p>

                        <h3><strong>Por que um site profissional é essencial?</strong></h3>
                        <p>Primeira impressão: O site é muitas vezes o primeiro ponto de contato entre a empresa e seus potenciais clientes. Um design profissional transmite credibilidade, confiança e qualidade.</p>

                        <p>Centro de estratégias de marketing: O site serve como hub central para todas as estratégias de marketing. Seja SEO, campanhas de mídia social, e-mail marketing ou publicidade paga, todas essas iniciativas direcionam o tráfego para o site.</p>

                        <p>Experiência do usuário: Um site bem projetado oferece uma navegação intuitiva, carregamento rápido e uma experiência agradável para o usuário. Isso aumenta o tempo de permanência no site e as chances de conversão.</p>

                        <p>Adaptabilidade e responsividade: É essencial que o site seja responsivo, funcionando bem em qualquer dispositivo, seja ele um computador, tablet ou smartphone, para garantir uma experiência de usuário consistente e satisfatória. Um site responsivo melhora a acessibilidade, reduz a taxa de rejeição, aumenta o tempo de permanência dos usuários e pode melhorar o ranqueamento nos motores de busca, contribuindo para o sucesso geral da sua presença online.</p>

                        <p>A criação de sites é feita através do nosso parceiro <a href="https://www.biplus.com.br/" target="_blank" rel="noopener">BI PLUS</a>, que oferece um serviço de altíssima qualidade, servidor cloud e tecnologia de ponta.</p>
                    </div>

                    </div>
            </section>

            <section id="servicesInsidePageOthers"  className='box_divider relative bg_secundary section_padding_clip text_center'>
                <div className='container'>
                    <ServiceBox services={services} />
                </div>
            </section>
        </>
    );
}

export default CriacaoSites;