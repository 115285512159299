import { Link } from "react-router-dom";
import { ServicesInterface } from "../../../interfaces/ServicesInterface";
import Breadcrumbs from "../../../layouts/Breadcrumbs";
import ServiceBox from "../../../layouts/ServiceBox";
import ReactPlayer from "react-player";
import image01 from '../../../../assets/images/servicos/hamburguer-mc-donalds.webp';
import image02 from '../../../../assets/images/servicos/servico-fotografia-de-eventos.webp';
import image03 from '../../../../assets/images/servicos/servico-fotografia-de-eventos-2.webp';
import image04 from '../../../../assets/images/servicos/servico-fotografia-de-eventos-3.webp';
import image05 from '../../../../assets/images/servicos/servico-fotografia-de-pessoas-antes.webp';
import image06 from '../../../../assets/images/servicos/servico-fotografia-de-pessoas-processo.webp';
import image07 from '../../../../assets/images/servicos/servico-fotografia-de-pessoas-depois.webp';
import image08 from '../../../../assets/images/servicos/fotografia-de-produto-still-antes.webp';
import image09 from '../../../../assets/images/servicos/fotografia-de-produto-still-depois.webp';
import image10 from '../../../../assets/images/servicos/foto-macro-olho-de-perto-agência-boa-imagem.webp';
import image11 from '../../../../assets/images/servicos/foto-macro-lente-canon-40mm.webp';
import image12 from '../../../../assets/images/servicos/servico-fotografia-de-ambientes.webp';
import image13 from '../../../../assets/images/servicos/servico-fotografia-de-ambientes-2.webp';
import image14 from '../../../../assets/images/servicos/servico-fotografia-de-ambientes-3.webp';

import servico01 from '../../../../assets/images/servicos/servico-design-de-produto.webp';
import servico02 from '../../../../assets/images/servicos/servico-estrategias-de-marketing.webp';
import servico03 from '../../../../assets/images/servicos/servico-videos-e-animacoes.webp';
import servico04 from '../../../../assets/images/servicos/servico-propaganda-de-televisao.webp';
import servico05 from '../../../../assets/images/servicos/servico-propaganda-em-radios.webp';
import servico06 from '../../../../assets/images/servicos/servico-propaganda-em-revistas.webp';
import { useEffect } from "react";

const FotografiaPublicitaria = () => {

    const services: ServicesInterface[] = [
        {
            title: 'Design de produto',
            text: 'Estamos preparados para desenvolver a criação do design de produto, peças publicitárias e outros modelos que podem fazer parte da necessidade da sua empresa.',
            image: servico01,
            link: '/servicos/offline/design',
        },{
            title: 'Estratégias de marketing',
            text: 'Independente do seu segmento, possuir uma boa estratégia de marketing faz toda a diferença na hora de atingir o sucesso e a visibilidade.',
            image: servico02,
            link: '/servicos/estrategias/estrategias-de-marketing',
        },{
            title: 'Vídeos e animações',
            text: 'Produzimos vídeos comerciais e publicitários, além de atendermos demanda para novos espaços como youtube e novas mídias. No modelo de animação 2D e 3D a criação do conteúdo pode ser feito de maneira completa.',
            image: servico03,
            link: '/servicos/offline/videos',
        },{
            title: 'Propaganda de televisão',
            text: 'As telinhas impactam milhões de brasileiros todos os dias, a mídia televisiva influencia a formação de opinião, apresentando aspectos de modernidade que causam transformações no sistema econômico.',
            image: servico04,
            link: '/servicos/offline/propaganda-de-televisao',
        },{
            title: 'Propaganda em rádios',
            text: 'As rádios tem o seu público cativo, um veículo de divulgação muito procurado pelos anunciantes pois pode ele oferece um baixo custo. Ou seja, para produzir e vincular o valor é mais em conta.',
            image: servico05,
            link: '/servicos/offline/propaganda-de-radio',
        },{
            title: 'Propaganda em revistas',
            text: 'A propaganda de revista possibilita o melhor tempo de leitura. Quando determinada pessoa está lendo uma revista, a atenção está voltada para as páginas, assim as chances de interferência nos anúncios é mínimo.',
            image: servico06,
            link: '/servicos/offline/propaganda-de-revista',
        }
    ];

    useEffect(() => {
        document.title = "A importância da fotografia publicitária | Contrate Profissionais";
        const metaDescription = document.querySelector('meta[name="description"]');
        if (metaDescription) {
          metaDescription.setAttribute(
            "content",
            "A fotografia publicitária, da Boa Imagem em Brasília, é pensada de forma sistemática e planejada quanto ao conceito, ideia, enquadramento, iluminação, cenário, personagens. Confira!"
          );
        }
    }, []);

    const breadcrumbsItens = [
        {
            name: 'Serviços',
            alias: '/servicos'
        },{
            name: 'Offline',
            alias: '/offline'
        },{
            name: 'Fotografia Publicitária',
            alias: '/fotografia-publicitaria'
        }
    ];

    return (
        <>
            <Breadcrumbs title={'A Fotografia Publicitária que Impulsiona seu Negócio e Cativa seus Clientes'} items={breadcrumbsItens}/>
            <section id="servicesPage">
                <div className='relative bg_secundary section_padding_clip'>
                    <div className='container'>

                        <h2 className="title_divider mb-30">Capture a Essência da sua Empresa com Imagens de Qualidade Profissional e Aumente suas Vendas</h2>
                        
                        <p>Seja bem-vindo à nossa página dedicada à Fotografia Publicitária, o serviço que vai levar sua empresa ao mesmo patamar das grandes empresas e indústrias do mercado. Sabemos o quão importante é a qualidade das imagens neste momento da história conhecido como "a era digital" e como agência estamos aqui para transmitir a essência e personalidade do seu negócio. Com nossa expertise em fotografia publicitária, as suas imagens podem se tornar poderosas ferramentas de comunicação, impulsionando sua marca, atraindo mais clientes e gerando mais vendas. Vamos explicar abaixo como utilizamos fotos de alta qualidade para transformar o ponto de vista do seu cliente e alinnhar os objetivos de comunicação da sua marca com a realidade.</p>

                        <p>As imagens sempre foram utilizadas para registrar momentos especiais, principalmente na vida pessoal. Fotografias também tem a função de transmitir sentimentos, seja no sentido pessoal, mas também no sentido jornalístico ou comercial. Para fins comerciais é necessário técnica e produção para que a mensagem deja adequada ao público-alvo, afinal o público precisa aderir a ideia, comprar o produto ou serviço que a fotografia publicitária está fazendo o papel de eternizar em apenas um click. As dicas que vamos abordar a seguir são importantes caso queira contratar um profissinal, como a equipe de parceiros da Agência Boa Imagem, ou mesmo produzir na sua empresa algumas fotos e vídeos usando seu celular.</p>

                        <h3>Como a fotografia publicitária pode te ajudar</h3>

                        <p>A fotografia é capaz de transmitir diferentes sensações como: felicidade, alegria, tristeza, abandono, calor, frio e tantas outras. A fotografia publicitária tem sua capacidade de transmitir a mensagem potencializada, pois é pensada de forma sistemática e planejada quanto ao conceito, ideia, enquadramento, iluminação, cenário, personagens e tantos outros detalhes. Dessa forma é possível mostrar o seu produto/serviço da melhor forma para o seu público-alvo.</p>


                        <h2>Quer saber como a Boa Imagem pode ajudar você nesse quesito? Clique no botão ao lado para conversar conosco:</h2>

                        <Link className="btn" to={"/contato"}>Fale Conosco!</Link>	  

                        <h3>Por que a Fotografia Publicitária pode ser um fator crucial para o Sucesso</h3>

                        <p>No mundo de hoje, onde a concorrência é acirrada e as pessoas estão sempre olhando belas imagens e vídeos em seus aparelhos celulares, a fotografia publicitária é uma das principais maneiras de fazer com que algo seja visto com uma boa imagem pelo público. Quando se trata de promover sua empresa, produtos ou serviços, a qualidade das imagens é um fator decisivo para atrair a atenção e o interesse do seu público-alvo. Muitas pessoas são visualmente orientadas, e fotografias bem produzidas tem o poder de transmitir emoções, contar histórias e criar conexões profundas.</p>
                        
                        <p>Para ajudar você a entender melhor como criar fotos e vídeos incríveis com seu celular, selecionamos uma série de vídeos tutoriais que abordam diferentes aspectos da fotografia e filmagem com dispositivos móveis. Esses vídeos irão ensiná-lo a aproveitar ao máximo a câmera do seu celular e criar imagens impressionantes para o seu negócio, redes sociais e websites.</p>

                        <div className="box_two_columns_desktop">
                            <div>
                                <h4>1. Dicas de enquadramento e composição para iniciantes</h4>
                                <ReactPlayer url={`https://player.vimeo.com/video/809630770?h=bb800dd94b`} controls responsive='true' light={true} width='100%' height='342px'/>
                            </div>    

                            <div>
                                <h4 className="mt-30">2. Como aproveitar a iluminação e utilizar coisas simples para auxiliar em vídeos e fotos</h4>
                                <ReactPlayer url={`https://player.vimeo.com/video/788728610?h=664b598be9`} controls responsive='true' light={true} width='100%' height='342px'/>
                            </div>    

                            <div>
                                <h4 className="mt-30">3. Os Planos das Cenas e sua interferência no contexto da imagem</h4>
                                <ReactPlayer url={`https://player.vimeo.com/video/788728823?h=763b018451`} controls responsive='true' light={true} width='100%' height='342px'/>
                            </div>    

                            <div>
                                <h4 className="mt-30">4. Entenda rapidamente como funciona a regra dos terços para fotografia e vídeos</h4>
                                <ReactPlayer url={`https://player.vimeo.com/video/788728780?h=1f77d73233`} controls responsive='true' light={true} width='100%' height='342px'/>
                            </div>    
                        </div>

                        <h3 className="mt-30">Transmitindo a Essência de Produtos e Serviços por meio de Fotos Profissionais</h3>

                        <p>Uma das principais vantagens da fotografia publicitária é sua capacidade de capturar a essência da sua empresa e transmiti-la de forma autêntica ao seu público. A Boa Imagem tem acesso a bancos de imagens exclusivos e fotógrafos profissionais especializados, que vão trabalhar junto com a nossa equipe para entender o DNA da sua marca e traduzi-lo em imagens visualmente impactantes. Cada foto é cuidadosamente planejada e produzida para refletir os valores, a personalidade e a identidade da sua empresa. Seja qual for o setor em que você atua, nossas imagens irão encantar e envolver seu público, tornando-se uma ferramenta poderosa para estabelecer conexões duradouras.</p>
                                
                        <h3>A Fotografia Publicitária e a Propaganda</h3>

                        <p>Nós, da Boa Imagem, conseguimos oferecer serviços de fotografia publicitária, com parceiros em todas as regiões do Brasil, aptos a desenvolver projetos personalizados de acordo com as necessidades específicas do seu negócio. Nossa equipe está pronta para trabalhar em parceria com você, desde a concepção e planejamento até a aplicação correta das suas imagens nas peças de comunicação e campanhas publicitárias.</p>
                        
                        <h3>A Importância da Qualidade na Fotografia Publicitária</h3>

                        <p>Sabemos que a qualidade das fotos é o fator crucial para ter sucesso em campanhas publicitárias. Imagens de qualidade inferior podem prejudicar sua marca, transmitindo a mensagem errada e afastando potenciais clientes. Por isso, garantimos que cada foto seja capturada com equipamentos de última geração e processada por profissionais experientes no pós-processamento. Nossa busca constante pela excelência nos permite entregar imagens nítidas, com cores vibrantes e composições impactantes, que certamente deixarão uma impressão duradoura nos espectadores.</p>

                        <p>Ao segurar uma câmera você ganha a capacidade de transmitir mensagens e registrar momentos para a eternidade, por isso é importante saber captar as informações relevantes da cena utilizando muitas vezes apenas a iluminação disponível.</p>

                        <p>A fotografia é uma poderosa ferramenta de comunicação e, quando aplicada à publicidade, pode transmitir de forma ainda mais impactante a mensagem que uma empresa quer passar. É por isso que na Boa Imagem fazemos sempre questão de ter as melhores fotografias para criar campanhas de marketing eficientes e de alta qualidade para empresas públicas e privadas.</p>

                        <p>Para pequenas empresas, ou que ainda estão começando, é importante desde o início priorizar a qualidade das fotos e vídeos utilizados na comunicação. Isso ajuda a construir um conteúdo que registra a história da marca, apresentando com qualidade seus produtos, serviços, clientes e equipe interna. Com isso, a empresa é vista de forma assertiva no mercado, o que pode gerar mais oportunidades de negócios e fidelizar clientes. A boa imagem de uma empresa começa desde a apresentação visual, e não é preciso ser uma grande corporação para ter uma comunicação eficiente.</p>

                        <p>A fotografia publicitária vai muito além de simplesmente tirar fotos de um produto ou serviço. Ela é planejada de forma cuidadosa para transmitir uma mensagem específica, considerando conceitos, ideias, enquadramentos, iluminação, cenários e personagens, entre outros detalhes. É assim que é possível mostrar o produto ou serviço da melhor forma possível para o público-alvo.</p>

                        <h3>Case mcdonald’s</h3>

                        <p>O vídeo que você verá a seguir ilustra perfeitamente a importância das imagens profissionais para qualquer negócio. A fotografia publicitária foi e continuará sendo uma das ferramentas mais importantes na hora de mostrar o seu produto. É válida para todos os tipos de empresas, seja ela pequena, média ou grande.</p>

                        <p>Confira agora como funciona o processo de produção de fotos de uma das maiores franquias do mundo.</p>

                        <ReactPlayer url={`https://www.youtube.com/watch?v=oSd0keSj2W8`} light={true} width='342px' height='570px'/>

                        <p className="mt-30 text_center"><b>RESULTADO</b></p>

                        <img className="mb-30" src={image01} title="Foto: Hambúrguer MC Donalds" alt="Foto: Hambúrguer MC Donalds" width="627" height="345"/>

                        <p>Mostrar o produto/serviço de uma empresa de forma bonita e agradável pode fazer toda a diferença na hora de conquistar clientes. É por isso que a fotografia publicitária se torna tão importante, especialmente para pequenas empresas. Além de transmitir a mensagem desejada, é uma forma de registrar a história da marca, apresentar com qualidade produtos, serviços, clientes e a equipe interna. Assim, desde o começo, priorizar ter boas fotos e bons vídeos pode ajudar a construir um conteúdo que represente a empresa de forma positiva no mercado. </p>
                        <h3>Fotografia publicitária x fotografia comum</h3>
                        <p>A fotografia profissional faz toda a diferença na percepção do seu público-alvo. Assim como mostrado no case McDonald’s, uma imagem produzida por uma equipe de profissionais qualificados pode atingir o objetivo que a sua empresa precisa: encantar e atrair os clientes certos, ressaltando as qualidades do seu produto/serviço.</p>

                        <h3>Priorize a sua Boa Imagem!</h3>
                        <p>Levamos o nosso nome muito a sério, assim como nosso propósito principal como marca que é de ajudar outras empresas e por isso já ajudamos mais de 1.000 empresas a construir uma imagem positiva e direcionada ao seu público-alvo certo.</p>
                        <p>Para a produção e posterior edição das fotos, é necessário inicialmente um briefing com o cliente, assim é possível saber todas as necessidades que irão ajudar a empresa. Junto com a agência de publicidade você pode definir se deseja fazer:</p>
                        <ul className="list">
                            <li><strong>Campanha:</strong> para divulgar produtos e serviços da empresa de forma mais abrangente, com uma abordagem mais conceitual e criativa.</li>
                            <li><strong>Ensaio gastronômico:</strong> para destacar a qualidade dos pratos e da culinária de um estabelecimento.</li>
                            <li><strong>Fotos específicas de um produto ou serviço:</strong> para apresentar com detalhes um produto ou serviço em específico.</li>
                            <li><strong>Fotografia de eventos:</strong> para documentar eventos corporativos, como palestras, conferências e feiras.</li>
                            <li><strong>Fotografia de arquitetura e interiores:</strong> para mostrar projetos de arquitetura e decoração de interiores.</li>
                            <li><strong>Fotografia de retratos corporativos:</strong> para registrar a equipe e os funcionários da empresa em retratos profissionais.</li>
                            <li><strong>Fotografia de produtos em uso:</strong> para mostrar produtos em situações de uso, evidenciando suas funcionalidades e vantagens.</li>
                            <li><strong>Fotografia de processos produtivos:</strong> para mostrar as etapas da produção de um produto, desde a matéria-prima até a sua finalização.</li>
                            <li><strong>Fotografia de equipe e funcionários em ação:</strong> para mostrar a equipe em ação e evidenciar o trabalho em equipe e o comprometimento dos colaboradores.</li>
                            <li><strong>Fotografia aérea:</strong> utilizada para registrar imóveis comerciais, empreendimentos, áreas industriais, entre outros, do alto.</li>
                        </ul>

                        <p>A seguir você verá alguns exemplos de trabalhos que demonstam algumas técnicas de fotografia:</p>

                        <h3>Fotografia de eventos</h3>

                        <p>Planejar um evento requer esforço, dedicação, organização e, acima de tudo, o desejo de que tudo correrá como previsto. Tanto trabalho assim merece ser documentado, fotografado, com a iluminação, enquadramento e o destaque certo! Conte com profissionais que saibam capturar os momentos certos, os sorrisos, o ambiente e principalmente, a essência do evento.</p>

                        <img className="mb-30" src={image02} title="Foto: Serviço de Fotografia de Eventos" alt="Foto: Serviço de Fotografia de Eventos" width="1024" height="682"/>
                        <img className="mb-30" src={image03} title="Foto: Serviço de Fotografia de Eventos" alt="Foto: Serviço de Fotografia de Eventos" width="1024" height="682"/>
                        <img className="mb-30" src={image04} title="Foto: Serviço de Fotografia de Eventos" alt="Foto: Serviço de Fotografia de Eventos" width="1024" height="682"/>

                        <h3>Fotografia de pessoas</h3>

                        <p>É comprovado que a imagem de pessoas em campanhas publicitárias, sejam elas em mídias offline ou online são capazes de causar maior aproximação e aceitação do público, por isso precisa de tratamento profissional para atingir o objetivo crucial: as vendas. O processo mostrado a seguir ilustra a sequência de um trabalho de tratamento de imagem.</p>

                        <p>A boa imagem é fundamental para a construção de uma imagem positiva e confiável de figuras públicas, influencers e funcionários de empresas. Através de uma produção fotográfica profissional, é possível transmitir uma mensagem coesa e alinhada com a proposta da marca, gerando uma identificação maior com o público e consequentemente aumentando a credibilidade e a confiança.</p>

                        <div className="box_three_columns_desktop mb-30">
                            <img src={image05} title="Foto: Serviço de Fotografia de Pessoas - Antes" alt="Foto: Serviço de Fotografia de Pessoas - Antes" width="440" height="661"/>
                            <img src={image06} title="Foto: Serviço de Fotografia de Pessoas - Processo" alt="Foto: Serviço de Fotografia de Pessoas - Processo" width="683" height="1024"/>
                            <img src={image07} title="Foto: Serviço de Fotografia de Pessoas - Depois" alt="Foto: Serviço de Fotografia de Pessoas - Depois" width="471" height="727"/>
                        </div>


                        <h3>Fotografia Still de Produto</h3>
                        <p>Quando o assunto é produto, é fundamental que ele seja apresentado de forma clara e profissional para que possa chamar a atenção do público e se destacar no mercado. E é exatamente aí que entra a Fotografia Still, uma técnica que tem como objetivo mostrar produtos de forma objetiva e bem definida, sem nenhum tipo de interferência.</p>
                        <p>As fotos Still são ideais para catálogos, e-commerce, mídias sociais, entre outras mídias digitais. Elas são produzidas em um estúdio fotográfico, com um fundo neutro ou branco, e utilizam uma iluminação adequada para que o produto fique destacado e nítido. Com o uso da técnica Still, é possível mostrar detalhes importantes, como a textura, a cor e os acabamentos do produto, tornando-o ainda mais atrativo para o público.</p>
                        <p>Essa técnica é amplamente utilizada por empresas de diferentes segmentos, desde a indústria alimentícia até o setor automotivo, passando por lojas de roupas, de móveis e decoração, e muitos outros. Se você quer destacar seus produtos e fazer sua empresa crescer, invista na Fotografia Still de Produto. Entre em contato conosco e saiba mais sobre como podemos ajudar sua empresa a se destacar no mercado!</p>
                        <p>As imagens mostradas abaixo foram produzidas em alguns projetos de clientes internos da Boa Imagem e tem como principal objetivo ser utilizadas em um site e-commerce.</p>

                        <div className="box_two_columns_desktop text_center">
                            <div>
                                <img src={image08} title="Foto: Serviço de Fotografia de Produto Still - Antes" alt="Foto: Serviço de Fotografia de Produto Still - Antes" width="768" height="512"/>
                                <p>Antes</p>
                            </div>                            
                            <div>
                                <img src={image09} title="Foto: Serviço de Fotografia de Produto Still - Depois" alt="Foto: Serviço de Fotografia de Produto Still - Depois" width="197" height="300"/>
                                <p>Depois</p>
                            </div>                                
                        </div>

                        <h3>Fotografia Macro</h3>
                        <p>A fotografia macro é uma técnica de fotografia que permite capturar imagens em grande escala, com detalhes minuciosos de pequenos objetos ou partes de objetos. Ela é utilizada em diversos segmentos como na indústria, ciência, arte, entre outros.</p>
                        <p>Na indústria, por exemplo, a fotografia macro é utilizada para fotografar peças em produção e assim analisar detalhes e possíveis falhas. Na ciência, é uma técnica importante para registrar micro-organismos, como células e bactérias, e detalhes de estruturas anatômicas de seres vivos. Na arte, a fotografia macro pode ser utilizada para criar imagens com efeitos visuais impressionantes, explorando texturas e formas em objetos comuns.</p>
                        <p>Na publicidade e no comércio eletrônico, a fotografia macro é utilizada para destacar detalhes e texturas de produtos, especialmente em áreas como joalheria, moda e cosméticos. É uma técnica que ajuda a evidenciar a qualidade dos produtos, dando uma sensação de realismo e detalhe para o consumidor.</p>


                        <div className="box_two_columns_desktop text_center">
                            <div>
                                <img className="mb-30" src={image10} title="Foto Macro Olho de perto Agência Boa Imagem de Propaganda e Publicidade" alt="Foto Macro Olho de perto Agência Boa Imagem de Propaganda e Publicidade" width="789" height="790"/>
                                <p>Fotografia Macro de Ìris (olho)</p>
                            </div>

                            <div>
                                <img className="mb-30" src={image11} title="Foto Macro lente Canon 40mm em comparação com moeda tamanho da lente Agência Boa Imagem de Propaganda e Publicidade" alt="Foto Macro lente Canon 40mm em comparação com moeda tamanho da lente Agência Boa Imagem de Propaganda e Publicidade" width="793" height="784"/>
                                <p>Fotografia Macro com profundidade de Campo</p>
                            </div>
                        </div>

                        <h3>Fotografia de ambientes</h3>

                        <p>A fotografia profissional é primordial na hora de mostrar o seu ponto de venda. Fatores como iluminação, ângulo, enquadramento podem ressaltar características como: conforto, decoração, sofisticação, estilo. Chame a atenção do seu público e atraia mais clientes!</p>

                        <img className="mb-30" src={image12} title="Foto: Serviço de Fotografia de Ambientes 1" alt="Foto: Serviço de Fotografia de Ambientes 1" width="1024" height="683"/>
                        <img className="mb-30" src={image13} title="Foto: Serviço de Fotografia de Ambientes 2" alt="Foto: Serviço de Fotografia de Ambientes 2" width="1024" height="682"/>
                        <img className="mb-30" src={image14} title="Foto: Serviço de Fotografia de Ambientes 3" alt="Foto: Serviço de Fotografia de Ambientes 3" width="1024" height="683"/>
                            
                        <h3>Conheça Modalidades de Fotografias Publicitárias:</h3>

                        <p><b>1. Fotografia de Produtos:</b> Destaque os detalhes e características únicas dos seus produtos por meio de imagens de alta qualidade que despertam o interesse dos clientes.</p>
                        <p><b>2. Fotografia de Moda:</b> Se você trabalha com moda, temos expertise em capturar a essência e estilo das suas peças, garantindo que suas coleções se destaquem e atraiam a atenção dos consumidores.</p>
                        <p><b>3. Fotografia de Alimentos:</b> Seu restaurante ou negócio relacionado à gastronomia precisa de imagens apetitosas e irresistíveis para conquistar o paladar dos clientes. Vamos auxiliar a capturar a beleza e a qualidade dos pratos de forma atraente.</p>
                        <p><b>4. Fotografia de Ambientes e Arquitetura:</b> Transmita a atmosfera e o estilo do seu espaço físico por meio de imagens que mostram a arquitetura, design de interiores e aconchego, convidando os clientes a conhecerem seu estabelecimento.</p>
                        <p><b>5. Fotografia de Eventos:</b> Quer registrar momentos especiais e promover seus eventos? Você precisa de fotógrafos experientes para capturar os melhores momentos, criando imagens que mostram a energia e a emoção vivenciadas durante o evento.</p>
                            
                        <h3>Por que trabalhar em conjunto com a Boa Imagem para seus projetos que precisam de Fotos Publicitárias?</h3>

                        <ul className="list">
                            <li>Experiência e Profissionalismo: Temos acesso a centenas de profissionais em todo o Brasil para produzir fotografias autorais utilizando técnicas e tendências do mercado publicitário.</li>
                            <li>Personalização e Parceria: Trabalhamos em estreita colaboração com cada cliente, compreendendo suas necessidades e objetivos específicos. Cada projeto é tratado de forma única, garantindo que suas expectativas sejam superadas.</li>
                            <li>Qualidade Superior: Buscamos incessantemente a excelência em todas as etapas do processo, em conjunto com o fotógrafo ou até mesmo utilizando bancos de imagem, assim a agência vai cuidar de aplicações em design e pós-processamento. </li>
                            <li>Entrega Pontual: Sabemos que prazos são importantes e acompanhamos rigorosamente os prazos acordados, garantindo que você tenha as imagens necessárias no momento certo para suas campanhas e promoções.</li>
                            <li>Investimento Inteligente: Entendemos que cada negócio tem seu orçamento. Buscamos serviços de qualidade a preços justos, para que você obtenha retorno significativo sobre o investimento realizado e fique feliz com o profissional e o resultado das suas fotos.</li>
                        </ul>
                        
                        <h3>Mostre sua empresa ao mundo da forma certa!</h3>
                        <p>Ter uma "boa imagem" é fundamental para qualquer empresa que queira se destacar no mercado. E para isso, a fotografia é uma ferramenta poderosa que pode ser utilizada de diversas formas, desde mostrar o produto ou serviço oferecido, até retratar a equipe e o ambiente de trabalho. Com o auxílio de uma agência especializada em fotografia publicitária, é possível transmitir a mensagem que a empresa deseja de forma profissional e impactante.</p>
                        <p>Na Boa Imagem, entendemos a importância de uma boa fotografia para a construção da imagem empresarial e oferecemos soluções completas e inovadoras para atender às necessidades de cada cliente. Nosso time de profissionais e parceiros altamente especializados trabalha com as mais modernas técnicas e equipamentos para garantir que o resultado final seja exatamente o que o cliente espera. Entre em contato conosco e descubra como podemos ajudar a sua empresa a transmitir a mensagem certa e construir uma imagem de sucesso no mercado.</p>
                        <p>Como agência de propaganda Full Service, trabalhamos de forma completa para que a sua empresa seja bem vista no mercado, com fotos, vídeos, animações para a Boa Publicidade... Invista em projetos que possam diferenciar a sua empresa e capazes de impulsionar o crescimento da sua marca.</p>
                    </div>
                    <div className='container text_center'>
                        <h2 className="title_divider mb-30">Entenda outras áreas de atuação da agência:</h2>
                    </div>
                </div>
            </section>

            <section id="servicesInsidePageOthers"  className='box_divider relative bg_secundary section_padding_clip text_center'>
                <div className='container'>
                    <ServiceBox services={services} />
                </div>
            </section>
        </>
    );
}

export default FotografiaPublicitaria;