import { Link } from 'react-router-dom';
import {PaginationInterface} from '../interfaces/PaginationInterface';
import React from 'react';
import { MdOutlineKeyboardDoubleArrowLeft, MdOutlineKeyboardArrowLeft, MdOutlineKeyboardDoubleArrowRight, MdOutlineKeyboardArrowRight } from "react-icons/md";

const Pagination: React.FC<PaginationInterface<undefined>> = ({ pagination }) => {
    return (        
        <div className="pagination">
            <div className='pagination_links'>
                {pagination.page_current !== 1 ? (
                    <Link to={'?page=1'}><MdOutlineKeyboardDoubleArrowLeft/></Link>
                ): (
                    <span className="disabled"><MdOutlineKeyboardDoubleArrowLeft/></span>
                )}

                {pagination.page_current !== 1 ? (
                    <Link to={`?page=${pagination.page_prev}`}><MdOutlineKeyboardArrowLeft /></Link>
                ): (
                    <span className="disabled"><MdOutlineKeyboardArrowLeft /></span>
                )}
                
                <span>Página {pagination.page_current} de {pagination.total_pages}</span>

                {pagination.page_next != null ? (
                    <Link to={`?page=${pagination.page_next}`}><MdOutlineKeyboardArrowRight /></Link>
                ): (
                    <span className="disabled"><MdOutlineKeyboardArrowRight /></span>
                )}

                {pagination.page_current !== pagination.total_pages ? (
                    <Link to={`?page=${pagination.total_pages}`}><MdOutlineKeyboardDoubleArrowRight/></Link>
                ): (
                    <span className="disabled"><MdOutlineKeyboardDoubleArrowRight/></span>
                )}   
            </div>                 

            <div className="pagination_all_results">
                Mostrando {pagination.post_start} - {pagination.post_end} de {pagination.total_posts} Resultados
            </div>
        </div>
    );
}

export default Pagination;