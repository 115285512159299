import logo from "../../assets/images/logo-boa-imagem.webp";
import { IoLogoWhatsapp, IoIosStar } from "react-icons/io";
import { LuPhone } from "react-icons/lu";
import { IoIosMail } from "react-icons/io";
import { MdFacebook } from "react-icons/md";
import { RiInstagramFill } from "react-icons/ri";
import { FaSquareXTwitter } from "react-icons/fa6";
import { FaYoutube, FaLinkedin } from "react-icons/fa";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
      <footer className="section_padding clip_top text_center">
        <div className="container">
          <img
            src={logo}
            width="162"
            height="33"
            alt="Logo: Boa Imagem - Marketing e Publicidade"
            title="Boa Imagem - Marketing e Publicidade"
            loading="lazy"
          />

          <h2>
            A sua Agência de <strong>Publicidade e Propaganda</strong>!
          </h2>
          <a
            className="btn"
            target="_blank"
            rel="nofollow noopener"
            title="Ligar para a Boa Imagem"
            href="https://wa.me/556139631444"
          >
            Entrar em contato
          </a>

          <div className="box_two_columns">
            <a
              rel="nofollow noopener"
              title="Mandar WhatsApp para a Boa Imagem"
              href="tel:6139631444"
            >
              <LuPhone />
              (61) 3963-1444
            </a>
            <a
              target="_blank"
              rel="nofollow noopener"
              title="Ligar para a Boa Imagem"
              href="https://wa.me/556139631444"
            >
              <IoLogoWhatsapp />
              (61) 3963-1444
            </a>
            <a
              target="_blank"
              rel="nofollow noopener"
              title="Mandar e-mail para a Boa Imagem"
              href="mailto:atendimento@boaimagem.tv"
            >
              <IoIosMail />
              atendimento@boaimagem.tv
            </a>
          </div>

          <a
            id="footerLocation"
            href="https://maps.app.goo.gl/qjHtcuezwtMegbdVA"
            target="_blank"
            rel="noopener"
          >
            <address>
              <h2>
                <strong>Brasília, Distrito Federal</strong>
              </h2>
              <p>
                SCS Quadra 2 Bloco C Lote 22
                <br />
                Edifício Serra Dourada - Sala 713
              </p>
            </address>
          </a>

          <div className="box_four_columns">
            <a
              target="_blank"
              rel="nofollow noopener"
              title="Ver Boa Imagem no Facebook"
              href="https://www.facebook.com/boaimagem"
            >
              <MdFacebook />
            </a>
            <a
              target="_blank"
              rel="nofollow noopener"
              title="Ver Boa Imagem no Instagram"
              href="https://www.instagram.com/boa_imagem"
            >
              <RiInstagramFill />
            </a>
            <a
              target="_blank"
              rel="nofollow noopener"
              title="Ver Boa Imagem no Twitter"
              href="https://twitter.com/BoaImagem"
            >
              <FaSquareXTwitter />
            </a>
            <a
              target="_blank"
              rel="nofollow noopener"
              title="Ver Boa Imagem no Youtube"
              href="https://www.youtube.com/user/Boalmagem"
            >
              <FaYoutube />
            </a>
            <a
              target="_blank"
              rel="nofollow noopener"
              title="Ver Boa Imagem no Linkedin"
              href="https://www.linkedin.com/company/boa-imagem"
            >
              <FaLinkedin />
            </a>
            <a
              target="_blank"
              rel="nofollow noopener"
              title="Ver as avaliações da Boa Imagem no Google"
              href="https://www.google.com/search?kgmid=/g/1tfg450g&hl=pt-BR&q=Boa+Imagem&shndl=30&shem=lsde&source=sh/x/loc/osrp/m5/1&kgs=6d31de7e66ad6bff#lrd=0x935a3af979bb16b1:0x5e292518eb1a7b0,1,,,,"
            >
              <IoIosStar />
            </a>
          </div>

          <div id="rights">
            <p>
              <Link to={"/politica-de-privacidade"}>
                Política de Privacidade
              </Link>
            </p>
            <p>Todos os direitos reservados &copy;</p>
            <p>Feito com excelência pela Boa Imagem&reg;</p>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
