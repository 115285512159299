import { useEffect, useState } from "react";
import { CasesInterface } from "../interfaces/CasesInterface";
import ReactPlayer from "react-player/lazy";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import { IoIosArrowForward, IoIosArrowBack } from "react-icons/io";
import { PaginationInterface } from "../interfaces/PaginationInterface";

const CasesCarousel = () => {
  const [cases, setCases] =
    useState<PaginationInterface<CasesInterface> | null>(null);

  useEffect(() => {
    const dataCases = async () => {
      try {
        const res = await fetch(
          "https://boaimagem.org/api/portfolios.php/?limit=4&category=cases&content_limit=200",
          {
            method: "GET",
            headers: {
              "Content-Type": "json/application",
            },
          }
        );
        const resCases = await res.json();
        setCases(resCases);
      } catch (err) {
        console.log(err);
      }
    };
    dataCases();
  }, []);

  const responsive = {
    568: { items: 1 },
    1024: { items: 3 },
  };

  let items: JSX.Element[] = [];
  if (cases?.data) {
    items = cases.data.map((item) => (
      <div className="item" data-value={item.id}>
        <img
          src={`/images/cases/${item.imagem.replace("jpg", "webp")}`}
          alt={item.post_title}
          width="450"
          height="241"
        />
        <ReactPlayer
          url={`https://www.youtube.com/watch?v=${item.video_id}`}
          light={`/images/cases/capa-video/${item.video_image_preview}`}
          width="342px"
          height="570px"
        />
        <p>{item.text.replace(/<\/?[^>]+(>|$)/g, "")}...</p>
      </div>
    ));
  }

  return (
    <>
      <div className="carousel_container">
        <AliceCarousel
          mouseTracking
          items={items}
          responsive={responsive}
          controlsStrategy="default"
          autoPlay={false}
          autoPlayInterval={1000}
          infinite={true}
          keyboardNavigation={true}
          renderPrevButton={() => {
            return (
              <button
                className="carousel_button prev-button"
                aria-label="Case anterior"
              >
                <IoIosArrowBack />
              </button>
            );
          }}
          renderNextButton={() => {
            return (
              <button
                className="carousel_button next-button"
                aria-label="Próximo case"
              >
                <IoIosArrowForward />
              </button>
            );
          }}
        />
      </div>
    </>
  );
};

export default CasesCarousel;
