import Breadcrumbs from "../../../layouts/Breadcrumbs";
import image from '../../../../assets/images/servicos/servico-google-street-view-trusted-2.webp';
import { useEffect } from "react";

const GoogleStreetViewTrusted = () => {

    useEffect(() => {
        document.title = "Google Street View Trusted - | Coloque a sua empresa no mapa!";
        const metaDescription = document.querySelector('meta[name="description"]');
        if (metaDescription) {
          metaDescription.setAttribute(
            "content",
            "Destaque-se como um profissional de confiança com o selo Trusted do Street View"
          );
        }
    }, []);

    const breadcrumbsItens = [
        {
            name: 'Serviços',
            alias: '/servicos'
        },{
            name: 'Online',
            alias: '/online'
        },{
            name: 'Google Street View Trusted',
            alias: '/google-street-view-trusted'
        }
    ];

    return (
        <>
            <Breadcrumbs title={'Google Street View Trusted'} items={breadcrumbsItens}/>
            <section id="servicesPage">
                <div className='relative bg_secundary section_padding_clip'>
                    <div className='container'>

                        <h2 className="title_divider mb-30">Aprenda tudo sobre o tour virtual 360º do google!</h2>

                        <img className="mb-30" src={image} alt="Foto: Serviço de Google Street View Trusted" title="Foto: Serviço de Google Street View Trusted" width="1024" height="640"/>

                        <p>O Google Street View Trusted é uma ferramenta desenvolvida pelo Google que disponibiliza uma visita interativa 360º pelo seu negócio, de qualquer lugar do mundo. Uma vez online, fica disponível 365 dias por ano, 24 horas por dia, para sempre. O serviço destaca sua empresa em buscas do Google, pois é integrado às pesquisas, Google Maps e ao Google+.</p>

                        <p>Esta é a oportunidade para conquistar novos clientes, enriquecer a listagem do seu negócio e aumentar o engajamento no site e mídias sociais da empresa. Ao acessar o tour virtual, o cliente passa por uma experiência única de aproximação à marca, acessando o Google Maps em computadores, tablets e celulares.</p>

                        <h5>INCORPORE FACILMENTE O STREET VIEW TRUSTED AO SEU SITE</h5>

                        <p>Do ponto de vista do SEO (otimização para motores de busca), a interatividade gera longas visitas às páginas e diminui a taxa de rejeição. Orientamos que o passeio seja incorporado tanto ao website da empresa quanto nas redes sociais.</p>

                        <p>Conheça também os nossos serviços de criação e alteração de Websites e Apps. O Google Street View Trusted é uma extensão do Google Maps Street View e é montado a partir de fotos panorâmicas especiais para visualização em 360 graus.</p>

                        <p>Não existe forma melhor de mostrar suas instalações para seus clientes em potencial, uma vez que é possível passear virtualmente dentro de sua empresa por todos os ambientes, sejam estes externos ou internos, sempre com visão em 360 graus.</p>

                        <h4>Agendamento</h4>
                        <p>Agende uma visita com a nossa equipe.</p>

                        <h4>Contrato</h4>
                        <p>Após todos os esclarecimentos, o contrato será assinado e o trabalho iniciado.</p>

                        <h4>Sessão de Fotos</h4>
                        <p>No dia e horário definido por você um fotógrafo irá captar as imagens da sua empresa.</p>

                        <h4>Montagem</h4>
                        <p>Em apenas alguns dias, sua visita virtual 360 graus estará disponível para veiculação.</p>

                        <h4>Publicação</h4>
                        <p>Seu passeio virtual será publicado no seu perfil do Google Meu Negócio.</p>

                        <h4>Pós-Venda</h4>
                        <p>Nossa equipe estará à disposição para ajudá-lo na implementação do Google Business View no seu site ou rede social.</p>

                        <h3>Serve para o meu negócio?</h3>
                        O Google Street View Trusted se adapta às necessidades de cada negócio – pode ser um hotel, restaurante, salão de beleza, loja de roupas, academias, entre muitos outros – e é mais uma opção para usuários explorarem novos lugares. O Google Street View Trusted é para qualquer tipo de negócio. Consulte um de nossos especialistas em caso de dúvidas.

                        <h3>Google street view trusted</h3>

                        <ol className="list">
                            <li>O Google Street View Trusted é um projeto novo no Brasil, capaz de gerar um tour virtual interativo de alta qualidade de 360 graus da empresa otimizado pela tecnologia do Street View.</li>
                            <li>Em cada região e segmento, as empresas pioneiras no programa ganham um destaque diferencial nas pesquisas entre as demais.</li>
                            <li>As sessões de fotos do Business View serão agendadas conforme a programação do seu estabelecimento.São projetadas para causar o mínimo impacto das suas operações.</li>
                            <li>Elas são concluídas geralmente em algumas horas. Depois da sessão, é só aguardar. Seu fotógrafo fará o upload das imagens em alta resolução no Google.</li>
                            <li>Todas as suas fotos do Business View serão exibidas no Google em algumas semanas.</li>
                            <li>O serviço, inclui outras fotografias profissionais que vão para a sessão de fotos na página do negócio no Google+. Essas fotos são de propriedade do negócio – o fotógrafo renuncia todos os direitos autorais. A empresa pode utilizar o material produzido para o Google Business View de modo irrestrito.</li>
                            <li>Dentro do programa de Business View, uma vez que a fotografia está completa e aprovada pelo cliente não há refilmagens obrigatórias. Também não há custos ou taxas de manutenção nem qualquer custo posterior ou taxa recorrente.</li>
                            <li>A fotografia é livre para ser utilizada em qualquer forma como o negócio escolhe. (Desde que a empresa adere aos termos de sua API do Google Maps do Google).</li>
                            <li>O Business View pode ser incorporado na página do Facebook ou empresas em seu site, ou qualquer domínio que suporta html iframe. Caso haja necessidade, fazemos também o serviço de criação, inclusão e adequação do website.</li>
                            <li>A contratação é simples e rápida, todos os termos do contrato são claros. Fazem parte da políticas de qualidade, privacidade e segurança do Google.</li>
                        </ol>

                        <h3>Sobre a ferramenta:</h3>

                        <ul className="list">
                            <li><b>Simples</b> Seus clientes vão adorar a experiência de conhecer sua empresa por dentro no passeio virtual do Google Maps Business View, e ainda mostrarão para outras pessoas. Tão fácil de usar quanto o Google Maps e o Street View.</li>
                            <li><b>Rápido</b> A produção do passeio virtual dentro do seu negócio é feita em poucas horas de trabalho. Em alguns dias você poderá promover ações para que outras pessoas também tenham a experiência de visitá-la.</li>
                            <li><b>Acessível</b> O serviço é pago apenas uma vez por sessão. Agende sem compromisso a visita de nossa equipe técnica. Descubra como ter o passeio virtual da sua empresa no Google Maps Business View.</li>
                        </ul>

                        <h4>1º Passo: CONTATO</h4>
                        <p>Ligue para nossa equipe ou preencha o formulário abaixo:</p>

                        <h4>2º Passo: AGENDAMENTO</h4>
                        <p>A sessão de fotos é rápida e não atrapalha o andamento da sua empresa.</p>

                        <h4>3º Passo: CONCLUSÃO</h4>
                        <p>Pronto, agora nossa equipe fica responsável pela criação do tour e disponibilização na web.</p>
                    </div>
                </div>
            </section>
        </>
    );
}

export default GoogleStreetViewTrusted;