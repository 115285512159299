import { useEffect } from "react";
import Breadcrumbs from "../../../../layouts/Breadcrumbs";

const MarketingIndireto = () => {

    useEffect(() => {
        document.title = "Definição do Marketing Indireto: Sua empresa sempre lembrada!";
        const metaDescription = document.querySelector('meta[name="description"]');
        if (metaDescription) {
          metaDescription.setAttribute(
            "content",
            "O mais importante para marcas e empresas é serem lembradas, para isso é preciso existir uma estratégia de marketing indireto"
          );
        }
    }, []);

    const breadcrumbsItens = [
        {
            name: 'Serviços',
            alias: '/servicos'
        },{
            name: 'Estratégias',
            alias: '/estrategias'
        },{
            name: 'Estratégias de marketing',
            alias: '/estrategias-de-marketing'
        },{
            name: 'Marketing Indireto',
            alias: '/marketing-indireto'
        }
    ];

    return (
        <>
            <Breadcrumbs title={'Marketing Indireto'} items={breadcrumbsItens}/>
            <section id="servicesInsidePage">
                <div className='relative bg_secundary section_padding_clip'>
                    <div className='container'>

                        <h2 className="title_divider mb-30">Marketing indireto: Faça a sua empresa ser lembrada!</h2>

                        <p>O mais importante para marcas e empresas é serem lembradas. Para isso é preciso existir uma estratégia de marketing, a mais indicada para serem vistas e não serem esquecidas é usando o marketing indireto.</p>

                        <h2>O que falaremos nesse post:</h2>

                        <ul className="list">
                            <li>Definição do Marketing Indireto</li>
                            <li>Como é pensando o Marketing Indireto</li>
                            <li>Marketing x Publicidade</li>
                            <li>Marketing Indireto X Marketing direto</li>
                            <li>O poder do Marketing Indireto</li>
                            <li>Proposta de valor</li>
                        </ul>

                        <h2>Definindo marketing indireto</h2>

                        <p>O marketing indireto tem várias maneiras de acontecer, ele pode ser obtido através de qualquer forma de divulgação de uma empresa. Ou seja, a sua divulgação não dependem diretamente dela ou das ações que ela toma.</p>

                        <p>Por exemplo, quando acontece o marketing boca a boca houve uma indicação. Porém, não foi a marca que fez com que isso acontecesse.</p>

                        <p>Sutileza define bem o marketing indireto, pois é uma forma de comunicar um produto ou marca sem que o espectador rejeite a mensagem, sem mudar de canal, por exemplo. Criatividade e boas sacadas são ideais na hora de implementar esse modelo de marketing.</p>

                        <p>Vamos conversar sobre alguns exemplos, comparações e a melhor forma de usar o marketing indireto. Porém já fica sabendo que ele pode influenciar as pessoas rapidamente e isso acaba sendo uma publicidade e tanto!</p>

                        <h2>Como é pensado o marketing indireto?</h2>

                        <p>Para entendermos melhor como o marketing é pensado e aplicado, vamos conhecer alguns tipos de estratégias. Com certeza, você já presenciou alguma delas.</p>

                        <p>Na grande maioria dos filmes é possível observar que em certas cenas os atores usam algum objeto de marcas que realmente existem, como computadores da Apple, celulares, produtos de higiene e objetos que fazem parte do nosso cotidiano e de marcas que podemos encontrar no mercado. Essa estratégia de marketing indireto é conhecida como merchandising.</p>

                        <p>É muito comum também nos depararmos com carros de marcas conhecidas em filmes, novelas e séries ou a mais forte e popular forma de marketing indireto, a divulgação de boca a boca.</p>

                        <p>Essa forma de apresentar a marca não torna a propaganda em algo agressivo e direto, muito pelo contrário torna algo sutil e que entra de forma leve no seu subconsciente.</p>

                        <h2>Marketing indireto X marketing direto</h2>

                        <p>Mas qual será a vantagem de investir no marketing indireto e não no direto?</p>

                        <p>A comparativa é simples, o marketing indireto faz com que a marca esteja na cabeça do consumidor mesmo quando ele não está pensando nela. Esse marketing é discreto e não tem como foco criar uma conexão com o público, o objetivo principal é apenas ser apresentado e lembrado.</p>

                        <p>O marketing direto é mais incisivo e literalmente direto. O público espera ofertas na hora do comercial de um supermercado e produtos na hora da propaganda de roupas, elas não precisam captar isso de forma leve, o objetivo desse modelo de marketing é fazer com que o público veja e consuma o que ele está vendo.</p>

                        <p>Infelizmente com o bombardeio de informações que temos diariamente fica difícil lembrar “daquela promoção” ou “daquele produto”. Dessa forma, a melhor maneira de apresentar isso é de forma sutil, pois assim a propaganda se instala na memória do consumidor.</p>

                        <h2>O poder do marketing indireto</h2>

                        <p>Agora que você já compreendeu o que é marketing indireto, se lembra de quantas vezes já viu marcas se apresentando de forma sutil por aí? Ou se lembra daquele restaurante que o seu amigo te indicou?</p>

                        <p>Essa estratégia é poderosa! A tática de aplicar a sua propaganda meio que “sem querer” funciona! O consumidor vai se lembrar, pode ser que isso não aconteça no mesmo dia, mas na hora que ele for pesquisar um serviço ou produto, ele se lembrará.</p>

                        <p>E é nesse momento que o seu site e empreendimento é visto e lembrado!</p>

                        <h2>Proposta de valor</h2>

                        <p>Vamos pensar o seguinte, cada empresa apresenta uma mensagem, que é o que as pessoas falam. É importante que as pessoas entendam o seu negócio de forma correta, certo?</p>

                        <p>Por exemplo, não basta alguém falar que o Walmart é um supermercado “legal” sendo que o diferencial deles é o tipo de produto ou a forma que e eles embalam.</p>

                        <p>Sempre que você tem a parte do marketing indireto ainda que seja boca a boca você precisa ter a <b>proposta de valor</b> muito bem definida, porque o importante é que as pessoas não só falem dessa empresa, mas que elas divulguem a sua proposta de valor de forma indireta, de forma orgânica.</p>

                        <p>A proposta da empresa precisa estar clara, de uma forma que as pessoas entendam e divulguem de forma certa e claro, se lembrem depois.</p>

                        <h3>E agora?</h3>

                        <p>Tenho certeza que depois desse post você ficou ansioso (a) para colocar a sua empresa em todos os cantos possíveis! Mas calma, existem inúmeras possibilidades para aplicar o marketing indireto.</p>

                        <p>Que tal buscar em agência de publicidade que é preparada para dar as melhores ideias para a sua empresa?</p>

                        <p>A Agência Boa Imagem pode te ajudar, entre em contato para agendarmos uma reunião! <b>Não deixe de comentar o que você achou do marketing indireto, vamos adorar saber a sua opinião!</b></p>

                    </div>
                </div>
            </section>
        </>
    )
}

export default MarketingIndireto;
