import React from "react";
import { Link } from "react-router-dom";
import { MdHome } from "react-icons/md";
import { IoIosArrowForward } from "react-icons/io";

interface BreadcrumbItem {
  name: string;
  alias: string;
}

interface BreadcrumbProps {
  title: string;
  items: BreadcrumbItem[];
}

const Breadcrumbs: React.FC<BreadcrumbProps> = ({ title, items }) => {
  let link = "";

  return (
    <div id="breadcrumb">
      <div className="container">
        <h1>{title}</h1>
        <nav aria-label="breadcrumb">
          <ol itemScope itemType="https://schema.org/BreadcrumbList">
            <li
              itemProp="itemListElement"
              itemScope
              itemType="https://schema.org/ListItem"
            >
              <Link to="/" itemProp="item" itemType="https://schema.org/Thing">
                <span itemProp="name">
                  <MdHome className="svg_home" />
                </span>
                <meta itemProp="name" content="Home" />
              </Link>
              <meta itemProp="position" content="1" />
              {items.length > 0 && <IoIosArrowForward />}
            </li>
            {items.map((item, index) => {
              link += item.alias;

              return (
                <li
                  key={index}
                  itemProp="itemListElement"
                  itemScope
                  itemType="https://schema.org/ListItem"
                >
                  <Link
                    to={link}
                    itemProp="item"
                    itemType="https://schema.org/Thing"
                    itemID={link} // Adiciona o ID da URL correta
                  >
                    <span itemProp="name">{item.name}</span>
                  </Link>
                  <meta itemProp="position" content={(index + 2).toString()} />
                  {index < items.length - 1 && <IoIosArrowForward />}
                </li>
              );
            })}
          </ol>
        </nav>
      </div>
    </div>
  );
};

export default Breadcrumbs;
