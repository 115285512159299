import Breadcrumbs from "../../../layouts/Breadcrumbs";
import image01 from '../../../../assets/images/servicos/display-01.webp';
import image02 from '../../../../assets/images/servicos/display-02.webp';
import image03 from '../../../../assets/images/servicos/display-03.webp';
import image04 from '../../../../assets/images/servicos/display-04.webp';
import image05 from '../../../../assets/images/servicos/display-05.webp';
import image06 from '../../../../assets/images/servicos/display-06.webp';
import image07 from '../../../../assets/images/servicos/display-07.webp';
import image08 from '../../../../assets/images/servicos/display-08.webp';
import image09 from '../../../../assets/images/servicos/display-09.webp';
import image10 from '../../../../assets/images/servicos/display-10.webp';
import image11 from '../../../../assets/images/servicos/display-11.webp';
import image12 from '../../../../assets/images/servicos/display-12.webp';
import image13 from '../../../../assets/images/servicos/display-13.webp';
import image14 from '../../../../assets/images/servicos/display-14.webp';
import image15 from '../../../../assets/images/servicos/display-15.webp';
import { useEffect } from "react";

const Display = () => {

    useEffect(() => {
        document.title = "Estrutura de Display para divulgação | Agência Boa Imagem";
        const metaDescription = document.querySelector('meta[name="description"]');
        if (metaDescription) {
          metaDescription.setAttribute(
            "content",
            "O uso do display trás muitos resultados, esse tipo de peça publicitária tem o objetivo fazer o consumidor realizar a compra do seu produto."
          );
        }
    }, []);

    const breadcrumbsItens = [
        {
            name: 'Serviços',
            alias: '/servicos'
        },{
            name: 'Offline',
            alias: '/offline'
        },{
            name: 'Display',
            alias: ''
        }
    ];

    return (
        <>
            <Breadcrumbs title={'Display'} items={breadcrumbsItens}/>
            <section id="servicesPage">
                <div className='relative bg_secundary section_padding_clip'>
                    <div className='container'>

                        <h2 className="title_divider mb-30">Estrutura de display</h2>

                        <p>As formas de publicidade por meio da estrutura de display são inúmeras. Esse tipo de peça publicitária tem como objetivo fazer o consumidor realizar a compra do seu produto. São colocados na altura dos olhos e normalmente possuem proporções diferentes, além disso são menores que o totem publicitário. Esse tipo de peça publicitária já possui um modelo e a grande maioria é produzida para que se acrescente apenas a impressão com o layout do produto que será exposto.</p>

                        <p>Na Agência Boa Imagem, temos a produção personalizada do layout da impressão para o seu display. Temos uma grande variedade de formas e tamanhos que podem trazer bons resultados para a divulgação do seu negócio. Essa ferramenta pode ser colocada em diversos lugares. Por exemplo, em eventos é possível divulgar através do display o seu produto posicionando-os em cima de balcões ou prateleiras.</p>

                        <h2 className="title_divider mb-30">Peça publicitária</h2>

                        <p>O display publicitário é um elemento de tamanho relativamente pequeno que se coloca sobre balcões, janelas, mesas ou prateleiras em um ponto de venda. Por causa do seu tamanho pequeno, ele não permite um conteúdo com extensas argumentações, o que remete a incorporação de uma imagem clara e sugestiva acompanhada de slogan ou da marca do produto. Buscar o auxilio de uma agência especializada nesse segmento pode ser crucial. Profissionais da área sabem como desenvolver chamadas breves e eficazes, assim as suas chances de atrair clientes é aumentar as vendas vão aumentar significativamente.</p>

                        <p>Esse tipo de material tem uma abordagem bem interessantes, pois o mesmo age como um vendedor silencioso. Os display podem ser confeccionados de diferentes materiais, lembrando sempre que a durabilidade é um dos fatores mais relevantes. Para a escolha dos materiais de confecção, podendo ser utilizadas misturas para a sua confecção que podem ser em:</p>

                        <ul className="list">
                            <li>Acrílico;</li>
                            <li>Vidro;</li>
                            <li>Ligas de metal;</li>
                            <li>Madeira;</li>
                            <li>Papel.</li>
                        </ul>

                        <p>Para obter a melhor solução em display entre em contato conosco!</p>

                        <h3>Modelos de display</h3>

                        <div className="box_three_columns_desktop">
                            <img className="text_center" src={image01} width="400" height="400" alt="Foto: Serviço de Display" title="Foto: Serviço de Display"/>
                            <img className="text_center" src={image02} width="500" height="375" alt="Foto: Serviço de Display" title="Foto: Serviço de Display"/>
                            <img className="text_center" src={image03} width="358" height="500" alt="Foto: Serviço de Display" title="Foto: Serviço de Display"/>
                            <img className="text_center" src={image04} width="500" height="417" alt="Foto: Serviço de Display" title="Foto: Serviço de Display"/>
                            <img className="text_center" src={image05} width="323" height="500" alt="Foto: Serviço de Display" title="Foto: Serviço de Display"/>
                            <img className="text_center" src={image06} width="333" height="500" alt="Foto: Serviço de Display" title="Foto: Serviço de Display"/>
                            <img className="text_center" src={image07} width="320" height="294" alt="Foto: Serviço de Display" title="Foto: Serviço de Display"/>
                            <img className="text_center" src={image08} width="500" height="500" alt="Foto: Serviço de Display" title="Foto: Serviço de Display"/>
                            <img className="text_center" src={image09} width="500" height="375" alt="Foto: Serviço de Display" title="Foto: Serviço de Display"/>
                            <img className="text_center" src={image10} width="500" height="425" alt="Foto: Serviço de Display" title="Foto: Serviço de Display"/>
                            <img className="text_center" src={image11} width="300" height="300" alt="Foto: Serviço de Display" title="Foto: Serviço de Display"/>
                            <img className="text_center" src={image12} width="500" height="333" alt="Foto: Serviço de Display" title="Foto: Serviço de Display"/>
                            <img className="text_center" src={image13} width="500" height="433" alt="Foto: Serviço de Display" title="Foto: Serviço de Display"/>
                            <img className="text_center" src={image14} width="500" height="447" alt="Foto: Serviço de Display" title="Foto: Serviço de Display"/>
                            <img className="text_center" src={image15} width="500" height="333" alt="Foto: Serviço de Display" title="Foto: Serviço de Display"/>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Display;