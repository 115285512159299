import { Outlet } from "react-router-dom";
import Header from "./components/layouts/Header";
import Footer from "./components/layouts/Footer";
import Navbar from "./components/layouts/Navbar";
import CanonicalMetaTag from "./components/helpers/CanonicalMetaTag";

function App() {
  return (
    <>
      <CanonicalMetaTag />
      <Header />
      <Navbar />
      <Outlet />
      <Footer />
    </>
  );
}

export default App;
