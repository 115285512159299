import React from "react";

interface FAQItem {
  question: string;
  answer: string;
}

interface CombinedFAQProps {
  items: FAQItem[];
}

const CombinedFAQ: React.FC<CombinedFAQProps> = ({ items }) => {
  const faqSchema = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    mainEntity: items.map((item) => ({
      "@type": "Question",
      name: item.question,
      acceptedAnswer: {
        "@type": "Answer",
        text: item.answer,
      },
    })),
  };

  return (
    <script type="application/ld+json">{JSON.stringify(faqSchema)}</script>
  );
};

export default CombinedFAQ;
