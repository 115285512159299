import { ServicesInterface } from "../../../interfaces/ServicesInterface";
import Breadcrumbs from "../../../layouts/Breadcrumbs";
import ServiceBox from "../../../layouts/ServiceBox";
import servico01 from '../../../../assets/images/servicos/servico-midias-sociais.webp';
import servico02 from '../../../../assets/images/servicos/servico-anuncios-patrocinados.webp';
import servico03 from '../../../../assets/images/servicos/servico-criacao-de-sites.webp';
import servico04 from '../../../../assets/images/servicos/servico-SEO–otimizacao.webp';
import servico05 from '../../../../assets/images/servicos/servico-monitoramento-de-marca.webp';
import servico06 from '../../../../assets/images/servicos/servico-automacao-de-marketing.webp';
import servico07 from '../../../../assets/images/servicos/servico-marketing-de-busca.webp';
import servico08 from '../../../../assets/images/servicos/servico-marketing-de-conteudo.webp';
import servico09 from '../../../../assets/images/servicos/servico-videos-e-animacoes.webp';
import servico10 from '../../../../assets/images/servicos/servico-google-street-view-trusted.webp';
import servico11 from '../../../../assets/images/servicos/servico-marketing-digital.webp';
import servico12 from '../../../../assets/images/servicos/servico-marketing-de-varejo.webp';
import { useEffect } from "react";

const Online = () => {

    useEffect(() => {
        document.title = "Serviços Online | Agência Boa Imagem";
        const metaDescription = document.querySelector('meta[name="description"]');
        if (metaDescription) {
          metaDescription.setAttribute(
            "content",
            "Existe uma vasta gama de ações de marketing à sua disposição. Conheça alguns deles e veja como podem ser úteis a sua empresa e gerar mais vendas."
          );
        }
    }, []);

    const breadcrumbsItens = [
        {
            name: 'Serviços',
            alias: '/servicos'
        },{
            name: 'Online',
            alias: '/online'
        }
    ];

    const services: ServicesInterface[] = [
        {
            title: 'Mídias sociais',
            text: 'São diversas soluções da Agência para Mídias Sociais, cada estratégia é criada de acordo com as metas, mercado e planos de cada cliente. Acompanhe este artigo para entender melhor a atuação da agência Boa Imagem nas redes sociais.',
            image: servico01,
            link: '/servicos/online/marketing-digital/midias-sociais',
        },{
            title: 'Anúncios patrocinados',
            text: 'Ofereça produtos e/ou serviços para quem busca! Este serviço geralmente é pago por cliques nos mecanismos de pesquisa e redes sociais como ferramenta de publicidade online.',
            image: servico02,
            link: '/servicos/online/marketing-digital/anuncios-patrocinados',
        },{
            title: 'Criação de sites',
            text: 'Somos uma empresa especializada na criação de websites responsivos, lojas virtuais, envio de email marketing, envio de sms marketing em ambiente corporativo e eleitoral.',
            image: servico03,
            link: '/servicos/online/marketing-digital/criacao-de-sites',
        },{
            title: 'SEO e Otimização',
            text: 'Para o site aparecer nas pesquisas do Google, são necessários conteúdos interessantes e únicos, levando o visitante a criar e visitar links voluntariamente.',
            image: servico04,
            link: '/servicos/online/marketing-digital/seo-otimizacao',
        },{
            title: 'Monitoramento de marca',
            text: 'É possível monitorar o que os usuários comentam a respeito da sua empresa, analisar as métricas de envolvimento do seu público e o nível de engajamento com suas postagens.',
            image: servico05,
            link: '/servicos/online/monitoramento-de-marca',
        },{
            title: 'Automação de marketing',
            text: 'A automação é uma forma dinâmica de comunicação, com chatbots e atendimento omnichannel integrados. A automação utiliza filtros de direcionamento e segmentação.',
            image: servico06,
            link: '/servicos/online/marketing-digital/automacao-de-marketing',
        },{
            title: 'Marketing de busca',
            text: 'Nós sabemos como aplicar as melhores estratégias dentro desse segmento. Cuidamos de todas as necessidades da sua empresa e garantimos bons resultados, conheça um pouco mais sobre Marketing de Busca.',
            image: servico07,
            link: '/servicos/estrategias/estrategias-de-marketing/marketing-de-busca/',
        },{
            title: 'Marketing de conteúdo',
            text: 'Hoje em dia as pessoas buscam coisas práticas e rápidas. Em buscas e sites online não seria diferente. Precisamos ter essa praticidade na hora de apresentar o conteúdo de uma empresa.',
            image: servico08,
            link: '/servicos/online/marketing-de-conteudo',
        },{
            title: 'Vídeos e animações',
            text: 'Produzimos vídeos comerciais e publicitários, além de atendermos demanda para novos espaços como youtube e novas mídias. No modelo de animação 2D e 3D a criação do conteúdo pode ser feito de maneira completa.',
            image: servico09,
            link: '/servicos/offline/videos',
        },{
            title: 'Google Maps e Street View',
            text: 'O Google Street View Trusted é uma ferramenta desenvolvida pelo Google que disponibiliza uma visita interativa 360º pelo seu negócio, de qualquer lugar do mundo.',
            image: servico10,
            link: '/servicos/online/google-street-view-trusted',
        },{
            title: 'Marketing Digital',
            text: 'Ainda que o Marketing Digital seja o termo errado, somos especialistas em disseminar informações com o intuito de influenciar a relaçao entre comprador e vendedor.',
            image: servico11,
            link: '/servicos/online/marketing-digital',
        },{
            title: 'Marketing de varejo',
            text: 'Nossa abordagem ao marketing de varejo é personalizada e baseada em dados, garantindo que cada campanha seja projetada para atender às necessidades específicas do seu negócio.',
            image: servico12,
            link: '/servicos/online/marketing-de-varejo',
        }
    ];

    return (
        <>
            <Breadcrumbs title={'Serviços Online'} items={breadcrumbsItens}/>
            <section id="servicesPage"  className='box_divider relative bg_secundary section_padding_clip text_center'>
                <div className='container'>
                    <ServiceBox services={services} />
                </div>
            </section>      
        </>
    );
}

export default Online;