import { ServicesInterface } from "../../../interfaces/ServicesInterface";
import Breadcrumbs from "../../../layouts/Breadcrumbs";
import ServiceBox from "../../../layouts/ServiceBox";
import servico01 from '../../../../assets/images/servicos/servico-design-de-produto.webp';
import servico02 from '../../../../assets/images/servicos/servico-fotografia-publicitaria.webp';
import servico03 from '../../../../assets/images/servicos/servico-videos-e-animacoes.webp';
import servico04 from '../../../../assets/images/servicos/servico-propaganda-de-televisao.webp';
import servico05 from '../../../../assets/images/servicos/servico-propaganda-em-radios.webp';
import servico06 from '../../../../assets/images/servicos/servico-propaganda-em-revistas.webp';
import { useEffect } from "react";

const Videos = () => {

    const services: ServicesInterface[] = [
        {
            title: 'Design de produto',
            text: 'Estamos preparados para desenvolver a criação do design de produto, peças publicitárias e outros modelos que podem fazer parte da necessidade da sua empresa.',
            image: servico01,
            link: '/servicos/offline/design',
        },{
            title: 'Fotografia publicitária',
            text: 'A fotografia publicitária é um serviço diferenciado, não é apenas uma foto. Com uma boa composição e com um projeto bem elaborado, o resultado com certeza irá satisfazer o cliente.',
            image: servico02,
            link: '/servicos/offline/fotografia-publicitaria',
        },{
            title: 'Vídeos e animações',
            text: 'Produzimos vídeos comerciais e publicitários, além de atendermos demanda para novos espaços como youtube e novas mídias. No modelo de animação 2D e 3D a criação do conteúdo pode ser feito de maneira completa.',
            image: servico03,
            link: '/servicos/offline/videos',
        },{
            title: 'Propaganda de televisão',
            text: 'As telinhas impactam milhões de brasileiros todos os dias, a mídia televisiva influencia a formação de opinião, apresentando aspectos de modernidade que causam transformações no sistema econômico.',
            image: servico04,
            link: '/servicos/offline/propaganda-de-televisao',
        },{
            title: 'Propaganda em rádios',
            text: 'As rádios tem o seu público cativo, um veículo de divulgação muito procurado pelos anunciantes pois pode ele oferece um baixo custo. Ou seja, para produzir e vincular o valor é mais em conta.',
            image: servico05,
            link: '/servicos/offline/propaganda-de-radio',
        },{
            title: 'Propaganda em revistas',
            text: 'A propaganda de revista possibilita o melhor tempo de leitura. Quando determinada pessoa está lendo uma revista, a atenção está voltada para as páginas, assim as chances de interferência nos anúncios é mínimo.',
            image: servico06,
            link: '/servicos/offline/propaganda-de-revista',
        }
    ];

    useEffect(() => {
        document.title = "Produção de vídeo | Agência Boa Imagem";
        const metaDescription = document.querySelector('meta[name="description"]');
        if (metaDescription) {
          metaDescription.setAttribute(
            "content",
            "O vídeo é uma ferramenta de comunicação que, por si só, possui valor agregado. Está é uma das principais tendências da atualidade. Saiba o que é preciso na produção de vídeo para campanhas publicitárias."
          );
        }
    }, []);

    const breadcrumbsItens = [
        {
            name: 'Serviços',
            alias: '/servicos'
        },{
            name: 'Offline',
            alias: '/offline'
        },{
            name: 'Vídeos e animações',
            alias: '/videos'
        }
    ];

    return (
        <>
            <Breadcrumbs title={'Produção de Vídeo profissional para Empresas e Campanhas Publicitárias'} items={breadcrumbsItens}/>
            <section id="servicesPage">
                <div className='relative bg_secundary section_padding_clip'>
                    <div className='container'>

                        <h2 className="title_divider mb-30">Entenda como usar estratégias com vídeos para impulsionar sua marca e aumentar a sua presença em diferentes mídias:</h2>

                        <p>Com os avanços tecnológicos, as necessidades publicitárias das empresas aumentaram rapidamente nos últimos anos. O espaço para exposição aumentou e, consequentemente, a mídia pede mais presença online e manifestações para cada área de atuação ou público-alvo relacionado com as marcas que desejam concorrer no mercado.</p>

                        <p>No mundo atual, onde a atenção das pessoas é disputada em múltiplas plataformas e canais, é essencial que as empresas encontrem maneiras criativas e eficazes de se conectar com seu público-alvo. Uma das estratégias de marketing mais poderosas disponíveis hoje é a produção de vídeos. Com os serviços profissionais de produção de vídeos, você pode transmitir sua mensagem de forma envolvente, persuasiva e altamente visual, alcançando resultados extraordinários para sua empresa.</p>

                        <p>As produções comerciais podem ter metodologia orgânica de conteúdo ou ainda, podem fazer parte de grandes campanhas com a necessidade de buscar ser cada vez mais perfeitas, por outro lado, o público está cada vez mais receptivo a cada uma delas. Por meio de vídeos você pode apresentar suas ambições, ideias, necessidades, expor um produto ou uma marca e conquistar o público com fascinantes propagandas.</p>

                        <p>Atualmente existem muitas possibilidades de viralizar e alcançar milhões ou até bilhões de pessoas, abrindo as portas do seu empreendimento para o mundo e fazendo com que cada pessoa tenha um espaço para agregar no seu negócio.</p> 

                        <h2 className="title_divider mb-30">Os benefícios dos vídeos para sua empresa:</h2>
                        
                        <h3><strong>Aumente o engajamento do público:</strong></h3>
                        <p>Os vídeos têm a capacidade de cativar e prender a atenção do espectador de uma maneira que outros formatos de conteúdo não conseguem. Com a produção de vídeos bem planejada e executada, você pode envolver seu público-alvo, transmitir sua mensagem com clareza e criar uma conexão emocional mais forte com seus clientes em potencial.</p>


                        <h3><strong>Fortaleça a confiança do cliente:</strong></h3>
                        <p>Os vídeos permitem que você demonstre sua expertise, mostre seus produtos ou serviços em ação e forneça depoimentos de clientes satisfeitos. Isso ajuda a estabelecer confiança e credibilidade junto ao seu público-alvo, o que é fundamental para impulsionar as conversões e o crescimento dos negócios.</p>

                        <p>A produção de vídeos com o roteiro certo, pensados para atingir os objetivos de comunicação da sua empresa é uma forma poderosa de reforçar a identidade visual e criar canais de comunicação consistentes para sua marca. Ao utilizar elementos visuais e narrativas atraentes, você pode fortalecer o reconhecimento da sua marca, transmitir seus valores e diferenciar-se da concorrência.</p>

                        <h3><strong>Aumente o tráfego e a classificação nos mecanismos de busca:</strong></h3>
                        <p>Os mecanismos de busca, como o Google, dão cada vez mais importância ao conteúdo em vídeo. Ao incorporar vídeos em seu site e em suas estratégias de marketing, você pode aumentar sua visibilidade online e melhorar seu ranking nos resultados de pesquisa. Além disso, os vídeos têm maior probabilidade de serem compartilhados nas redes sociais, o que pode ampliar ainda mais seu alcance.</p>

                        <p>A <a href="https://bigood.com.br/" target="_blank" rel="noopener">BI GOOD</a>, produtora parceira da Boa Imagem, oferece serviços que superam as expectativas. Existem muitas opções, desde salas com Chroma Key para produção com fundo falso, até painéis de led ou locações de gravação de alto padrão que podem inclusive envolver efeitos especiais e muito mais, a imaginação é o limite!</p>

                    </div>

                </div>
            </section>

            <section id="servicesInsidePageOthers"  className='box_divider relative bg_secundary section_padding_clip text_center'>
                <div className='container'>
                    <ServiceBox services={services} />
                </div>
            </section>
        </>
    );
}

export default Videos;