import { useEffect } from "react";
import Breadcrumbs from "../../../../layouts/Breadcrumbs";

const AnunciosPatrocinados = () => {

    useEffect(() => {
        document.title = "Links Patrocinados | Indispensável para suaPresença Online";
        const metaDescription = document.querySelector('meta[name="description"]');
        if (metaDescription) {
          metaDescription.setAttribute(
            "content",
            "Tire as suas dúvidas sobre Links Patrocinados através da Boa Imagem em Brasília, a agência de Publicidade, Propaganda e Marketing em Brasília expecializada em Estratégias de Marketing."
          );
        }
    }, []);

    const breadcrumbsItens = [
        {
            name: 'Serviços',
            alias: '/servicos'
        },{
            name: 'Online',
            alias: '/online'
        },{
            name: 'Marketing Digital',
            alias: '/marketing-digital'
        },{
            name: 'Anúncios Patrocinados',
            alias: '/anuncios-patrocinados'
        }
    ];

    return (
        <>
            <Breadcrumbs title={'Anúncios Patrocinados'} items={breadcrumbsItens}/>
            <section id="servicesPage">
                <div className='relative bg_secundary section_padding_clip'>
                    <div className='container padding'>

                        <h2 className="title_divider mb-30">Anúncios</h2>

                        <p>Para apresentar soluções a seus clientes de forma simples e objetiva, a Boa Imagem consolidou-se como agência Full Service. Conta com equipes especializadas na produção de anúncios para mídia impressa, radiofônica, televisiva e tradicional. Trabalha com o desenvolvimento de sites, estratégias de marketing, campanhas publicitárias, ilustrações, animações, modelagem em 3D, assessoria de imprensa e promoção de eventos.</p>

                        <p>Proporciona também apoio na produção gráfica, desde pequenos materiais até grandes formatos, e desenvolve sites E-commerce, gerenciamento de conteúdos, marketing digital, hospedagem, SEM (Search Engine Marketing), publicidade online e integração com redes sociais, anúncios no Google e conteúdos interativos para veiculação na Internet.</p>

                        <p>A Boa Imagem presta ainda suporte e atendimento a outras agências, equipes de marketing, produtoras de vídeos, fotógrafos e parceiros, tornando-se ponto de convergência entre empresas desse segmento.</p>

                        <ul className="list">
                            <li>
                                <h4>Aumente as visitas a seu site!</h4>
                            </li>
                            <li>
                                <h4>Aumente sua visibilidade na web</h4>
                            </li>
                            <li>
                                <h4>Faça anúncios direcionados</h4>
                            </li>
                            <li>
                                <h4>90% dos brasileiros buscam pelo Google</h4>
                            </li>
                            <li>
                                <h4>Ofereça produtos e/ou serviços para quem busca</h4>
                            </li>
                        </ul>

                        <p>Ter presença online hoje em dia é indispensável. Possuir links patrocinados pode ser uma boa forma de gerar um bom tráfego para o site da sua empresa. Ter uma empresa com profissionais que entendam das melhores ferramentas para ajudar o seu empreendimento faz toda diferença.</p>

                        <p><b>O que é Google AdWords?</b></p>

                        <p>É um serviço fornecido pelo maior mecanismo de pesquisa da Web como ferramenta de publicidade online. Possibilita gerar tráfego qualificado para o seu site, por meio de anúncios associados a palavras-chave para otimizar as buscas feitas na internet. Também é possível fazer os links patrocinados em vídeo, banners animados e atingir outros sites parceiros do Google, que publicam os anúncios do Google Adwords.</p>

                        <p><b>Características dos anúncios</b></p>

                        <ul className="list">
                            <li>Criação / Configuração de conta / Monitoramento e Otimização;</li>
                            <li>Relatório desempenho da(s) campanha(s);</li>
                            <li>Horário de exibição da(s) campanha(s) definido pelo cliente;</li>
                            <li>Suporte especializado**;</li>
                            <li>Sem prazo mínimo de contrato.</li>
                        </ul>

                        <p><b>Como funciona</b></p>

                        <p>Os planos são mensais, mas recomendamos o uso mínimo de seis meses. Os anúncios começam a ser exibidos nas buscas do Google e dentro de 15 a 30 dias é possível ter métricas com resultados concretos. As campanhas são usadas para organizar a estratégia de divulgação do negócio, destino, idioma e segmentação por localização entre outras configurações.</p>

                        <p>Todos os links patrocinados feitos no Google AdWords são cobrados somente quando há um clique no link direcionado a seu site. O custo do clique varia conforme o nicho de cada negócio e concorrência da palavra-chave, por isso, o CPC só é feito após a publicação da campanha.</p>
                        <p>Entre em contato com a Agência de Publicidade Boa Imagem e conheça mais do nosso atendimento de links patrocinados.</p>

                    </div>
                </div>
            </section>
        </>
    );
}

export default AnunciosPatrocinados;