import Breadcrumbs from "../../../layouts/Breadcrumbs";
import image from '../../../../assets/images/servicos/servico-propaganda-de-radio.webp';
import { useEffect } from "react";

const PropagandaRadio = () => {

    useEffect(() => {
        document.title = "Propaganda de Rádio: Vantagens desse veículo | Boa Imagem";
        const metaDescription = document.querySelector('meta[name="description"]');
        if (metaDescription) {
          metaDescription.setAttribute(
            "content",
            "O rádio é um veículo de comunicação forte, que atinge um grande número de pessoas. Se bem aplicada como veículo de divulgação pode trazer muitos resultados."
          );
        }
    }, []);

    const breadcrumbsItens = [
        {
            name: 'Serviços',
            alias: '/servicos'
        },{
            name: 'Offline',
            alias: '/offline'
        },{
            name: 'Propaganda de Rádio',
            alias: '/propaganda-de-radio'
        }
    ];

    return (
        <>
            <Breadcrumbs title={'Propaganda de Rádio'} items={breadcrumbsItens}/>
            <section id="servicesPage">
                <div className='relative bg_secundary section_padding_clip'>
                    <div className='container'>

                        <p>A propaganda possui grande poder de influência, além disso é um meio poderoso para as empresas e para o país.</p>

                        <p>As propagandas possuem uma força brutal de influência, especialistas dizem que esse veículo pode ser um grande responsável pela mudança da nossa sociedade. Veículos de comunicação como televisão, revistas e jornais conseguem por si só construir uma sustentação usando a propaganda. Após a criação da TV o desejo de comprar coisas aumentou e isso consequentemente aumentou os gastos e influenciou diversas áreas. E é exatamente essa a intenção de todas as produções, frases criativas e spots que você escuta e vê assim que liga o rádio ou sai na rua.</p>

                        <p>Esse veículo leva a informação para o telespectador ou para o leitor de forma direta. Por isso sempre estamos em contato com chamadas interessantes como, spots de cursos com preços baixos e produtos atuais com bons preços. Não seria interessante para os fornecedores oferecerem coisas antigas ou passadas, o objetivo é vender e colocar as novidades nas mãos das pessoas.</p>

                        <p>Sabemos que a propaganda é uma ótima forma de fornecer informação e desenvolver o crescimento de um empreendimento. Estamos sempre observando a receptividade dos ouvintes, leitores e telespectadores, pois eles são os guiadores do que é produzido. Sabemos também que nossa vida é totalmente influenciada pela mídia e seus veículos.</p>

                        <p>Usar essa ferramenta de forma criativa e positiva, pode ser primordial para um bom relacionamento da sua empresa com o seu cliente. A televisão, rádio e as revistas ainda são poderosas fontes de informação. Conheça mais sobre a Propaganda de Rádio e em como ela pode ajudar a sua empresa.</p>

                        <h2 className="title_divider mb-30">Propaganda de Rádio</h2>

                        <img className="text_center mb-30" src={image} alt="Foto: Serviço de Propaganda de Rádio" title="Foto: Serviço de Propaganda de Rádio" width="520" height="347"/>

                        <p>odas as propagandas são muito válidas, porém cada uma oferece um tipo retorno. Tudo vai depender da necessidade da sua empresa. O rádio é um veículo de comunicação forte, porém não são todas as pessoa que o escutam. Esse veículo de divulgação é muito procurado pelos anunciantes pois pode ele oferece um baixo custo. Ou seja, para produzir e vincular o valor é mais em conta. Lembrando que essa propaganda só necessita de um bom roteiro pois apenas o áudio será transmitido.

                        </p><p>Por ser uma propaganda que as pessoas irão apenas escutar, a vinculação na rádio deve ser feita repetidas vezes. O público precisa fixar a informação. Diferente da TV que prende a atenção do telespectador, a propaganda na rádio precisa ser lembrada.</p>

                        <h2 className="title_divider mb-30">Por que escolher esse veículo de propaganda de rádio?</h2>

                        <p>A propaganda de TV tem um receptividade e aceitação maior, pois a mesma contém imagens e vídeos que são pensados de forma completa para prender a atenção do telespectador, é um bom veículo de informações e mesmo valendo a pena os custos para a criação da propaganda de televisão são bem alto.</p>

                        <p>Para optar pelo rádio como meio de divulgação é importante que a sua empresa tenha uma analise completa, sobre quem escuta, horários e vantagens. Uma agência de publicidade que entende do serviço, pode te ajudar.</p>

                        <p>Saber aplicar em spots o segmento da sua empresa é essencial. Existe, inúmeras formas de expandir o seu negócio através de propagandas. Todas elas são capazes de transmitir as informações e conquistar possíveis leads.</p>

                        <p>A Boa Imagem, sabe a melhor forma de inserir o seu negocio na rádio, sabemos estudar o seu empreendimento e oferecemos as melhores saídas de divulgação. Conheça sobre o nosso trabalho em Propaganda de Televisão. Entre em contato conosco para que juntos possamos definir as melhores estratégias de divulgação.</p>

                    </div>
                </div>
            </section>
        </>
    );
}

export default PropagandaRadio;