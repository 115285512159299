import { useEffect, useState } from "react";
import { PostInterface } from "../interfaces/PostInterface";
import { useNavigate, useParams } from "react-router-dom";
import Breadcrumbs from "../layouts/Breadcrumbs";

const Post = () => {
  const [post, setPost] = useState<PostInterface | null>(null);
  const { alias } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const data = async () => {
      try {
        const res = await fetch(
          `https://boaimagem.org/api/posts.php/?alias=${alias}`,
          {
            headers: {
              "Content-Type": "application/json",
            },
            method: "get",
          }
        );
        const data = await res.json();
        if (data === null) {
          navigate("/blog");
        } else {
          setPost(data);
          document.title = `${data?.post_title} | Boa Imagem`;
          const metaDescription = document.querySelector(
            'meta[name="description"]'
          );
          if (metaDescription) {
            metaDescription.setAttribute(
              "content",
              `${data?.text
                .replace(/<\/?[^>]+(>|$)/g, "")
                .substring(0, 150)}...`
            );
          }
        }
      } catch (err) {
        console.log(err);
      }
    };
    data();
  }, [alias]);

  const breadcrumbsItens = [
    {
      name: "Blog",
      alias: "/blog",
    },
    {
      name: post?.post_title ?? "",
      alias: "/" + post?.post_name ?? "",
    },
  ];

  return (
    <>
      {post?.post_name && post?.post_title && (
        <Breadcrumbs title={"Blog"} items={breadcrumbsItens} />
      )}
      <section id="blogPagePost">
        <div className="relative bg_secundary section_padding_clip text_center">
          <div className="container padding">
            {post != null && (
              <>
                <h2>{post.post_title}</h2>
                <div className="img_featured">
                  <picture className="imgMain">
                    <source
                      media="(max-width: 768px)"
                      srcSet={`https://boaimagem.org/images/noticias-webp/${post.imagem.replace(
                        /\.(png|jpg)$/,
                        "-thumb.webp"
                      )}`}
                    />
                    <img
                      width="400"
                      height="185"
                      src={`https://boaimagem.org/images/noticias-webp/${post.imagem.replace(
                        /\.(png|jpg)$/,
                        ".webp"
                      )}`}
                      alt={post.post_title}
                      title={post.post_title}
                    />
                  </picture>
                </div>
                <div
                  className="text"
                  dangerouslySetInnerHTML={{ __html: post.text }}
                ></div>
              </>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default Post;
