import Breadcrumbs from "../layouts/Breadcrumbs";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import image from "../../assets/images/homer-computer-doh-404.webp";

const NotFound = () => {
  useEffect(() => {
    document.title = "404 - Página Não Encontrada | Agência Boa Imagem";
    const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
      metaDescription.setAttribute(
        "content",
        "Desculpe, a página que você tentou acessar não existe ou foi movida, tente navegar pelo menu ou entre em contato conosco."
      );
    }
  }, []);

  const breadcrumbsItens = [
    {
      name: "404 - Página Não Encontrada",
      alias: "",
    },
  ];

  return (
    <>
      <Breadcrumbs
        title="404 - Página Não Encontrada"
        items={breadcrumbsItens}
      />
      <section id="notFoundPage">
        <div className="relative bg_secundary section_padding_clip text_center">
          <div className="container">
            <div className="box_two_columns_desktop">
              <img
                className="mb-30"
                width="788px"
                height="500px"
                src={image}
                alt="Erro 404: homer Computador Doh"
              />
              <p>
                A página que você tentou acessar não existe ou foi movida.{" "}
                <br />
                <strong>Continue navegando:</strong>
                <div className="box_two_columns_desktop">
                  <Link className="btn btn-center mt-30" to={"/"}>
                    Home{" "}
                  </Link>{" "}
                  <Link className="btn btn-center mt-30" to={"/contato"}>
                    Fale Conosco
                  </Link>
                </div>
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default NotFound;
