import Breadcrumbs from "../layouts/Breadcrumbs";
import { IoLogoWhatsapp } from "react-icons/io";
import { LuPhone } from "react-icons/lu";
import { IoIosMail } from "react-icons/io";
import { Link } from "react-router-dom";
import { useEffect } from "react";

const Contato = () => {
  useEffect(() => {
    document.title = "Telefone e E-mail de contato | Agência Boa Imagem";
    const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
      metaDescription.setAttribute(
        "content",
        "Endereço, Whatsapp, Telefone e E-mail da agência Boa Imagem em Brasília. Fale com um de nossos consultores de marketing."
      );
    }
  }, []);

  const breadcrumbsItens = [
    {
      name: "Contato",
      alias: "/contato",
    },
  ];

  return (
    <>
      <Breadcrumbs title="Contato" items={breadcrumbsItens} />
      <section id="contatoPage">
        <div
          id="contatcsInfos"
          className="relative bg_secundary section_padding_clip text_center"
        >
          <div className="container">
            <p>
              O Propósito da agência é ajudar outras Empresas e nosso objetivo é
              usar o marketing para vender, garantindo assim que as pessoas
              certas vejam o que você oferece, demonstrando desde o primeiro
              momento Boa Imagem aos seus futuros clientes.
            </p>
          </div>

          <div className="box_two_columns container">
            <a
              className="btn"
              rel="nofollow noopener"
              title="Mandar WhatsApp para a Boa Imagem"
              href="tel:6139631444"
            >
              <LuPhone />
              (61) 3963-1444
            </a>
            <a
              className="btn"
              target="_blank"
              rel="nofollow noopener"
              title="Ligar para a Boa Imagem"
              href="https://wa.me/556139631444"
            >
              <IoLogoWhatsapp />
              (61) 3963-1444
            </a>
            <a
              className="btn"
              target="_blank"
              rel="nofollow noopener"
              title="Mandar e-mail para a Boa Imagem"
              href="mailto:atendimento@boaimagem.tv"
            >
              <IoIosMail />
              atendimento@boaimagem.tv
            </a>
          </div>

          <div className="container">
            <div className="mt-30" style={{ width: "100%", height: "300px" }}>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3839.2910163440747!2d-47.88697492395044!3d-15.788601822814414!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x935a3af979bb16b1%3A0x5e292518eb1a7b0!2sBoa%20Imagem!5e0!3m2!1spt-BR!2sbr!4v1727299195975!5m2!1spt-BR!2sbr"
                width="100%"
                height="100%"
                style={{ border: 0 }}
                allowFullScreen={true}
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
                title="Localização da Boa Imagem"
              />
            </div>
          </div>
        </div>

        <div
          id="workWithUs"
          className="relative bg_black section_padding_clip clip_top text_center"
        >
          <div className="container">
            <h2>Faça a diferença:</h2>
            <p>
              Participe da equipe
              <br /> multidisciplinar
              <br /> da Agência
              <br /> Boa Imagem.
            </p>
            <Link to="/jobs-vagas-oportunidades" className="btn">
              Trabalhe conosco
            </Link>
          </div>
        </div>
      </section>
    </>
  );
};

export default Contato;
