import Breadcrumbs from "../../../layouts/Breadcrumbs";
import image from '../../../../assets/images/servicos/servico-marketing-de-conteudo-2.webp';
import { useEffect } from "react";

const MarketingConteudo = () => {

    useEffect(() => {
        document.title = "Inbound: o Marketing de Conteúdo | Agência Boa Imagem";
        const metaDescription = document.querySelector('meta[name="description"]');
        if (metaDescription) {
          metaDescription.setAttribute(
            "content",
            "O marketing de conteúdo é uma das estratégias mais antigas do marketing como um todo. É a maneira de engajar com seu público-alvo e crescer sua rede de clientes através da criação de conteúdo relevante."
          );
        }
    }, []);
    
    const breadcrumbsItens = [
        {
            name: 'Serviços',
            alias: '/servicos'
        },{
            name: 'Online',
            alias: '/online'
        },{
            name: 'Marketing de Conteúdo',
            alias: '/marketing-de-conteudo'
        }
    ];

    return (
        <>
            <Breadcrumbs title={'Marketing de Conteúdo'} items={breadcrumbsItens}/>
            <section id="servicesPage">
                <div className='relative bg_secundary section_padding_clip'>
                    <div className='container'>
                                                
                        <h2>Qualidade no seu conteúdo: Como atingir?</h2>

                        <h2>Link building &amp; conteúdo</h2>

                        <p>Não basta apenas criar um site, temos que adequá-lo às necessidades da empresa e cliente.</p>

                        <p>Atualmente sabemos a importância de possuir um espaço na mídia e um website. É um dos meios mais usados por empresas que querem de alguma forma oferecer seu serviço no mundo online.</p>

                        <p>Mas você sabia que é preciso um cuidado específico para o uso do website?</p>

                        <p>Suponhamos que você vá oferecer um serviço de buffet de festas e no seu website os leitores acabam encontrando apenas fotos dos eventos realizados. Nesse caso, quem tiver interesse em contratar o seu buffet não saberá exatamente quais serviços você presta.</p>

                        <p>Por isso é essencial que o website seja informativo, dinâmico e que ajude os seus futuros consumidores a entender o melhor que você pode oferecer. Nesse post vamos tratar de tópicos que com certeza te ajudarão a encontrar o modelo de website ideal para a sua empresa:</p>

                        <ul className="list">
                            <li>Devo aprimorar o meu website?</li>
                            <li>Técnicas de escrita</li>
                            <li>Atingindo o meu futuro cliente</li>
                            <li>Quem pode me ajudar?</li>
                        </ul>

                        <h2 className="text_center">Mais que aprimorar</h2>

                        <img className="mb-30" src={image} alt="Foto: Serviço de Marketing de Conteúdo" title="Foto: Serviço de Marketing de Conteúdo" width="520" height="347"/>

                        <p>As formas de aprimoramento são inúmeras, podemos mudar o layout, o design e o texto, porém se as informações não forem bem elaboradas o resultado de buscas e até vendas online não será satisfatório.</p>

                        <p>É preciso dinamizar cada vez mais o contato com o público e isso acontece através de boas estratégias nas áreas de desenvolvimento de site e consultorias em recursos da web.</p>

                        <h2>O que é necessário para um site de sucesso?</h2>

                        <p>Além de boas estratégias, técnicas de escrita agregam valor para a web que será disponibilizada para o público.</p>

                        <p>Empresas que conhecem a melhor forma de usar copywriting, por exemplo, podem produzir conteúdos atraentes que prendam a atenção dos seus futuros clientes e que os levem a compras e conhecer as suas novidades e serviços.</p>

                        <p>Ter o acompanhamento de uma agência, pode aumentar em 100% as chances de expandir o seu website, pois um olhar profissional traz novas perspectivas para o que você procura e isso significa um grande número de valores que serão agregados e assim sua empresa sairá na frente, nas mídias.</p>

                        <h2>Como atingir o seu público ideal?</h2>

                        <p>Hoje em dia as pessoas buscam coisas práticas e rápidas. Em buscas e sites online não seria diferente. Precisamos ter essa praticidade na hora de apresentar o conteúdo de uma empresa, pois tudo o que será visto no website precisará trazer informações de uma forma completa e rápida.</p>

                        <p>Além disso é necessário um estudo sobre qual público você quer atingir, isso inclui faixa etária, gostos e costumes. Depois, é preciso escolher a melhor mídia social para se usar, o vocabulário ideal para o seu site e assim sua empresa estará pronta para abordar e satisfazer o seu público.</p>

                        <h2>Quem pode me ajudar?</h2>

                        <p>A Agência Boa Imagem é especializada no segmento de criação e aprimoramento de websites. Reformulamos sites inovando em estilo, qualidade e funcionalidade com qualquer sistema de pesquisa regional ou mundial.</p>

                        <p>Busque sempre os melhores profissionais do segmento para a criação ou aprimoramento do seu website. Sabemos da importância de um serviço com qualidade e a diferença que ele faz na sua empresa.</p>

                        <p><b>Não deixe de visitar a nossa página no <a href="https://www.facebook.com/boaimagem/" target="_blank" rel="noopener">Facebook!</a> Por lá você fica por dentro de todos os nossos serviços e dicas que podem ajudar a sua empresa!</b></p>

                    </div>
                </div>
            </section>
        </>
    );
}

export default MarketingConteudo;