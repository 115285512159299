import { useEffect, useState } from "react";
import Breadcrumbs from "../layouts/Breadcrumbs";
import { CasesInterface } from "../interfaces/CasesInterface";
import ReactPlayer from "react-player";
import { PaginationInterface } from "../interfaces/PaginationInterface";
import { Link, useSearchParams } from "react-router-dom";
import Pagination from "../layouts/Pagination";

const Portfolios = () => {
  const [searchParams] = useSearchParams();
  const [portfolios, setPortfolios] =
    useState<PaginationInterface<CasesInterface> | null>(null);
  const page = searchParams.get("page") ?? 1;

  useEffect(() => {
    const portfolios = async () => {
      try {
        const res = await fetch(
          `https://boaimagem.org/api/portfolios.php/?limit=6&category=cases&content_limit=200&page=${page}`,
          {
            method: "get",
            headers: { "Content-Type": "json/application" },
          }
        );
        const dataRes = await res.json();
        setPortfolios(dataRes);
      } catch (err) {
        console.log(err);
      }
    };
    portfolios();

    if (page === 1) {
      document.title = "Portifólio | Conheça o conteúdo e acesse os cases";
      const metaDescription = document.querySelector(
        'meta[name="description"]'
      );
      if (metaDescription) {
        metaDescription.setAttribute(
          "content",
          "Nos orgulhamos de ter atendido mais de 1.500 clientes em Brasília e por todo Brasil. Por trás de cada case, existe um sério caso de amor por uma ideia."
        );
      }
    } else {
      document.title = `Portifólio | Conheça o conteúdo e acesse os cases | Página: ${page}`;
      const metaDescription = document.querySelector(
        'meta[name="description"]'
      );
      if (metaDescription) {
        metaDescription.setAttribute(
          "content",
          `Nos orgulhamos de ter atendido mais de 1.500 clientes em Brasília e por todo Brasil. Por trás de cada case, existe um sério caso de amor por uma ideia. | Página: ${page}`
        );
      }
    }
  }, [page]);

  const breadcrumbsItens = [
    {
      name: "Portfólio",
      alias: "/portfolio",
    },
  ];

  return (
    <>
      <Breadcrumbs title="Portfólio" items={breadcrumbsItens} />
      <section
        id="portfoliosPage"
        className="relative bg_secundary section_padding_clip text_center"
      >
        <div className="container">
          {portfolios?.data && (
            <div className="box_three_columns_desktop">
              {portfolios.data.map((portfolio, index) => (
                <div className="item" key={index}>
                  <img
                    src={`/images/cases/${portfolio.imagem.replace(
                      "jpg",
                      "webp"
                    )}`}
                    alt={portfolio.post_title}
                    width="450"
                    height="241"
                  />
                  <ReactPlayer
                    url={`https://www.youtube.com/watch?v=${portfolio.video_id}`}
                    light={`/images/cases/capa-video/${portfolio.video_image_preview}`}
                    width="342px"
                    height="570px"
                  />
                  <p>{portfolio.text.replace(/<\/?[^>]+(>|$)/g, "")}...</p>
                  <Link
                    className="btn"
                    to={`/portfolio/${portfolio.post_name}`}
                  >
                    Leia mais
                  </Link>
                </div>
              ))}
            </div>
          )}

          {portfolios?.data && (
            <Pagination pagination={portfolios.pagination} />
          )}
        </div>
      </section>
    </>
  );
};

export default Portfolios;
