import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Breadcrumbs from "../layouts/Breadcrumbs";
import { CasesInterface } from "../interfaces/CasesInterface";
import ReactPlayer from "react-player";

const Portfolio = () => {
  const [portfolio, setPortfolio] = useState<CasesInterface | null>(null);
  const navigate = useNavigate();
  const { alias } = useParams();

  useEffect(() => {
    const data = async () => {
      try {
        const res = await fetch(
          `https://boaimagem.org/api/portfolios.php/?alias=${alias}`,
          {
            headers: {
              "Content-Type": "application/json",
            },
            method: "get",
          }
        );
        const data = await res.json();
        if (data === null) {
          navigate("/portfolio");
        } else {
          setPortfolio(data);
          document.title = `${data?.post_title} | Boa Imagem`;
          const metaDescription = document.querySelector(
            'meta[name="description"]'
          );
          if (metaDescription) {
            metaDescription.setAttribute(
              "content",
              `${data?.text
                .replace(/<\/?[^>]+(>|$)/g, "")
                .substring(0, 150)}...`
            );
          }
        }
      } catch (err) {
        console.log(err);
      }
    };
    data();
  }, [alias]);

  const breadcrumbsItens = [
    {
      name: "Portfólio",
      alias: "/portfolio",
    },
    {
      name: portfolio?.post_title ?? "",
      alias: "/" + portfolio?.post_name ?? "",
    },
  ];

  return (
    <>
      {portfolio?.post_name && portfolio?.post_title && (
        <Breadcrumbs title={"Blog"} items={breadcrumbsItens} />
      )}
      <section id="portfolioPageInternal">
        <div className="relative bg_secundary section_padding_clip">
          <div className="container">
            {portfolio != null && (
              <div>
                <div className="item text_center">
                  <img
                    src={`/images/cases/${portfolio.imagem.replace(
                      "jpg",
                      "webp"
                    )}`}
                    alt={portfolio.post_title}
                    width="450"
                    height="241"
                  />
                  <ReactPlayer
                    url={`https://www.youtube.com/watch?v=${portfolio.video_id}`}
                    light={`/images/cases/capa-video/${portfolio.video_image_preview}`}
                    width="342px"
                    height="570px"
                  />
                </div>

                <div className="informations">
                  <h2 className="title_divider mt-30">
                    {portfolio.post_title}
                  </h2>
                  <div
                    className="text"
                    dangerouslySetInnerHTML={{ __html: portfolio.text }}
                  ></div>
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default Portfolio;
