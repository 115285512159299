import Breadcrumbs from "../../../layouts/Breadcrumbs";
import image from '../../../../assets/images/servicos/servico-monitoramento-de-marca.webp'
import { useEffect } from "react";

const MonitoramentoMarca = () => {

    useEffect(() => {
        document.title = "Monitoramento de Marca e tendências  | Agência Boa Imagem";
        const metaDescription = document.querySelector('meta[name="description"]');
        if (metaDescription) {
          metaDescription.setAttribute(
            "content",
            "É possível monitorar o que os usuários comentam a respeito da sua empresa, analisar as métricas de envolvimento do seu público e o nível de engajamento com suas postagens."
          );
        }
    }, []);
    
    const breadcrumbsItens = [
        {
            name: 'Serviços',
            alias: '/servicos'
        },{
            name: 'Online',
            alias: '/online'
        },{
            name: 'Monitoramento de Marca',
            alias: '/monitoramento-de-marca'
        }
    ];

    return (
        <>
            <Breadcrumbs title={'Monitoramento de Marca'} items={breadcrumbsItens}/>
            <section id="servicesPage">
                <div className='relative bg_secundary section_padding_clip'>
                    <div className='container'>

                        <p>Imagine a seguinte situação: Você acabou de criar o perfil da sua empresa no Facebook, por exemplo, o tempo passa e você começa a perceber que não tem feito diferença postar fotos do seu produto ou divulgar o serviço, não tem novas vendas!</p>

                        <p>Essa é a jornada que grande parte dos empreendedores seguem e que talvez você também esteja seguindo. Mas, porque a sua empresa não está alcançando os clientes, se está exatamente onde eles estão? A resposta é simples, falta monitoramento de marca!</p>

                        <h2>Mas, o que é monitoramento de marca?</h2>

                        <blockquote>“Consiste em acompanhar a reputação da sua empresa nas redes sociais e demais canais da internet. É possível monitorar o que os usuários comentam a respeito da sua empresa, analisar as métricas de envolvimento do seu público e o nível de engajamento com suas postagens.”</blockquote>

                        <p>Mesmo sabendo da importância de acompanhar as redes sociais, talvez você ainda pense que:</p>

                        <ul className="list">
                            <li>Rede social não dá lucro;</li>
                            <li>É melhor investir em panfleto;</li>
                            <li>Colocar uma faixa ou placa na frente da loja é mais eficiente;</li>
                            <li>Reformar o seu ponto de venda é a solução;</li>
                            <li>Anunciar na OLX vai resolver os seus problemas;</li>
                            <li>Divulgar em grupos no WhatsApp e do Facebook vende mais…</li>
                        </ul>


                        <p>Mas sabemos que não é assim que as coisas funcionam. O mundo dos negócios está dividido em 2 grupos: aqueles que acreditam no marketing digital e estão tendo sucesso e os que estão perdendo clientes.</p>

                        <p>O maior erro de muitos empreendedores está em não dar a devida atenção aos seus canais de comunicação. Assim como a área financeira da sua empresa precisa de profissionais competentes, para o Marketing Digital é essencial.</p>

                        <p>Existem 5 ótimos motivos para você investir em monitoramento e fazer parte do primeiro grupo:</p>

                        <ul className="list">
                            <li>Saiba onde está errando;</li>
                            <li>Conheça o seu público;</li>
                            <li>Saiba o que falam da sua empresa;</li>
                            <li>Ouça sugestões e fique a frente do seu concorrente;</li>
                            <li>Não perca nenhuma oportunidade de venda.</li>
                        </ul>


                        <p>O sol nasce para todos, mas apenas alguns conhecem e investem no segredo do sucesso. A única solução para ter sucesso nas redes sociais é:</p>

                        <blockquote>Monitorar cada passo dos seus seguidores e principalmente daqueles que não são. Esteja atento aos números, pois neles moram o segredo do sucesso.</blockquote>

                        <p><b>Você deve estar se perguntando como nós fazemos isso. É simples, conhecemos os bastidores das redes sociais e sabemos como usá-los a seu favor.</b></p>

                        <img className="mb-30" src={image} alt="Foto: Serviço de Monitoramento de Marca" title="Foto: Serviço de Monitoramento de Marca" width="360" height="240"/>

                        <p>A primeira vista, a imagem acima pode até parecer simples números e gráficos, mas mostram de forma detalhada como está a performance real da sua empresa. São valiosos para construir a sua imagem e gerar vendas para a sua marca.</p>

                        <p>Sabemos que todo empreendedor tem muitas responsabilidades. Precisa lidar diariamente com funcionários, clientes, prestadores de serviços e com muita burocracia. Por vezes enfrentando jornadas de até 12 horas. Deixe a comunicação da sua empresa nas mãos de quem entende. Conte com a expertise de especialistas!</p>

                        <h2>O que a boa imagem pode fazer pela sua empresa?</h2>

                        <ul className="list">
                            <li><b>Analisar as métricas de performance dos perfis corporativos;</b></li>
                            <li><b>Acompanhar as menções do nome da marca;</b></li>
                            <li><b>Gerir respostas a comentários em publicações;</b></li>
                            <li><b>Gerenciar respostas a mensagens enviadas;</b></li>
                            <li><b>Mostrar o panorama completo e propor soluções para aumentar suas vendas.</b></li>
                        </ul>

                        <p>Se ainda restam dúvidas de que monitorar as suas redes sociais é a melhor opção, saiba que grandes empresas tem investido pesado no monitoramento de marca, porque sabem que a internet é um terreno fértil, com possibilidades de elogios, compartilhamentos, engajamento e boas sugestões.</p>

                        <p>Mas também existe o outro lado, com muitos comentários negativos, campanhas que dão errado e se tornam virais, sabem que quando há incêndio é necessário ter uma equipe preparada para agir rápido e apagar o fogo.</p>

                        <p>Você deve estar pensando que a sua empresa é pequena e por isso monitorar as redes sociais não é importante, não vale o investimento. Saiba que é um grande erro! Só é possível crescer nas redes sociais de 3 formas:</p>

                        <ul>
                        <li>Criando conteúdo relevante para o seu público;</li>
                        <li>Monitorando a performance;</li>
                        <li>Investindo em anúncios;</li>
                        <li>Se a sua empresa não acompanha as métricas e não sabe pelo o que o público se interessa, as duas outras opções podem não ser suficientes.</li>
                        </ul>

                        <p>Não deixe sua empresa 3 passos atrás da concorrência. Dê o primeiro passo e converse com quem pode contribuir para o desenvolvimento da sua empresa e tirá-la do atraso. <b>Solicite um orçamento ou marque uma reunião conosco</b>.</p>

                    </div>
                </div>
            </section>
        </>
    );
}

export default MonitoramentoMarca;