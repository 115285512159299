
import Breadcrumbs from "../../../layouts/Breadcrumbs";
import featuredImage from '../../../../assets/images/servicos/servico-totem-publicitario-2.webp';
import image01 from '../../../../assets/images/servicos/totem-01.webp';
import image02 from '../../../../assets/images/servicos/totem-02.webp';
import image03 from '../../../../assets/images/servicos/totem-03.webp';
import image04 from '../../../../assets/images/servicos/totem-04.webp';
import image05 from '../../../../assets/images/servicos/totem-05.webp';
import image06 from '../../../../assets/images/servicos/totem-06.webp';
import image07 from '../../../../assets/images/servicos/totem-07.webp';
import image08 from '../../../../assets/images/servicos/totem-08.webp';
import image09 from '../../../../assets/images/servicos/totem-09.webp';
import image10 from '../../../../assets/images/servicos/totem-10.webp';
import image11 from '../../../../assets/images/servicos/totem-11.webp';
import image12 from '../../../../assets/images/servicos/totem-12.webp';
import image13 from '../../../../assets/images/servicos/totem-13.webp';
import image14 from '../../../../assets/images/servicos/totem-14.webp';
import image15 from '../../../../assets/images/servicos/totem-15.webp';
import { useEffect } from "react";

const TotemPublicitario = () => {

    useEffect(() => {
        document.title = "Projetos de Totem | Agência Boa Imagem";
        const metaDescription = document.querySelector('meta[name="description"]');
        if (metaDescription) {
          metaDescription.setAttribute(
            "content",
            "A finalidade do totem é ser um produto de comunicação e ambientação muito prático e funcional além de leve e de fácil montagem e desmontagem. Totem digital, painel digital, mural digital, Totem All-in-one e desktop, Totens touchscreen, Totens integrados, Totens especiais e Totem Retangular."
          );
        }
    }, []);

    const breadcrumbsItens = [
        {
            name: 'Serviços',
            alias: '/servicos'
        },{
            name: 'Offline',
            alias: '/offline'
        },{
            name: 'Totem Publicitário',
            alias: '/totem-publicitario'
        }
    ];

    return (
        <>
            <Breadcrumbs title={'Totem Publicitário'} items={breadcrumbsItens}/>
            <section id="servicesPage">
                <div className='relative bg_secundary section_padding_clip'>
                    <div className='container'>

                        <h2 className="title_divider mb-30">Totem publicitário: tire todas as suas dúvidas!</h2>

                        <img className="text_center" src={featuredImage} alt="Foto: Serviço de Totem Publicitário" title="Foto: Serviço de Totem Publicitário" width="520" height="340"/>

                        <h2>TOTEM PUBLICITÁRIO</h2>

                        <p>Toda criação gera um efeito e tudo se deve a uma gigantesca possibilidade de junções de ideias, que é o start para a elaboração de toda e qualquer peça publicitária. O designer deve traduzir da melhor forma as intenções de seus clientes. Transmitindo as ideias que representam a identidade de uma empresa por meio de formas e textos apresentados na peça.</p>

                        <p>Para as empresas que têm interesse em começar a trabalhar na divulgação de um negócio. É possível encontrar um ramo de possibilidades muito amplo no mercado, entre as opções disponíveis temos o totem publicitário. Sua forma simples, prática e econômica desperta interesse e traz resultados agradáveis e sem um alto custo de investimento.</p>

                        <p>Para o desenvolvimento de um totem é necessário a união da sua característica física com a escolha certa da localização. Esses dois fatores devem ser alinhados à uma estratégia de marketing com foco no público alvo da empresa para se tornar efetivo. São desenvolvidos de forma prática, fácil e rápida e podem ser fabricados em painéis verticais, luminosos ou não, podendo ser confeccionados em diversos materiais como madeira, acrílico, fibra, papelão, vidro, aço e outros.</p>

                        <h3>VALE A PENA?</h3>

                        <p>Podemos comparar um totem publicitário simples com um outdoor, ambos têm como objetivo atingir camadas sociais. Porém o totem de acordo com a lei e fiscalização estabelecida pela Agefis não pode ser usado em ambientes públicos. Ou seja, o totem é uma peça que pode ser usada em ambientes internos e externos, desde que seja um fachada auxiliar de empresas ou painéis para eventos.</p>

                        <p>Portanto, é uma forma eficiente e rápida de propagar o tema de uma campanha, além de aumentar a visualização de uma marca ou empresa, demarcar o território onde o negócio se encontra.Sem dúvida, esse modelo de mídia possui um resultado muito positivo, pois a mensagem é direta e concisa tudo isso produzido com muita criatividade, que são pontos necessários para a criação de qualquer peça publicitária.</p>

                        <p>Pode ser uma fachada auxiliar de empresas, facilitando a visualização das pessoas que passam de carro ou pode funcionar como um totem interativo. Diferente do display que possuem características mais simples, o totem é sempre personalizado para cada cliente.</p>

                        <h2>CUIDADOS COM A CRIAÇÃO DO LAYOUT</h2>

                        <p>O designer precisa lembrar de escolher conteúdos atualizados, saber quais as intenções da criação, as imagens que serão utilizadas, quais cores devem ser empregadas, qual o melhor formato de tamanho e a melhor fonte. Esses são apenas alguns fatores que um profissional preparado deve levar em consideração. É importante ser bastante criterioso na hora de elaborar um totem, pois o público precisa entender e se interessar.</p>

                        <p>Existem no mercado alguns tipos de totens publicitários e cada um deles tem uma forma de atingir as pessoas. Em suas individualidades cada um traz um resultado diferente. O que o difere dos demais é seu material básico que consequentemente se torna mais acessível na hora de investir. Sendo mais econômico principalmente para aqueles que estão começando um negócio.</p>

                        <h2>MODELOS DE TOTEM PUBLICITÁRIO</h2>

                        <p>O totem em formato de painel publicitário é um modelo diferente e que traz praticidade. O painel versátil possui estrutura de alumínio, funciona como um móvel, sem dificuldades para ser exposto. Não precisa de instalações especiais é interessante principalmente pelo fato de ser personalizado para cada cliente.</p>

                        <p>O totem publicitário iluminado é mais charmoso e atraente, ideal para as empresas que buscam um destaque. Esse material possui uma característica única de divulgação que chama atenção e atrai a clientela. É possível encontrar totens interativos que possuem uma proposta especial pensada na interação com o cliente. Criando trazendo uma ligação diferenciada do público com a empresa.</p>

                        <p>Tudo vai de acordo com o investimento que vai ser feito e com o que o cliente procura.</p>

                        <h2>BOA IMAGEM</h2>

                        <p>É importante ter uma empresa que saiba atender as suas necessidades e aplicar as suas possibilidades na realidade. O Totem e outras peças produzidas pela Agencia Boa Imagem são acompanhadas de uma criatividade ilimitada de design e conteúdo.</p>

                        <p>Buscamos o modelo ideal de totem publicitário para a sua empresa. Avaliamos a sua carência,vantagens, desvantagens e todos os pontos necessários para uma estratégia completa e com resultados efetivos. É essencial produzir um projeto bem feito, inteligente e completo. Pense nos problemas que necessita ser resolvidos e qual o principal objetivo com a utilização dessa mídia. Certamente teremos uma ideia adequada para cada momento e cada lugar.</p>

                        <p>Tirou suas dúvidas sobre Totem Publicitário? Conheça um pouco mais sobre Display!</p>

                        <p>Muitos negócios optam por ter essa peça como uma porta de entrada para o mundo. Entre em contato e monte conosco a melhor estratégia para a sua empresa.</p>

                        <h2>MODELOS DE TOTEM PUBLICITÁRIO</h2>

                        <div className="box_three_columns_desktop">
                            <img className="text_center" src={image01} alt="Foto: Serviço de Totem Publicitário" title="Foto: Serviço de Totem Publicitário" width="375" height="500"/>

                            <img className="text_center" src={image02} alt="Foto: Serviço de Totem Publicitário" title="Foto: Serviço de Totem Publicitário" width="375" height="500"/>

                            <img className="text_center" src={image03} alt="Foto: Serviço de Totem Publicitário" title="Foto: Serviço de Totem Publicitário" width="375" height="500"/>

                            <img className="text_center" src={image04} alt="Foto: Serviço de Totem Publicitário" title="Foto: Serviço de Totem Publicitário" width="339" height="500"/>

                            <img className="text_center" src={image05} alt="Foto: Serviço de Totem Publicitário" title="Foto: Serviço de Totem Publicitário" width="375" height="500"/>

                            <img className="text_center" src={image06} alt="Foto: Serviço de Totem Publicitário" title="Foto: Serviço de Totem Publicitário" width="375" height="500"/>

                            <img className="text_center" src={image07} alt="Foto: Serviço de Totem Publicitário" title="Foto: Serviço de Totem Publicitário" width="360" height="480"/>

                            <img className="text_center" src={image08} alt="Foto: Serviço de Totem Publicitário" title="Foto: Serviço de Totem Publicitário" width="363" height="484"/>

                            <img className="text_center" src={image09} alt="Foto: Serviço de Totem Publicitário" title="Foto: Serviço de Totem Publicitário" width="375" height="500"/>

                            <img className="text_center" src={image10} alt="Foto: Serviço de Totem Publicitário" title="Foto: Serviço de Totem Publicitário" width="500" height="382"/>

                            <img className="text_center" src={image11} alt="Foto: Serviço de Totem Publicitário" title="Foto: Serviço de Totem Publicitário" width="500" height="353"/>

                            <img className="text_center" src={image12} alt="Foto: Serviço de Totem Publicitário" title="Foto: Serviço de Totem Publicitário" width="500" height="371"/>

                            <img className="text_center" src={image13} alt="Foto: Serviço de Totem Publicitário" title="Foto: Serviço de Totem Publicitário" width="465" height="346"/>

                            <img className="text_center" src={image14} alt="Foto: Serviço de Totem Publicitário" title="Foto: Serviço de Totem Publicitário" width="500" height="374"/>

                            <img className="text_center" src={image15} alt="Foto: Serviço de Totem Publicitário" title="Foto: Serviço de Totem Publicitário" width="500" height="375"/>
                        </div>



                    </div>
                </div>
            </section>
        </>
    );
}

export default TotemPublicitario;