import React, { useState } from "react";
import { FaPlus, FaMinus } from "react-icons/fa";

interface AccordionItem {
  question: string;
  answer: string;
}

interface AccordionProps {
  items: AccordionItem[];
}

const Accordion: React.FC<AccordionProps> = ({ items }) => {
  const [activeIndex, setActiveIndex] = useState<number | null>(null);

  const toggleAccordion = (index: number) => {
    setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <div className="accordion">
      {items.map((item, index) => (
        <div key={index} className={activeIndex === index ? "active" : ""}>
          <button
            onClick={() => toggleAccordion(index)}
            aria-expanded={activeIndex === index}
            aria-controls={`answer-${index}`}
          >
            {activeIndex === index ? <FaMinus /> : <FaPlus />}

            {item.question}
          </button>
          {activeIndex === index && (
            <div className="text" id={`answer-${index}`}>
              {item.answer}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default Accordion;
