import Breadcrumbs from "../../../layouts/Breadcrumbs";
import image from '../../../../assets/images/servicos/servico-propaganda-de-televisao.webp';
import { useEffect } from "react";

const PropagandaTelevisao = () => {

    useEffect(() => {
        document.title = "Propaganda de Televisão | Sua empresa em todos os canais | fechada e aberta";
        const metaDescription = document.querySelector('meta[name="description"]');
        if (metaDescription) {
          metaDescription.setAttribute(
            "content",
            "A mídia televisiva influencia a formação da opinião do público. Com a nossa experiência de produção, vemos grandes possibilidades para uma boa audiência."
          );
        }
    }, []);

    const breadcrumbsItens = [
        {
            name: 'Serviços',
            alias: '/servicos'
        },{
            name: 'Offline',
            alias: '/offline'
        },{
            name: 'Propaganda de Televisão',
            alias: '/propaganda-de-televisao'
        }
    ];

    return (
        <>
            <Breadcrumbs title={'Propaganda de Televisão'} items={breadcrumbsItens}/>
            <section id="servicesPage">
                <div className='relative bg_secundary section_padding_clip'>
                    <div className='container'>

                        <h2 className="title_divider mb-30">Propaganda</h2>

                        <p>A propaganda possui grande poder de influência, além disso é um meio poderoso para as empresas e para o país.</p>

                        <p>As propagandas possuem uma força brutal de influência, especialistas dizem que esse veículo pode ser um grande responsável pela mudança da nossa sociedade. Veículos de comunicação como televisão, revistas e jornais conseguem por si só construir uma sustentação usando a propaganda. Após a criação da TV o desejo de comprar coisas aumentou e isso consequentemente aumentou os gastos e influenciou diversas áreas. E é exatamente essa a intenção de todas as produções, frases criativas e spots que você escuta e vê assim que liga o rádio ou sai na rua.</p>

                        <p>Esse veículo leva a informação para o telespectador ou para o leitor de forma direta. Por isso sempre estamos em contato com chamadas interessantes como, spots de cursos com preços baixos e produtos atuais com bons preços. Não seria interessante para os fornecedores oferecerem coisas antigas ou passadas, o objetivo é vender e colocar as novidades nas mãos das pessoas.</p>

                        <p>Sabemos que a propaganda é uma ótima forma de fornecer informação e desenvolver o crescimento de um empreendimento. Estamos sempre observando a receptividade dos ouvintes, leitores e telespectadores, pois eles são os guiadores do que é produzido. Sabemos também que nossa vida é totalmente influenciada pela mídia e seus veículos.</p>

                        <p>Usar essa ferramenta de forma criativa e positiva, pode ser primordial para um bom relacionamento da sua empresa com o seu cliente. A televisão, rádio e as revistas ainda são poderosas fontes de informação. Conheça mais sobre a Propaganda de Televisão e em como ela pode ajudar a sua empresa.</p>

                        <h2 className="title_divider mb-30">Propaganda de televisão</h2>

                        <img className="text_center mb-30" src={image} alt="Foto: Serviços de Propaganda de televisão, Propaganda, TV" title="Foto: Serviços de Propaganda de televisão, Propaganda, TV" width="520" height="344"/>

                        <p>Há um tempo atrás a única forma de divulgação era em formato de comerciais para a TV. Atualmente existem inúmeros meios de divulgação, como por exemplo vídeos no youtube, propagandas em mídias sociais e outras. Mas não podemos deixar os comercias de lado, eles ainda são grandes referências para o público. Com boas ideias podemos gerar um viral e consequentemente uma boa visibilidade para o seu negocio.</p>

                        <p>As telinhas impactam milhões de brasileiros todos os dias, a mídia televisiva evidentemente influencia a formação de opinião, apresentando aspectos de modernidade que causam transformações no sistema econômico.</p>

                        <p>Com a nossa experiência de produção, vemos grandes possibilidades. Conhecemos as ferramentas necessárias para ganhar uma boa audiência. Boas formas de divulgação acarretam em boas oportunidades para a sua empresa.</p>

                        <ul className="list">
                            <li>Criação campanhas para TV;</li>
                            <li>Comerciais, Institucionais;</li>
                            <li>Assinaturas;</li>
                            <li>Vinhetas;</li>
                            <li>Merchandising.</li>
                        </ul>

                        <p>Em meio a esse contexto do avanço tecnológico, a difusão de uma nova cultura a informacional, que captura a realidade a partir destes meios. Sistemas de comunicação e principalmente a telecomunicação reforçam as mensagens, direcionadas para o seu público.</p>

                        <p>Atuando de maneira correta, a propaganda forma opiniões na comunicação entre as pessoas e a Agência utiliza a interferência da mídia televisiva para atuar em todas as áreas de conhecimento, abordando diferentes formas de captura da realidade; Assim interfere nos acontecimentos devido a relação emissor/receptor e os impactos que a mensagem tem no cotidiano das pessoas.</p>

                        <h2 className="title_divider mb-30">Publicidade e TV</h2>

                        <p>Sabemos que a assim como a internet a a televisão tem uma grande influência no dia-a-dia das pessoas. As novela e as propagandas tem um grande espaço quando se trata de agir como veículo de informação. Temos a facilidade de encontrar qualquer informação e programas pelo celular e isso acabou diminuindo a fama da televisão. Mas o público de telespectadores que acompanham constantemente os programas de televisão é enorme e por isso usar esse veículo para fins comercias pode ser uma grande negócio.</p>

                        <p>A Agência Boa Imagem é apta a produzir grande propagandas para o seu negócio. Para bons resultados é preciso executar um bom serviço. Possuímos visão, conhecimento de mercado e do público, sendo assim podemos atender todas as necessidades da sua empresa e assim seremos capazes de desenvolver boas propagandas fortalecendo ainda mais esse veículo que já possui um grande poder de influência.</p>

                        <p>Se assim como nós você acredita nos resultados da propaganda de televisão. Conheça mais sobre os nossos serviços de Mídia e Veículos e faça o seu negócio decolar!</p>

                    </div>
                </div>
            </section>
        </>
    );
}

export default PropagandaTelevisao;