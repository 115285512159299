import { useEffect } from "react";
import Breadcrumbs from "../../../../layouts/Breadcrumbs";

const MidiaImpressa = () => {
  useEffect(() => {
    document.title =
      "Mídia impressa, elementos para traduzir missão e valores da marca";
    const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
      metaDescription.setAttribute(
        "content",
        "A Mídia Impressa é representado pela criação de layouts de: folder, flyers, catálogos, embalagens, papelaria, anúncio para jornal, anúncio para revista, mala direta e para divulgação em geral"
      );
    }
  }, []);

  const breadcrumbsItens = [
    {
      name: "Serviços",
      alias: "/servicos",
    },
    {
      name: "Offline",
      alias: "/offline",
    },
    {
      name: "Mídia e veículo",
      alias: "/midia-e-veiculo",
    },
    {
      name: "Mídia impressa",
      alias: "/impressa",
    },
  ];

  return (
    <>
      <Breadcrumbs title={"Mídia impressa"} items={breadcrumbsItens} />
      <section id="servicesPage">
        <div className="relative bg_secundary section_padding_clip">
          <div className="container">
            <h2 className="title_divider mb-30">
              Mídia impressa e suas formas de publicidade!
            </h2>
            <p>
              A Agência Boa Imagem atende vários segmentos, desenvolvendo
              serviços de acordo com a necessidade do cliente, o desenvolvimento
              de produtos para a Mídia Impressa é feito de forma offline.
            </p>
            <h2>Mídia impressa</h2>
            <p>
              Essa forma de atuar juntamente com o cliente não torna o serviço
              pior, muito pelo contrário estamos sempre focados em oferecer
              todos os serviços com qualidade e eficiência. No ramo offline
              desenvolvemos peças que fazem a publicidade do cliente trazendo
              resultados satisfatórios, mesmo que fora do mundo online.
            </p>
            <p>
              Conheça um pouco mais sobre as formas de aplicar a publicidade em
              mídia impressa.
            </p>
            <h4>Identidade Visual</h4>
            <p>Elementos para traduzir a missão e os valores da Marca.</p>
            <h4>Marca</h4>
            <p>Logomarca, Logotipo e Manual de uso da marca.</p>
            <h4>Ilustração</h4>
            <p>Editorial, Personagem e projetos diversos.</p>
            <h4>Sinalização</h4>
            <p>Placas e Informativos para identificação e sinalização.</p>
            <h4>Papelaria e impressos</h4>
            <p>Artes e diagramações para itens na seção de gráfica.</p>
            <h4>Editorial</h4>
            <p>Capas, Livros, Revistas, Cartilhas, Jornais e Catálogos</p>
            <h4>Materiais promocionais</h4>
            <p>Materiais personalizados.</p>
            <h4>Design para pontos de vendas</h4>
            <p>Acrílico, Madeira, PVC, Ferro, Chapa.</p>
            <h4>Embalagem</h4>
            <p>Criação de embalagens personalizadas e design de produtos.</p>
            <h4>Slider e E-Banner</h4>
            <p>Divulgações específicas para web.</p>
            <h4>E-Book</h4>
            <p>Livro interativo para internet, e tablets.</p>
            <h4>Layout</h4>
            <p>Organização da proporção de elementos em projetos diversos.</p>
            <h4>Projetos 3D</h4>
            <p>
              Diferentes projetos de ambientes e personagens para animações,
              vinhetas e outros.
            </p>
            <h4>PDF Interativo</h4>
            <p>
              Forma simples e fácil de enviar apresentações digitais em arquivos
              protegidos.
            </p>
            <h4>Interfaces para aplicativos</h4>
            <p>
              Funcionalidade não é tudo, o seu aplicativo precisa de interface
              atrativa e interativa.
            </p>
            <h4>Mala Direta</h4>
            <p>Montagem da imagem para ser enviada por e-mail.</p>
            <h4>Estrutura Para Fachada</h4>
            <p>Estrutura metálica, layout e projetos especiais.</p>
            <h4>Estrutura para Outdoor</h4>
            <p>Estrutura em qualquer material e layout para outdoor.</p>
            <h4>Placas</h4>
            <p>
              Placas e estruturas para fixação,no material ideal para sua
              empresa.
            </p>
            <h4>Painel Luminoso</h4>
            <p>Destaque-se em painéis multimídia, nos locais que preferir!</p>
            <h4>Display e Digital Signage</h4>
            <p>
              Display de acrílico com armação de alumínio super leve para
              comunicação interna, opções práticas e duradouras.
            </p>
            <h4>Letras Caixa</h4>
            <p>
              Letras com profundidade, podem ser confeccionadas em liga de metal
              ou acrílico, em diferentes tamanhos para dentro e fora da sua
              loja.
            </p>
            <h4>Truss Box</h4>
            <p>Estrutura de metal para Iluminação de palco.</p>
            <h4>Stand de Ponto de Venda</h4>
            <p>Decorado com a identidade da sua empresa.</p>
            <h4>Stand para feiras e Eventos</h4>
            <p>
              Stand para feiras de exposição, pontos de venda e feiras
              corporativas.
            </p>
            <h4>Totem</h4>
            <p>
              Perfeito para distribuição em ponto de venda para todos os
              segmentos.
            </p>
            Entre em Contato e escolha o melhor serviço para a sua.
          </div>
        </div>
      </section>
    </>
  );
};

export default MidiaImpressa;
