import { Link } from "react-router-dom";
import { ServicesInterface } from "../../../../interfaces/ServicesInterface";
import Breadcrumbs from "../../../../layouts/Breadcrumbs";
import ServiceBox from "../../../../layouts/ServiceBox";

import servico01 from "../../../../../assets/images/servicos/servico-eletronico-digital.webp";
import servico02 from "../../../../../assets/images/servicos/servico-outras-midias.webp";
import servico03 from "../../../../../assets/images/servicos/servico-paineis-OOH.webp";
import servico04 from "../../../../../assets/images/servicos/servico-midia-impressa.webp";

import { useEffect } from "react";

const MidiaVeiculos = () => {
  const services: ServicesInterface[] = [
    {
      title: "Mídias Eletrônica/Digital",
      text: "A mídia digital se tornou algo revolucionário, a cada dia existem mais adeptos e consumidores virtuais. Essa ferramenta pode ser explorada por meio de redes sociais, e-mails, sites, telas de elevadores, banners eletrônicos e outros.",
      image: servico01,
      link: "/servicos/offline/midia-e-veiculo/eletronica-digital",
    },
    {
      title: "Outras mídias",
      text: "A agência Boa Imagem possui uma grande variedade de serviços, porém possuímos outras mídias que também podem agregar no seu negócio. Todos os nossos projetos são pensados de forma completa para atender todas as necessidades dos clientes.",
      image: servico02,
      link: "/servicos/offline/midia-e-veiculo/outras",
    },
    {
      title: "Painéis OOH",
      text: "Ela corresponde a qualquer tipo de mídia que nos impacta a partir do momento em que colocamos os pés fora de casa, por isso o termo Out of Home.",
      image: servico03,
      link: "/servicos/offline/midia-e-veiculo/out-of-home",
    },
    {
      title: "Mídia impressa",
      text: "A Agência Boa Imagem atende vários segmentos, desenvolvendo serviços de acordo com a necessidade do cliente, o desenvolvimento de produtos para a Mídia Impressa é feito de forma offline.",
      image: servico04,
      link: "/servicos/offline/midia-e-veiculo/impressa",
    },
  ];

  useEffect(() => {
    document.title =
      "Online ou offline: Como escolher a melhor para sua estratégia?";
    const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
      metaDescription.setAttribute(
        "content",
        "Ter um planejamento bem delineado para a sua empresa, saber qual meio será usado ou qual veículo será responsável pela divulgação é essencial."
      );
    }
  }, []);

  const breadcrumbsItens = [
    {
      name: "Serviços",
      alias: "/servicos",
    },
    {
      name: "Offline",
      alias: "/offline",
    },
    {
      name: "Mídia e veículo",
      alias: "/midia-e-veiculo",
    },
  ];

  return (
    <>
      <Breadcrumbs
        title={
          "Mídia X Veículo: online ou off-line? Como escolher a melhor para sua estratégia"
        }
        items={breadcrumbsItens}
      />
      <section id="servicesPage">
        <div className="relative bg_secundary section_padding_clip">
          <div className="container">
            <p>
              Para onde quer que você olhe, marcas estão conversando ou se
              comunicando com seus potenciais clientes. No ambiente online ou no
              mundo físico, <b>interação e engajamento</b> com o público são{" "}
              <b>fatores determinantes</b> para o <b>sucesso</b> de qualquer
              negócio.
            </p>

            <p>
              Como você vem interagindo com o seu público? A principal dúvida
              que recebemos é{" "}
              <b>
                como escolher o local certo para estar, anunciar e começar uma
                conversa
              </b>{" "}
              com seu público em potencial. Afinal, são tantos tipos de veículos
              de comunicação e mídias publicitárias que você pode até ficar
              confuso para começar.
            </p>

            <p>O primeiro passo é entender o que é exatamente cada um.</p>

            <h2>Mídia versus veículo</h2>

            <ul>
              <li>
                <b>Mídia</b> é o local onde será transmitida sua mensagem, como
                no rádio ou televisão.
              </li>
              <li>
                Já os <b>veículos de comunicação</b>, como o próprio nome já
                diz, é a empresa que irá transmitir sua mensagem.
              </li>
            </ul>

            <h2>E as diferenças entre mídia online e mídia off-line?</h2>

            <p>
              A diferença entre elas é a forma de veiculação da mensagem. No{" "}
              <Link to="/servicos/online">online</Link>, a divulgação do seu
              negócio é feita a <b>nível global</b>, com{" "}
              <Link to="/servicos/online/marketing-digital/criacao-de-sites">
                sites
              </Link>
              , <Link to="/servicos/online/e-commerce">e-commerce</Link>,{" "}
              <Link to="/servicos/online/marketing-digital/midias-sociais">
                redes sociais
              </Link>
              , blogs, newsletter, entre outras frentes.
            </p>

            <p>
              Já na mídia <Link to="/servicos/offline">off-line</Link>, ou
              veículos de comunicação tradicionais, trabalhamos a divulgação de
              sua marca em determinada <b>região</b>, com banners,{" "}
              <Link to="/servicos/offline/midia-e-veiculo/out-of-home">
                outdoors
              </Link>
              , panfletos e matérias para jornais,
              <Link to="/servicos/offline/propaganda-de-revista">revistas</Link>
              ,<Link to="/servicos/offline/propaganda-de-televisao">tv</Link> e
              <Link to="/servicos/offline/propaganda-de-radio">rádio</Link>.
            </p>

            <h3>Uma estratégia completa</h3>

            <p>
              É importante entender a diferença entre os dois, mas não basta
              parar por aí! Ter uma <b>estratégia de comunicação convergente</b>
              , que une online e off-line, também é fundamental. Saber onde o
              seu potencial cliente está e como abordá-lo em cada canal de
              comunicação é o grande diferencial que pode levar seu negócio a um
              <b>novo patamar de sucesso</b>.
            </p>

            <p>
              A <b>transformação digital</b> revolucionou a forma como as
              pessoas conversam, interagem e compram. Mesmo com o crescimento
              vertiginoso <b>da comunicação online</b>, as
              <b>campanhas off-line</b> também não perderem sua força e têm
              papel importante em uma
              <b>estratégia de comunicação de sucesso</b>.
            </p>

            <p>
              Como saber em quais canais investir para o seu negócio? Uma
              <b>
                agência <i>full service</i>
              </b>
              como a <b>Boa Imagem</b> tem a resposta! Somos especialistas em
              todos os canais de comunicação, podemos identificar e criar uma
              estratégia <b>personalizada para o seu negócio</b>, investindo
              onde seu público está.
            </p>

            <p className="center">
              Isso garante mais conversão e <b>mais lucro</b> para o seu
              negócio!
            </p>

            <p>
              Vamos conversar sobre o assunto e saiba como garantir melhores
              resultados para os negócios. Agende uma <b>avaliação gratuita</b>e
              conheça nossas <b>expertises</b>. Será um prazer atendê-lo e
              mostrar alguns de nossos
              <Link to="/portfolio/cases">
                <b>cases de sucesso</b>
              </Link>
              .
            </p>
            <Link to="/contato" className="botao-01 float-none">
              Entrar em contato
            </Link>
          </div>
        </div>
      </section>

      <section
        id="servicesInsidePageOthers"
        className="box_divider relative bg_secundary section_padding_clip text_center"
      >
        <div className="container">
          <ServiceBox services={services} />
        </div>
      </section>
    </>
  );
};

export default MidiaVeiculos;
