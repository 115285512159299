import Breadcrumbs from "../../../../layouts/Breadcrumbs";
import image from '../../../../../assets/images/servicos/servico-seo-otimizacao.webp'
import { useEffect } from "react";

const SeoOtimizacao = () => {

    useEffect(() => {
        document.title = "SEO: Otimização para buscas em pesquisas no Google e outros buscadores";
        const metaDescription = document.querySelector('meta[name="description"]');
        if (metaDescription) {
          metaDescription.setAttribute(
            "content",
            "Tire as suas dúvidas sobre o que é SEO e aprenda como o seu site pode se destacar nos mecanismos de busca com quem realmente entende do assunto!"
          );
        }
    }, []);

    const breadcrumbsItens = [
        {
            name: 'Serviços',
            alias: '/servicos'
        },{
            name: 'Online',
            alias: '/online'
        },{
            name: 'Marketing digital',
            alias: '/marketing-digital'
        },{
            name: 'SEO – Otimização de Sites para buscas em pesquisas no Google e outros buscadores',
            alias: '/seo-otimizacao'
        }
    ];

    return (
        <>
            <Breadcrumbs title={'SEO – Otimização de Sites para buscas em pesquisas no Google e outros buscadores'} items={breadcrumbsItens}/>
            <section id="servicesPage">
                <div className='relative bg_secundary section_padding_clip'>
                    <div className='container padding'>

                        <h2 className="title_divider ml-auto mr-auto mb-30">A Sua empresa em PRIMEIRO lugar!</h2>

                        <img className="mb-30" src={image} alt="Foto: Serviço de SEO – Otimização de Sites para buscas em pesquisas no Google e outros buscadores" width="520" height="359" title="Foto: Serviço de SEO – Otimização de Sites para buscas em pesquisas no Google e outros buscadores"/>
                        
                        <p>No cenário atual da publicidade digital, o SEO (Search Engine Optimization) é um componente essencial para o sucesso de qualquer estratégia de marketing online. O SEO envolve um conjunto de práticas destinadas a otimizar um site ou conteúdo online para melhorar sua visibilidade nos mecanismos de busca, como o Google.</p>

                        <p>A maioria das pessoas buscam informações na internet antes de fazer uma compra ou contratar um serviço. Se o seu site não aparecer na primeira página dos mecanismos de busca, é provável que essas pessoas nem cheguem a conhecer a sua empresa ou acessar o seu website.</p>

                        <p>Essa também é uma estratégia de longo prazo, pois seus resultados são cumulativos, ou seja, quanto mais você investe em SEO, melhores são as suas chances de obter resultados duradouros. Se você quer atrair mais clientes e vender mais de forma recorrente, essa é a solução ideal. </p>

                        <h3><strong>SEO: Aprenda com quem realmente entende!</strong></h3>
                        <p>Veja aqui um artigo que escrevemos sobre SEO e aprenda um pouco mais sobre essa estratégia que pode posicionar o seu site gratuitamente no topo do ranking dos mecanismos de pesquisa:</p>
                        
                        <h3 className="text_center"><strong>SEO: Aprenda com quem realmente entende!</strong></h3>
                        <p className="text_center">Para contratar serviços de SEO, confira a página do nosso parceiro <a href="https://www.biplus.com.br/" target="_blank" rel="noopener">BI PLUS</a>.</p>


                    </div>
                </div>
            </section>
        </>
    );
}

export default SeoOtimizacao;