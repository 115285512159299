import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

const MetaTagsUpdater: React.FC = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [location]);

  useEffect(() => {
    const updateMetaTags = () => {
      const pageTitle = document.title;
      const metaDescriptionElement = document.querySelector(
        'meta[name="description"]'
      );
      const pageDescription =
        metaDescriptionElement?.getAttribute("content") || "";

      // Atualiza o link canonical
      let canonicalUrl = window.location.origin + location.pathname;
      if (canonicalUrl.endsWith("/")) {
        canonicalUrl = canonicalUrl.slice(0, -1);
      }
      let canonicalLink = document.querySelector('link[rel="canonical"]');
      if (!canonicalLink) {
        canonicalLink = document.createElement("link");
        canonicalLink.setAttribute("rel", "canonical");
        document.head.appendChild(canonicalLink);
      }
      canonicalLink.setAttribute("href", canonicalUrl);

      // Atualiza o meta og:url
      let ogUrlMetaTag = document.querySelector('meta[property="og:url"]');
      if (!ogUrlMetaTag) {
        ogUrlMetaTag = document.createElement("meta");
        ogUrlMetaTag.setAttribute("property", "og:url");
        document.head.appendChild(ogUrlMetaTag);
      }
      ogUrlMetaTag.setAttribute("content", canonicalUrl);

      // Atualiza o meta og:title e twitter:title
      let ogTitleMetaTag = document.querySelector('meta[property="og:title"]');
      if (!ogTitleMetaTag) {
        ogTitleMetaTag = document.createElement("meta");
        ogTitleMetaTag.setAttribute("property", "og:title");
        document.head.appendChild(ogTitleMetaTag);
      }
      ogTitleMetaTag.setAttribute("content", pageTitle);

      let twitterTitleMetaTag = document.querySelector(
        'meta[name="twitter:title"]'
      );
      if (!twitterTitleMetaTag) {
        twitterTitleMetaTag = document.createElement("meta");
        twitterTitleMetaTag.setAttribute("name", "twitter:title");
        document.head.appendChild(twitterTitleMetaTag);
      }
      twitterTitleMetaTag.setAttribute("content", pageTitle);

      // Atualiza o meta og:description
      let ogDescriptionMetaTag = document.querySelector(
        'meta[property="og:description"]'
      );
      if (!ogDescriptionMetaTag) {
        ogDescriptionMetaTag = document.createElement("meta");
        ogDescriptionMetaTag.setAttribute("property", "og:description");
        document.head.appendChild(ogDescriptionMetaTag);
      }
      ogDescriptionMetaTag.setAttribute("content", pageDescription);

      // Remove qualquer schema dinâmico existente (WebSite ou BlogPosting)
      const existingDynamicSchema = document.querySelector(
        'script[type="application/ld+json"][data-schema="dynamic"]'
      );
      if (existingDynamicSchema) {
        existingDynamicSchema.remove();
      }

      // Cria o script JSON-LD dinamicamente para WebSite ou BlogPosting
      const jsonLdScript = document.createElement("script");
      jsonLdScript.setAttribute("type", "application/ld+json");
      jsonLdScript.setAttribute("data-schema", "dynamic");

      // Verifica se a página é uma página específica de artigo de blog
      const isBlogPostPage =
        location.pathname.includes("/blog/") &&
        location.pathname.split("/blog/")[1].trim() !== "";

      const imgPost = document
        .querySelector(".imgMain img")
        ?.getAttribute("src");

      // Defina o tipo do jsonLdContent explicitamente
      type JsonLdContent = {
        "@context": string;
        "@type": string;
        "@id": string;
        url: string;
        name: string;
        description: string;
        author?: {
          "@type": string;
          name: string;
        };
        image?: string;
        mainEntityOfPage?: {
          "@type": string;
          "@id": string;
        };
        sameAs: string[];
        inLanguage?: string;
        potentialAction?: {
          "@type": string;
          target: string;
          "query-input": string;
        };
      };
      // Cria o JSON-LD específico da página
      const jsonLdContent: JsonLdContent = isBlogPostPage
        ? {
            "@context": "https://schema.org",
            "@type": "BlogPosting",
            "@id": canonicalUrl,
            url: canonicalUrl,
            name: pageTitle,
            description: pageDescription,
            author: {
              "@type": "Organization",
              name: "Boa Imagem",
            },
            image: `${imgPost}`,
            mainEntityOfPage: {
              "@type": "WebPage",
              "@id": canonicalUrl,
            },
            sameAs: [
              "https://www.facebook.com/boaimagem",
              "https://www.instagram.com/boa_imagem/?hl=pt_BR",
              "https://BoaImagem.com.br",
              "https://BoaImagem.net",
              "https://boaimagem.tv",
              "https://twitter.com/BoaImagem",
              "https://br.linkedin.com/company/boa-imagem",
            ],
          }
        : {
            "@context": "https://schema.org",
            "@type": "WebSite",
            "@id": "https://boaimagem.org/#website",
            url: canonicalUrl,
            name: pageTitle,
            inLanguage: "pt-BR",
            description: pageDescription,
            potentialAction: {
              "@type": "SearchAction",
              target: "https://boaimagem.org/?s={search_term_string}",
              "query-input": "required name=search_term_string",
            },
            sameAs: [
              "https://www.facebook.com/boaimagem",
              "https://www.instagram.com/boa_imagem/?hl=pt_BR",
              "https://BoaImagem.com.br",
              "https://BoaImagem.net",
              "https://boaimagem.tv",
              "https://twitter.com/BoaImagem",
              "https://br.linkedin.com/company/boa-imagem",
            ],
          };

      // Remove campos indefinidos
      Object.keys(jsonLdContent).forEach((key) => {
        const typedKey = key as keyof JsonLdContent; // Cast para um tipo válido
        if (jsonLdContent[typedKey] === undefined) {
          delete jsonLdContent[typedKey];
        }
      });

      // Adiciona o script JSON-LD atualizado ao <head>
      jsonLdScript.textContent = JSON.stringify(jsonLdContent);
      document.head.appendChild(jsonLdScript);
    };

    // Função para monitorar mudanças no <title> e <meta name="description">
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.type === "childList" || mutation.type === "attributes") {
          updateMetaTags();
        }
      });
    });

    // Observa o <title> e <meta name="description">
    const titleElement = document.querySelector("title");
    const descriptionElement = document.querySelector(
      'meta[name="description"]'
    );

    if (titleElement) observer.observe(titleElement, { childList: true });
    if (descriptionElement)
      observer.observe(descriptionElement, { attributes: true });

    // Limpa o observer quando o componente desmontar
    return () => observer.disconnect();
  }, [location]);

  return null;
};

export default MetaTagsUpdater;
